import { IndexRoute, Route } from 'react-router';
import asyncComponent from 'components/async-component';
import Container from 'app/container';
import getRouteContainer from 'app/route-container';
import Home from 'components/home-container';
import { ID_ROUTES } from 'constants/routes';
import React from 'react';
import { trackPageView } from 'services/tracking';


const Establishment = asyncComponent(() => import('../templates/establishment/container').then(module => module.default));

function RoutesBase(routes) {

  function onEnter(params) {
    trackPageView(undefined, params);
  }

  function onEnterHome() {
    onEnter({ pageType: 'home' });
  }

  return (
    <Route component={ Container } path="/">
      <IndexRoute
        component={ getRouteContainer(Home, ID_ROUTES.HOME, true) }
        onEnter={ onEnterHome }
      />
      <Route
        component={ getRouteContainer(Establishment, ID_ROUTES.ESTABLISHMENT, true) }
        onEnter={ onEnter }
        path={ `${routes.establishment}(/:id)` }
      />
      <Route
        component={ getRouteContainer(() => null, ID_ROUTES.STATIC) }
        onEnter={ onEnter }
        path={ routes.privacy }
      />
      <Route
        component={ getRouteContainer(() => null, ID_ROUTES.ERROR) }
        onEnter={ onEnter }
        path="*"
      />
    </Route>
  );
}

export default RoutesBase;
