// Original source: http://www.w3schools.com/js/js_cookies.asp

const DEFAULT_PATH_ATTRIBUTE = '/';
export const HALF_HOUR_IN_DAYS = 0.5 / 24;

const calculateCookieExpiresAttribute = (expirationDays) => {
  if (expirationDays === 0) return 0;

  const date = new Date();
  date.setTime(date.getTime() + (expirationDays * 24 * 60 * 60 * 1000));
  return date.toUTCString();
};

const calculateCookie = (key, value = '', expirationDays = HALF_HOUR_IN_DAYS, path = DEFAULT_PATH_ATTRIBUTE, domain) => {
  const cleanValue = value && typeof value === 'string' && value.startsWith(',') ? value.slice(1) : value;
  return `${key}=${cleanValue}; path=${path}${expirationDays ? `; expires=${calculateCookieExpiresAttribute(expirationDays)}` : ''}${domain ? `; domain=${domain}` : ''}`;
};

export const setCookie = (...args) => {
  document.cookie = calculateCookie(...args);
};

export const getCookieValueFromString = (cookieString, key) => {
  const keyWithEqual = `${key}=`;
  const cookie = cookieString.split(';')
    .map(c => c.trim())
    .find(c => c.includes(keyWithEqual));

  return cookie ? cookie.substring(keyWithEqual.length) : undefined;
};

export const getCookieValue = key => getCookieValueFromString(document.cookie, key);

const isCookieInString = (cookieString, key) => (
  typeof (getCookieValueFromString(cookieString, key)) !== 'undefined'
);

export const isCookieSet = key => isCookieInString(document.cookie, key);

// A cookie can be deleted by setting its expiration date to a passed date
export const deleteCookie = key => setCookie(key, undefined, -1);
