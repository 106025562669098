import { LOCATION_ACTIONS } from 'constants/actions';


const initialState = {
  actualPage: null,
};

export function locationReducer(state = initialState, action) {

  switch (action.type) {

    case LOCATION_ACTIONS.SET_ACTUAL_PAGE:
      return Object.assign({}, state, { actualPage: action.page });

    default:
      return state;

  }

}

export function isInPersonalArea() {
  return false;
}

export function isInFavourites() {
  return false;
}
