import PropTypes from 'prop-types';
import React from 'react';


export const STATES = {
  DEFAULT: 'DEFAULT',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
};

const CLASS = {
  [STATES.DEFAULT]: '-is-default-state',
  [STATES.ERROR]: '-is-error-state',
  [STATES.LOADING]: '-is-loading-state',
  [STATES.SUCCESS]: '-is-success-state hundredicons-done',
};

const CONTENT = {
  [STATES.ERROR]: 'Oops!',
  [STATES.LOADING]: ' ',
  [STATES.SUCCESS]: ' ',
};

const Button = ({ children, className, disabled, handleOnClick, state, style, tabIndex }) => (
  <button
    className={ `${className} hundred-ui-button ${CLASS[state]}` }
    disabled={ state === (STATES.LOADING || STATES.LOADING) || disabled }
    onClick={ handleOnClick }
    style={ style }
    tabIndex={ tabIndex }
    type="button"
  >
    { CONTENT[state] ? CONTENT[state] : children }
  </button>
);

Button.defaultProps = {
  children: null,
  className: '',
  disabled: false,
  handleOnClick: () => {},
  state: STATES.DEFAULT,
  style: null,
  tabIndex: '1',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleOnClick: PropTypes.func,
  state: PropTypes.oneOf(Object.keys(STATES)),
  style: PropTypes.shape(),
  tabIndex: PropTypes.string,
};

export default Button;
