import { COOKIES, MODALS, STANDALONE_MODE } from 'constants/constants';
import { getMatchingRoute, updateSearchSubsection } from 'actions/searchFormActions';
import { hideEstablishment, showEstablishment } from 'actions/searchResultsActions';
import { hideModalByName, showModal } from 'actions/modals';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { getUrlData } from 'actions/requestActions';
import ModalContainer from 'components/modal/new-modal/modal-container';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import { setCookie } from 'helpers/cookies-helper';
import { setStandAloneMode } from 'actions/appActions';


function getClassNameByDevice() {
  if (global.isPhone) return `react-app phone ${global.isPhone}`;
  if (global.isTablet) return `react-app tablet ${global.isTablet}`;
  return 'react-app desktop';
}

class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      establishmentModal: null,
    };
  }

  componentWillMount() {
    const { location, params, showModalAction, setStandAloneModeAction, getUrlDataAction } = this.props;
    if (typeof window !== 'undefined' && location) {
      if (global.browserNotCompatible) showModalAction(MODALS.COMPATIBILITY);
      global.scrollTo(0, 0);

      if (!location.query) return;
      if (location.query.mode === STANDALONE_MODE) setStandAloneModeAction();
      if (location.query.gclid) {
        setCookie(COOKIES.GOOGLE_CLICK_ID, location.query.gclid, 90);
      }
      if (location.query.msclkid) {
        setCookie(COOKIES.MICROSOFT_CLICK_ID, location.query.msclkid, 90);
      }
      if (location.query.establishment && (getMatchingRoute()[0] !== 'search' || !params.destination)) {
        const newQueryParams = { ...location.query };
        delete newQueryParams.establishment;

        browserHistory.replace({
          pathname: location.pathname,
          search: `?${queryString.stringify(newQueryParams)}`,
        });

        browserHistory.push({
          pathname: location.pathname,
          search: `?${queryString.stringify(location.query)}`,
        });
      }
      if (location.query.dateArrival || location.query.dateLeaving || location.query.guestsNumber) {
        getUrlDataAction(location.query);
      }
    }
  }

  componentDidMount() {
    const { location } = this.props;
    if (location && location.query && location.query.subsection) {
      updateSearchSubsection(Number(location.query.subsection));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { location } = this.props;
    const { establishmentModal } = this.state;

    if (location.query.modal && location.query.modal !== nextProps.location.query.modal) {
      hideModalByName(location.query.modal);
    }

    if (establishmentModal && !nextProps.location.query.establishment) {
      this.hideEstablishmentModal(establishmentModal);
      return;
    }

    if (
      nextProps.location.query.establishment
      && nextProps.location.query.establishment !== establishmentModal
    ) {
      if (establishmentModal) this.hideEstablishmentModal(establishmentModal);
      this.showEstablishmentModal(nextProps.location.query.establishment);
    }
  }

  showEstablishmentModal(establishmentId) {
    const { showEstablishmentAction } = this.props;
    this.setState({ establishmentModal: Number(establishmentId) });
    showEstablishmentAction(Number(establishmentId));
  }

  hideEstablishmentModal(establishmentId) {
    const { hideEstablishmentAction } = this.props;
    this.setState({ establishmentModal: null });
    hideEstablishmentAction(Number(establishmentId));
  }

  render() {
    const { children } = this.props;

    return (
      <div className={ getClassNameByDevice() }>
        { children }
        <ModalContainer />
      </div>
    );
  }
}

Container.propTypes = {
  children: PropTypes.shape().isRequired,
  getUrlDataAction: PropTypes.func.isRequired,
  hideEstablishmentAction: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
  params: PropTypes.shape().isRequired,
  setStandAloneModeAction: PropTypes.func.isRequired,
  showEstablishmentAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getUrlDataAction: getUrlData,
    hideEstablishmentAction: hideEstablishment,
    setStandAloneModeAction: setStandAloneMode,
    showEstablishmentAction: showEstablishment,
    showModalAction: showModal,
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(Container);
