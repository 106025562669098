import { ACTIONS_ALERT_FAVORITE, ACTIONS_REGISTER, MODALS } from 'constants/constants';
import { addedFavouriteNotification, deletedFavouriteNotification } from 'services/notification';
import { requestEstablishments } from 'actions/requestActions';
import { showModal } from 'actions/modals';
import { trackFavorite } from 'services/tracking';
import Wishlist from 'services/Wishlist';
import { WISHLIST_ACTIONS } from 'constants/actions';


export const requestFavouriteAction = establishmentId => ({
  establishmentId,
  type: WISHLIST_ACTIONS.REQUEST_FAVOURITE_ACTION,
});

export const addFavourite = establishmentId => ({
  establishmentId,
  type: WISHLIST_ACTIONS.ADD_FAVOURITE,
});

export const changeFavouritesPage = page => ({
  page,
  type: WISHLIST_ACTIONS.CHANGE_PAGE,
});

export const removeFavourite = establishmentId => ({
  establishmentId,
  type: WISHLIST_ACTIONS.REMOVE_FAVOURITE,
});

export const updateFavourites = establishmentIds => ({
  establishmentIds,
  type: WISHLIST_ACTIONS.UPDATE_FAVOURITES,
});

/* ****************************************************************** */
/* *************************  MIDDLEWARE **************************** */
/* ****************************************************************** */

export const retrieveFavourites = () => (dispatch, getState) => {
  const authState = getState().authReducer;

  if (!authState.authenticated) return null;

  const { user } = authState;
  return Wishlist.forUser(user).all()
    .then(lists => Promise.all(
      lists.map(list => Wishlist.forUser(user).get(list.id)
        .then((establishmentIds) => {
          dispatch(requestEstablishments(establishmentIds));
          dispatch(updateFavourites(establishmentIds));
        }))
    ));
};

export const recoverFavourite = (establishmentId, pending = false) => (dispatch, getState) => {
  // we add the favourite to the state
  // if user is logged in, we also have to sync the favorite with the service
  const authState = getState().authReducer;

  if (!authState.authenticated) {
    return suggestLogin(dispatch, getState, {
      action: WISHLIST_ACTIONS.ADD_FAVOURITE,
      establishmentId,
    });
  }

  dispatch(requestFavouriteAction(establishmentId));

  const { user } = authState;
  return getOrCreateCurrentWishlist(user)
    .then((list) => {
      dispatch(addFavourite(establishmentId));
      return Wishlist
        .forUser(user)
        .addEstablishment(list.id, establishmentId);
    })
    .then(() => { if (pending) { trackFavorite(ACTIONS_ALERT_FAVORITE.ADD, establishmentId); } })
    .catch(() => dispatch(removeFavourite(establishmentId)));
};

export const requestAddFavourite = (establishmentId, onCompleteCallback = () => {}) => (dispatch, getState) => {
  // we add the favourite to the state
  // if user is logged in, we also have to sync the favorite with the service
  const authState = getState().authReducer;

  if (!authState.authenticated) {
    return suggestLogin(dispatch, getState, {
      action: WISHLIST_ACTIONS.ADD_FAVOURITE,
      establishmentId,
      onCompleteCallback,
    });
  }

  dispatch(requestFavouriteAction(establishmentId));

  const { user } = authState;
  return getOrCreateCurrentWishlist(user)
    .then((list) => {
      dispatch(addFavourite(establishmentId));
      return Wishlist
        .forUser(user)
        .addEstablishment(list.id, establishmentId)
        .then(() => addedFavouriteNotification());
    })
    .then(() => onCompleteCallback())
    .catch(() => dispatch(removeFavourite(establishmentId)));
};

// Request to remove a favourite establishment
export const requestRemoveFavourite = establishmentId => (dispatch, getState) => {
  // we remove the favourite from the state
  // if user is logged in, we also have to sync the removal with the service
  const authState = getState().authReducer;

  if (!authState.authenticated) {
    return suggestLogin(dispatch, getState, {
      action: WISHLIST_ACTIONS.REMOVE_FAVOURITE,
      establishmentId,
    });
  }

  dispatch(requestFavouriteAction(establishmentId));

  const { user } = authState;
  return getOrCreateCurrentWishlist(user)
    .then((list) => {
      dispatch(removeFavourite(establishmentId));
      return Wishlist
        .forUser(user)
        .deleteEstablishment(list.id, establishmentId)
        .then(() => deletedFavouriteNotification(establishmentId));
    })
    .catch(() => dispatch(addFavourite(establishmentId)));
};

function getOrCreateCurrentWishlist(user) {
  // todo: add "current" handling when the service supports it
  const userWishlists = Wishlist.forUser(user);
  return userWishlists
    .all()
    .then(lists => lists[0])
    .then(defaultList => defaultList || userWishlists.create({
      name: 'Favoritos',
      user_id: user.id,
    }))
    .catch((error) => {});

}

function suggestLogin(dispatch, getState, params) {
  dispatch(showModal(MODALS.LOGIN, {
    ...params,
    origin: ACTIONS_REGISTER.FAVORITE,
    view: 'login',
  }));
}
