import { getURIS, NON_OCCIDENTAL_COUNTRIES } from 'constants/constants';
import { hasKanji } from 'services/slug';
import resource from './JsonApiResource';


class GeoService {
  constructor(url) { this.path = url; }

  get() { return resource.get(this.path, {}, true).then(resp => resp); }
}

function getBoundingBox(query) {
  return new GeoService(`${getURIS().GEO}/v1/geo?${query}`).get();
}

function getZones(polygonId) {
  return new GeoService(`${getURIS().GEO}/v1/zones?polygon_id=${polygonId}`).get();
}

export function getReverseGeocoding(cords) {
  return new GeoService(`${getURIS().GEO}/v1/reverse?lat_sw=${cords.lat_sw}&lng_sw=${cords.lng_sw}&lat_ne=${cords.lat_ne}&lng_ne=${cords.lng_ne}`)
    .get().then((res) => {
      if (!res) {
        return {
          geoFailed: true,
          lat: (cords.lat_ne + cords.lat_sw) / 2,
          lng: (cords.lng_ne + cords.lng_sw) / 2,
          ...cords,
        };
      }
      const result = res[0];
      return {
        place_id: result.place_id,
        polygon_id: result.polygon_id,
        name: result.name,
        country: result.country,
        lat: (cords.lat_ne + cords.lat_sw) / 2,
        lng: (cords.lng_ne + cords.lng_sw) / 2,
        romaji_name: result.romaji_name,
        tags: result.tags,
        ...cords,
      };
    });
}

export function getBoundingBoxByName(placeName, language) {
  const needsRomaji = NON_OCCIDENTAL_COUNTRIES.includes(global.hr.intl.COUNTRY_CODE) && hasKanji(placeName);
  return getBoundingBox(`place_name=${placeName}&language=${language}&romaji=${needsRomaji}`);
}

export function getBoundingBoxById(placeId) {
  const needsRomaji = NON_OCCIDENTAL_COUNTRIES.includes(global.hr.intl.COUNTRY_CODE);
  return getBoundingBox(`place_id=${placeId}&romaji=${needsRomaji}`);
}

export function getZonesByPolygonId(polygonId) {
  return getZones(polygonId);
}
