import { FILTERS_ACTIONS, RECOMMENDATION_ACTIONS, SEARCH_FORM_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';
import { ORDERING, RECOMMENDED_SNIPPET_POSITIONS } from 'constants/constants';
import { filterAll } from 'service-client-js/app/lib/helpers/filters';
import { getFiltersApplied } from 'reducers/filtersReducer';
import Hr from 'service-client-js';
import { isSameDate } from 'helpers/dates-helper';
import { merge } from 'helpers/array-helper';

const numberOfRecommendeds = 10;
const resultsPageInitialState = {
  currentPage: 1,
  establishmentOrder: ORDERING.ORDER_NONE,
  isSearchFromMap: false,
  loadProgress: 0,
  loggerSearchId: null,
  modalShowed: null,
  numEstablishments: 0,
  partners: {},
  polygon: null,
  prediction: null,
  recommendedEstablishments: {},
  recommendedIds: [],
  removedEstablishments: [],
  searchHash: null,
  showMap: false,
  showSearchAsFinished: false,
  veilType: null,
  visibleEstablishments: [],
  zones: [],
};

export default function searchResultsReducer(state = resultsPageInitialState, action) {
  switch (action.type) {

    case SEARCH_RESULTS_ACTIONS.RESET_RESULTS_PAGE:
      return Object.assign({}, state, {
        currentPage: 1,
        loadProgress: 0,
        numEstablishments: 0,
        recommendedEstablishments: {},
        recommendedIds: [],
        searchHash: null,
        showSearchAsFinished: false,
        visibleEstablishments: [],
        zones: [],
      });

    case FILTERS_ACTIONS.UPDATE_FILTERS:
      Hr.establishment.search.filter(action.filters, true);
      return Object.assign({}, state, Hr.establishment.search.getPage(0), {
        currentPage: 1,
      });

    case SEARCH_RESULTS_ACTIONS.ORDER_ESTABLISHMENTS:
      Hr.establishment.search.sort(action.order);
      Hr.establishment.search.filter(action.filters, true);
      return Object.assign({}, state, Hr.establishment.search.getPage(0), {
        currentPage: 1,
        establishmentOrder: action.order,
      });

    case SEARCH_RESULTS_ACTIONS.SELECT_ESTABLISHMENTS_PAGE:
      return Object.assign({}, state, Hr.establishment.search.getPage(action.page - 1), {
        currentPage: action.page || 1,
      });

    case SEARCH_RESULTS_ACTIONS.REMOVE_ESTABLISHMENT:
      Hr.establishment.search.removeEstablishment(action.establishmentId);
      return Object.assign({}, state, Hr.establishment.search.getPage(state.currentPage - 1), {
        recommendedEstablishments: Object.keys(state.recommendedEstablishments).reduce((res, cur) => {
          if (cur !== String(action.establishmentId)) {
            res[cur] = state.recommendedEstablishments[cur];
          }
          return res;
        }, {}),
        recommendedIds: [...state.recommendedIds.filter(id => id !== action.establishmentId)],
        removedEstablishments: [...state.removedEstablishments, action.establishmentId],
      });

    case SEARCH_RESULTS_ACTIONS.START_SEARCH:
      return Object.assign({}, state, {
        searchHash: action.hash,
      });

    case SEARCH_RESULTS_ACTIONS.FETCHING_ESTABLISHMENTS_IN_AREA:
      return Object.assign({}, state, {
        currentPage: 1,
        isSearchFromMap: true,
        numEstablishments: 0,
        polygon: action.polygon,
        recommendedEstablishments: {},
        recommendedIds: [],
        visibleEstablishments: [],
      });

    case SEARCH_FORM_ACTIONS.SUBMIT:
      return {
        ...state,
        polygon: state.isSearchFromMap ? state.polygon : null,
      };

    case SEARCH_FORM_ACTIONS.SEARCH_FROM_MAP:
      return {
        ...state,
        isSearchFromMap: action.isSearchFromMap,
      };

    case SEARCH_RESULTS_ACTIONS.RECEIVE_VISIBLE_ESTABLISHMENTS:
      return Object.assign({}, state, action.establishments, {
        loadProgress: action.searchStatus || 0,
        showSearchAsFinished: action.searchStatus && action.searchStatus >= 100,
      });

    case SEARCH_RESULTS_ACTIONS.RECEIVE_PARTNERS:
      return Object.assign({}, state, {
        partners: action.partners,
      });

    case SEARCH_RESULTS_ACTIONS.SHOW_MODAL:
      return Object.assign({}, state, {
        modalShowed: action.modalShowed,
        veilType: action.veilType,
      });

    case SEARCH_RESULTS_ACTIONS.SHOW_MAP:
      return Object.assign({}, state, {
        showMap: action.show,
      });

    case SEARCH_RESULTS_ACTIONS.FINISH_SEARCH:
      return Object.assign({}, state, {
        loadProgress: 100,
        showSearchAsFinished: true,
      });

    case SEARCH_RESULTS_ACTIONS.STORE_SEARCH_ID:
      return Object.assign({}, state, {
        loggerSearchId: action.loggerSearchId,
      });

    case SEARCH_RESULTS_ACTIONS.UPDATE_ZONES:
      return Object.assign({}, state, {
        zones: action.zones,
      });

    case SEARCH_RESULTS_ACTIONS.CLEAN_ZONES:
      return Object.assign({}, state, {
        zones: [],
      });

    case SEARCH_RESULTS_ACTIONS.UPDATE_PRICE_PREDICTION:
      return Object.assign({}, state, {
        prediction: action.prediction,
      });

    case SEARCH_RESULTS_ACTIONS.CLEAN_PRICE_PREDICTION:
      return Object.assign({}, state, {
        prediction: null,
      });

    case RECOMMENDATION_ACTIONS.RECEIVE_RECOMMENDATIONS:
      return Object.assign({}, state, {
        recommendedEstablishments: Object.assign({}, state.recommendedEstablishments, action.recommendations),
        recommendedIds: Object.entries(Object.assign({}, state.recommendedEstablishments, action.recommendations))
          .filter(([key]) => !state.removedEstablishments.includes(Number(key)))
          .sort((a, b) => b[1] - a[1])
          .slice(0, numberOfRecommendeds)
          .map(([key]) => Number(key)),
      });

    default:
      return state;
  }
}

function getRecommendations(state) {
  const numberOfFilters = getFiltersApplied(state);
  const hasFilters = numberOfFilters > 0;

  if (hasFilters) {
    const recommendedIds = Object.keys(state.searchResultsReducer.recommendedEstablishments).map(k => Number(k));
    const recommendedEstablishments = Object.keys(state.searchResultsReducer.recommendedEstablishments).reduce((res, cur) => {
      res[cur] = state.establishments[cur];
      return res;
    }, {});
    const filteredRecommendations = filterAll(recommendedIds, recommendedEstablishments, state.filtersApplied);
    return Object.entries(state.searchResultsReducer.recommendedEstablishments)
      .filter(([key]) => filteredRecommendations.includes(Number(key)) && !state.searchResultsReducer.removedEstablishments.includes(Number(key)))
      .sort((a, b) => b[1] - a[1])
      .slice(0, numberOfRecommendeds)
      .map(([key]) => Number(key));
  }

  return state.searchResultsReducer.recommendedIds;
}

export function getCurrentPage(state) {
  return state.currentPage;
}

export function getAreAnySearchResults(state) {
  return state.numEstablishments > 0;
}

export function getVisibleEstablishmentIds(state) {
  const recommendedPositions = RECOMMENDED_SNIPPET_POSITIONS[global.hr.intl.COUNTRY_CODE][global.isPhone ? 'mobile' : 'desktop'];
  const isFirstPage = state.searchResultsReducer.currentPage === 1;
  const isDefaultSorting = state.searchResultsReducer.establishmentOrder === ORDERING.ORDER_NONE;

  if (isFirstPage) {
    const recommendations = getRecommendations(state);
    if (!isDefaultSorting) {
      return [state.searchResultsReducer.visibleEstablishments, recommendations];
    }
    const visibleEstablishments = merge(state.searchResultsReducer.visibleEstablishments.filter(id => !recommendations.includes(id)), [...recommendations], recommendedPositions);
    return [visibleEstablishments, recommendations];
  }

  return [state.searchResultsReducer.visibleEstablishments, []];
}

export function getVisibleEstablishmentsNumber(state) {
  return state.numEstablishments;
}

export function getVisibleEstablishmentsNumPages(state, pageSize) {
  return Math.ceil(state.numEstablishments / pageSize);
}

export function getIsEstablishmentInCurrentPage(state, id) {
  return state.visibleEstablishments.some(establishment => establishment === id);
}

export function getPricePrediction(state, dateArrival) {
  const shouldShowPrediction = getAreAnySearchResults(state);
  if (!shouldShowPrediction) return null;

  const { prediction } = state;
  const isPredictionAvailable = (
    prediction
     && dateArrival
     && prediction.prices.findIndex(({ date }) => isSameDate(date, dateArrival, 'DD-MM-YYYY')) !== -1
  );
  return isPredictionAvailable ? prediction : null;
}
