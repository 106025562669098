import { signRequest } from './signature';


export default {
  create(url, type, attributes, headers = {}) {
    const body = prepareDataForRequest(type, attributes);
    const headersToFetch = createHeaders(Object.assign({}, headers, { 'Content-Type': 'application/json' }));
    return fetch(url, {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: headersToFetch,
      method: 'POST',
    }).then(parseJsonAndValidate)
      .catch((error) => {
        if (![404, 403, 409].includes(error.status)) {}
        throw error;
      });
  },
  delete(url, headers = {}, body = {}) {
    const headersToFetch = createHeaders(headers);
    return fetch(url, {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: headersToFetch,
      method: 'DELETE',
    }).then(parseJsonAndValidate)
      .catch((error) => {});
  },
  get(url, headers = {}, includeSignature = false) {
    const headersToFetch = createHeaders(headers);

    if (includeSignature) {
      headers['X-Signature'] = signRequest(url);
    }
    return fetch(url, {
      credentials: 'include',
      headers: createHeaders(headers),
    }).then(parseJsonAndValidate)
      .catch((error) => {
        if (![400, 403, 404, 500, 502, undefined].includes(error.status)) {}
      });
  },
  post(url, data) {
    return fetch(url, {
      body: JSON.stringify(data),
      credentials: 'include',
      headers: createHeaders({ 'Content-Type': 'application/json' }),
      method: 'POST',
    });
  },
  update(url, type, attributes, headers = {}) {
    const body = prepareDataForRequest(type, attributes);
    const headersToFetch = createHeaders(Object.assign({}, headers, { 'Content-Type': 'application/json' }));
    return fetch(url, {
      body: JSON.stringify(body),
      credentials: 'include',
      headers: headersToFetch,
      method: 'PUT',
    }).then(parseJsonAndValidate)
      .catch((error) => {
        if (![404, 403].includes(error.status)) {}
        throw error;
      });
  },
};

function parseJsonAndValidate(response) {
  return response.json().then((body) => {
    if (body.error) {
      throw Object.assign({}, body.error, { url: response.url });
    }
    return body.data;
  });
}

function prepareDataForRequest(type, fields) {
  return {
    data: {
      attributes: fields,
      type,
    },
  };
}

function createHeaders(customHeaders) {
  const headers = new Headers(customHeaders);
  // set default headers
  headers.append('Accept', 'application/json');
  return headers;
}
