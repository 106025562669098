import { ADJECTIVES, AVAILABILITY, ESTABLISHMENT_TYPE, SERVICES } from 'constants/constants';
import { availabilityLiterals, establishmentTypesLiteral, servicesLiteral } from 'constants/literals';
import { t } from 'services/Intl';

export const ESTABLISHMENT_TYPE_FILTER = Object.keys(ESTABLISHMENT_TYPE).reduce((acc, key) => {
  acc[ESTABLISHMENT_TYPE[key]] = {
    icon: 'hundredicons-door',
    key,
    text: establishmentTypesLiteral(t, ESTABLISHMENT_TYPE[key]),
  };
  return acc;
}, {});

export const SERVICES_FILTER = Object.keys(SERVICES).reduce((acc, key) => {
  acc[SERVICES[key]] = {
    name: servicesLiteral(t, SERVICES[key]),
  };
  return acc;
}, {});

const AVAILABILITY_ICONS = {
  [AVAILABILITY.INSTANTLY]: 'hundredicons-bolt-filled',
  [AVAILABILITY.UNDER_DEMAND]: 'hundredicons-percent',
};
export const AVAILABILITY_FILTER = Object.keys(AVAILABILITY).reduce((acc, key) => {
  if (AVAILABILITY[key] === AVAILABILITY.UNCONFIRMED) return acc;
  acc[AVAILABILITY[key]] = {
    icon: AVAILABILITY_ICONS[AVAILABILITY[key]],
    text: availabilityLiterals(t, AVAILABILITY[key]),
  };
  return acc;
}, {});

export const OPTIONS_FILTER_MAP = {
  CANCELLATION: 'cancellation',
  HOT_DEALS: 'hot-deals',
  INSTANTLY_BOOKING: [AVAILABILITY.INSTANTLY],
};

export const OPTIONS_FILTER = {
  [OPTIONS_FILTER_MAP.CANCELLATION]: {
    image: '/img/cancellation/full_cancellation_icon.svg',
    subtext: t("Holiday rentals with free cancellation at the time of reservation"),
    text: t("Free cancellation"),
  },
  [OPTIONS_FILTER_MAP.HOT_DEALS]: {
    icon: 'hundredicons-percent',
    subtext: t("Guaranteed savings on accommodation compared to several websites"),
    text: t("Discounts"),
  },
  [OPTIONS_FILTER_MAP.INSTANTLY_BOOKING]: {
    icon: 'hundredicons-bolt-filled',
    subtext: t("With the immediate reservation the accommodation is yours at the moment"),
    text: availabilityLiterals(t, AVAILABILITY.INSTANTLY),
  },
};

export const HEADER_FILTER_TYPE = {
  ESTABLISHMENT_TYPE: 'ESTABLISHMENT_TYPE',
  MORE: 'MORE',
  PRICE_EVOLUTION: 'PRICE_EVOLUTION',
  PRICE_RANGE: 'PRICE_RANGE',
  SERVICES: 'SERVICES',
  VALORATION: 'VALORATION',
};

export const VALORATION_FILTER = [
  { adjective: ADJECTIVES.EXCEPTIONAL,
    id: 'one',
    rating: 9.5,
  },
  { adjective: ADJECTIVES.FANTASTIC,
    id: 'two',
    rating: 8.5,
  },
  { adjective: ADJECTIVES.VERY_GOOD,
    id: 'three',
    rating: 8,
  },
  { adjective: ADJECTIVES.GOOD,
    id: 'four',
    rating: 7,
  },
];

export const STARS_FILTER = [1, 2, 3, 4, 5];
