/* Service base url is injected by Webpack */
import { getURIS } from 'constants/constants';
import resource from './JsonApiResource';


const PATH = '/user/{user_id}/wishlist';

export default class WishlistService {

  static forUser(user) {
    const baseUrl = WishlistService.baseUrl || getURIS().WISHLIST;
    const url = baseUrl + PATH.replace('{user_id}', user.id);
    return new WishlistService(url, user.user_token);
  }

  /**
   * @param  {string}   resourcePath The resourcePath for wishlists
   * @param  {function} fetchFn      fetch function. Defaults to fetch. Useful for tests
   */
  constructor(resourcePath, userToken) {
    this.path = resourcePath;
    this.headers = { AUTHORIZATION: userToken };
  }

  /**
   * Gets one wishlist
   * @return {object} wishlist data object
   */
  get(listId) {
    return resource.get(this.path.concat(`/${listId}/establishment`), this.headers)
      .then(this.mapEstablishmentsToIds);
  }

  /**
   * Gets many wishlists
   * @return {Promise} Resolves to array
   */
  all() {
    return resource.get(this.path, this.headers);
  }

  /**
   * Creates a wishlist
   * @param  {object} wishlistAttributes wishlist data
   * @return {Promise}
   */
  create(wishlistAttributes) {
    return resource.create(this.path, 'Wishlist', wishlistAttributes, this.headers);
  }

  /**
   * Updates an wishlist
   * @param  {int}     wishlistId
   * @param  {object}  wishlistAttributes
   * @return {Promise}
   */
  update() {
    // to be defined
  }

  /**
   * Adds a establishment to a wishlist
   * @param {int} wishlistId
   * @param {int} establishmentId
   * @return {Promise}
   */
  addEstablishment(wishlistId, establishmentId) {
    const body = { wishlist_id: wishlistId, establishment_id: establishmentId };
    const path = `${this.path}/${wishlistId}/establishment`;
    return resource.create(path, 'Wishlist', body, this.headers);
  }

  /**
   * Deletes an establishment from a wishlist
   * @param {int} wishlistId
   * @param {int} establishmentId
   * @return {Promise}
   */
  deleteEstablishment(wishlistId, establishmentId) {
    const path = `${this.path}/${wishlistId}/establishment/${establishmentId}`;
    return resource.delete(path, this.headers);
  }

  /**
   *  Deletes an empty wishlist from a user
   * @param {int} userId
   * @param {int} wishlistId
   */
  delete(userId, wishlistId) {
    const path = `${this.path}/${wishlistId}`;
    return resource.delete(path, this.headers);
  }

  /**
   * Gets establishments for a whishlist
   * @param   {int}     wishlistId
   * @return  {Promise} Resolves to an array of establishments ids
   */
  getEstablishments(wishlistId) {
    const path = `${this.path}/${wishlistId}/establishment`;
    return resource.get(path, this.headers).then(this.mapEstablishmentsToIds);
  }

  /**
   * Maps an array of wishlist establishments to an array of establishment ids
   * @param  {array} establishments Array of WishlistEstablishment object received from the service
   * @return {array}                Array of establishment ids
   */
  mapEstablishmentsToIds(whislistEstablishments) {
    return whislistEstablishments.map(establishment => establishment.attributes.establishment_id);
  }

}
