import { combineReducers } from 'redux';
import { WISHLIST_ACTIONS } from 'constants/actions';

const establishmentIdsInitialState = [];
function establishmentIds(state = establishmentIdsInitialState, action) {
  switch (action.type) {
    case WISHLIST_ACTIONS.ADD_FAVOURITE:
      if (state.includes(action.establishmentId)) return state;
      return [...state, action.establishmentId];

    case WISHLIST_ACTIONS.REMOVE_FAVOURITE:
      return state.filter(id => id !== action.establishmentId);

    case WISHLIST_ACTIONS.UPDATE_FAVOURITES:
      return action.establishmentIds;

    default:
      return state;
  }
}

const pageInitialState = 1;
function page(state = pageInitialState, action) {
  switch (action.type) {
    case WISHLIST_ACTIONS.CHANGE_PAGE:
      return action.page;

    default:
      return state;
  }
}

const processingEstablishmentIdInitialState = null;
function processingEstablishmentId(state = processingEstablishmentIdInitialState, action) {
  switch (action.type) {
    case WISHLIST_ACTIONS.REQUEST_FAVOURITE_ACTION:
      return action.establishmentId;

    case WISHLIST_ACTIONS.ADD_FAVOURITE:
    case WISHLIST_ACTIONS.REMOVE_FAVOURITE:
    case WISHLIST_ACTIONS.UPDATE_FAVOURITES:
      return processingEstablishmentIdInitialState;

    default:
      return state;
  }
}

export default combineReducers({
  establishmentIds,
  page,
  processingEstablishmentId,
});

export function getFavouriteEstablishmentIds(state) {
  return state.establishmentIds;
}

export function getFavouriteEstablishmentsNumber(state) {
  return getFavouriteEstablishmentIds(state).length;
}

export function isFavouriteEstablishment(state, establishmentId) {
  return getFavouriteEstablishmentIds(state).includes(establishmentId);
}

export function getFavouritesPage(state) {
  return state.page;
}

function getProcessingFavouriteEstablishmentId(state) {
  return state.processingEstablishmentId;
}

export function getIsProcessingFavourite(state, establishmentId) {
  return getProcessingFavouriteEstablishmentId(state) === establishmentId;
}
