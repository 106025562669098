import * as GA from 'services/tracking/g-analytics';
import * as GTM from 'services/tracking/gtm';
import * as Logger from 'services/tracking/logger';
import * as SpotEffects from 'services/tracking/spot-effects';
import { ADS } from 'constants/constants';
import { getCurrentRoute } from 'app/route-container';
import { getDestinationName } from 'reducers/main';
import { getState } from 'services/store';
import { ID_ROUTES } from 'constants/routes';


export function trackSearch(searchOrigin) {
  if (getCurrentRoute() !== ID_ROUTES.ESTABLISHMENT) {
    GA.gASearch();
    SpotEffects.sESearch();
    Logger.logSearch(searchOrigin);
  }
}

export function trackClickout(config) {
  GA.gAClickOut(config.allPartners[config.cheapestPartner], config.id, getDestinationName(getState()));
  SpotEffects.sEClickOut();
  Logger.logClickOut(config);
}

export function trackFilter(config) {
  GA.gAFilter(config);
}

export function trackZonesFilter(config) {
  GA.gAZonesFilter(config);
}

export function trackCheckbox(config) {
  return Promise.resolve().then(() => {
    // Logger tracks only clicks with check information
    if (config.type === ADS.CLICK) Logger.logCheckbox(config);

    // Google analytics track impresions always and clicks only when checked (forced in impression)
    if (config.checked) GA.gAAdCheckbox(config);
  });
}

export function trackFavorite(action, label) {
  GA.gAFavorite(action, label);
}

export function trackFavoriteFromModal(action, label = 'favorite_modal') {
  GA.gAFavoriteFromModal(action, label);
}

export function trackShareFromEstablishment(label) {
  GA.gAShareFromEstablishment(label);
}

export function trackPublishEstablishment(action, label) {
  GA.gAPublishStablishment(action, label);
}

export function trackRegister(origin, method) {
  GA.gARegister(origin, method);
}

export function trackPageView(name, params) {
  Logger.logVisit();
  GTM.gtmPageView(params);
}

export function trackSignIn(method) {
  GA.gASignIn(method);
}

export function trackOrder(order) {
  GA.gAOrder(order);
}

export function trackShare(action, label) {
  GA.gAShare(action, label);
}

export function trackLeadAction(action, label) {
  GA.gALeadAction(action, label);
}

export function trackSnippetAction(action, label) {
  GA.gASnippetAction(action, label);
}

export function trackSharingAction(label) {
  GA.gaShareFrom(label);
}

export function trackEstablishmentModalAction(action, label) {
  GA.gAEstablishmentModalAction(action, label);
}

export function trackSearchBar(action, label) {
  GA.gASearchBar(action, label);
}

export function trackMapMobile(map) {
  GA.gAMapMobile(map);
}

export function trackCardGrid(card, action) {
  if (!(getState().searchResultsReducer.showMap)) GA.gACardGrid(card, action);
}

export function trackMapCheckbox(label) {
  GA.gAMapCheckbox(label);
}

export function trackMapResearch(label) {
  GA.gAMapResearch(label);
}

export function trackInlineHundred(action, label) {
  GA.gAInlineHundred(action, label);
}

export function trackEventsMap(action, label) {
  GA.gAEventsMap(action, label);
}

export function trackPriceEvolution(action, label) {
  GA.gAPriceEvolution(action, label);
}

export function trackUserPushes(action, label) {
  if (getCurrentRoute() !== ID_ROUTES.ESTABLISHMENT) GA.gAUserPushes(action, label);
}

export function trackHeader(action, label) {
  GA.gAHeader(action, label);
}
