import PropTypes from 'prop-types';
import React from 'react';


class LazilyLoad extends React.Component {

  constructor(...args) {
    super(...args);
    this.state = { isLoaded: false };
  }

  componentDidMount() {
    this._isMounted = true;
    this.load();
  }

  componentDidUpdate(previous) {
    const { module } = this.props;
    return (module !== previous.module) ? this.load() : null;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  load() {
    this.setState({ isLoaded: false });
    const { module } = this.props;
    module().then(component => (!this._isMounted ? null : this.setState({ module: component, isLoaded: true })));
  }

  render() {
    const { isLoaded, module } = this.state;
    if (!isLoaded) return null;
    const Module = module && module.default;
    return <Module { ...this.props } />;
  }
}

LazilyLoad.propTypes = {
  module: PropTypes.func.isRequired,
};

export default LazilyLoad;
