/* global ga */
import { ADS, ORDERING_EVENTS } from 'constants/constants';
import makeSlug, { hasKanji } from 'services/slug';
import { clickOut } from 'services/tracking/g-analytics/clickout';
import { FILTERS_ACTION_NAMES } from 'constants/tracking';
import { getState } from 'services/store';


function percentTransform(value) {
  return Math.round(value * 100);
}

export function gASearch() {
  const state = getState();
  const { searchHash } = state.searchResultsReducer;
  const { name } = state.destination;
  const { name: modal } = state.modals;
  return modal !== 'establishment' ? ga('send', 'event', 'search', searchHash, makeSlug(name)) : '';
}

export const gAClickOut = clickOut;

export function gAAdCheckbox(checkbox) {
  const action = checkbox.type;
  const send = ['send', 'event', 'ad-checkbox', makeSlug(hasKanji(checkbox.destination.name) ? checkbox.destination.romaji_name : checkbox.destination.name), `${checkbox.name}-${checkbox.order}-${action}`];
  if (action === ADS.CLICK) send.push(percentTransform(checkbox.cpc));
  return ga(...send);
}

export function gACheckbox(adCheckbox, type, origin) {
  if (!adCheckbox.length) return null;
  return ga('send', 'event', 'checkbox', `${type}-checkbox-${adCheckbox[0].name}`, origin);
}

export function gASignIn(method) {
  return ga('send', 'event', 'sign-in', method);
}

export function gARegister(origin, method) {
  return ga('send', 'event', 'register', origin, method);
}

export function gAFavorite(action, label) {
  return ga('send', 'event', 'favorite', action, label);
}

export function gAFavoriteFromModal(action, label) {
  return ga('send', 'event', 'favorite', label, action.toLowerCase());
}

export function gAPublishStablishment(action, label) {
  return ga('send', 'event', 'publish partner', action, label);
}

export function gAClickEstablishmentImage(action, establishmentId) {
  return ga('send', 'event', 'click-img', action, establishmentId);
}

export function gAShareFromEstablishment(label) {
  return ga('send', 'event', 'share', 'share_modal', label);
}

export function gAClickEstablishmentInfo(action) {
  return ga('send', 'event', 'listado-info', action);
}

export function gAClickElement(element) {
  return ga('send', 'event', 'clicked-element', element);
}

export function gAClickEstablishmentTab(tab) {
  return ga('send', 'event', 'InfoAd', tab);
}

export function gAFilter(config) {
  const send = ['send', 'event', 'Filters', config.action];
  if (global.isPhone && config.action === FILTERS_ACTION_NAMES.OPEN_FILTERS) {
    const { showMap } = getState().searchResultsReducer;
    send.push(showMap ? 'from map' : 'from serp');
  } else {
    send.push(config.label.toString());
  }
  return ga(...send);
}

export function gAZonesFilter(config) {
  return ga('send', 'event', 'Relevant_zones', config.location, config.zone);
}

export function gAClickCardMapElement(element, establishmentId) {
  return ga('send', 'event', 'cardmap', element, establishmentId);
}

export function gACardMap(action, label) {
  return ga('send', 'event', 'cardmap', action, label);
}

export function gAOrder(order) {
  return ga('send', 'event', 'Ordering', ORDERING_EVENTS[order]);
}

export function gASemSearchAction(action) {
  return ga('send', 'event', 'search_sem', action);
}

export function gALeadAction(action, label) {
  return ga('send', 'event', 'lead_form', action, label);
}

export function gASnippetAction(action, label) {
  if (action === "sentimental Analysis") return ga("send", "event", "swippe-info", "sentimental Analysis");
  return ga('send', 'event', 'Open Modal', action, label);
}

export function gAEstablishmentModalAction(action, label) {
  return ga('send', 'event', 'Modal', action, label);
}

export function gASearchBar(action, label) {
  return ga('send', 'event', 'searchbar', action, label);
}

export function gASimilars(action, label) {
  return ga('send', 'event', 'similars', action, label);
}

export function gAMapMobile(map) {
  return ga("send", "event", "map mobile", map ? "sticky map" : "sticky result");
}

export function gACardGrid(card, action) {
  return ga('send', 'event', 'card-grid', card, action);
}

export function gAMapCheckbox(label) {
  return ga('send', 'event', 'map', 'checkbox-move', label);
}

export function gAMapResearch(label) {
  return ga('send', 'event', 'map', 'research', label);
}

export function gAInlineHundred(action, label) {
  return ga('send', 'event', 'inline-hundred', action, label);
}

export function gAEventsMap(action, label) {
  if (label) return ga('send', 'event', 'map', action, label);
  return ga('send', 'event', 'map', action);
}

export function gAPriceEvolution(action, label) {
  return ga('send', 'event', 'evolution-price-graphic', action, label);
}

export function gAPushModal(label) {
  return ga('send', 'event', 'smartbanner', 'modal', label);
}

export function gAUserPushes(action, label) {
  return ga('send', 'event', 'users-pushes', action, label);
}

export function gAHeader(action, label) {
  return ga('send', 'event', 'header', action, label);
}

export function gARedirectBanner(action, label) {
  return ga('send', 'event', 'smartdirect', action, label);
}

export function gaInlineSeo(action, label) {
  return ga('send', 'event', 'inline_seo', action, label);
}

export function gaPublishPartner(label) {
  return ga('send', 'event', 'publish-partner', 'click-to-publish', label);
}
