import PropTypes from 'prop-types';
import React from 'react';


export default function Typologies({ routes, t }) {
  if (!routes.tipologyFirst) return null;
  return (
    <li className="footer-molecule__first-footer__links__establishment-type">
      <ul className="footer-molecule__first-footer__links__establishment-type-container">
        <p className="footer-molecule__first-footer__links__establishment-type__title">
          { t('Establishments type') }
        </p>
        <li className="footer-molecule__first-footer__links__establishment-type__link">
          <a
            className="footer-molecule__first-footer__links__establishment-type__link__a"
            href={ `/${routes.tipologyFirst}/` }
            target="_blank"
          >
            { t('Tourist apartments') }
          </a>
        </li>
        { routes.tipologySecond
          && (
            <li className="footer-molecule__first-footer__links__establishment-type__link">
              <a
                className="footer-molecule__first-footer__links__establishment-type__link__a"
                href={ `/${routes.tipologySecond}/` }
                target="_blank"
              >
                { t('Vacational houses') }
              </a>
            </li>
          )
        }
        { routes.tipologyThird
          && (
            <li className="footer-molecule__first-footer__links__establishment-type__link">
              <a
                className="footer-molecule__first-footer__links__establishment-type__link__a"
                href={ `/${routes.tipologyThird}/` }
                target="_blank"
              >
                { t('Cottage') }
              </a>
            </li>
          )
        }
      </ul>
    </li>
  );
}

Typologies.propTypes = {
  routes: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};
