import Ad from 'services/AdService/Ad';
import { pinmapAds } from 'services/AdService/database';
import uuid4 from 'helpers/crypto';


export default class Pinmap {
  constructor(search) {
    this.countryDomain = global.hr.intl.COUNTRY_CODE;
    this.search = search;
  }

  getPinmapToShow() {
    const sortedAds = this.getAds();
    return sortedAds.length
      ? this.parseAd(sortedAds[0])
      : null;
  }

  getAds() {
    const { airbnb } = pinmapAds;
    return [airbnb];
  }

  getDevice() {
    if (global.isPhone) return 'mobile';
    if (global.isTablet) return 'tablet';
    return 'desktop';
  }

  getDeviceShort() {
    if (global.isPhone) return 'mo';
    if (global.isTablet) return 'ta';
    return 'dk';
  }

  parseAd(ad) {
    ad.checked = true;
    ad.cpc = ad.countryParams[this.countryDomain].cpc[this.getDeviceShort()];
    ad.domain = this.countryDomain;
    ad.device = this.getDeviceShort();
    ad.transactionId = uuid4();
    ad.type = 'pinmap';

    return Ad.forSearch(ad, this.search).toObject();
  }

}
