import { getUserRecommendations } from 'services/recommendation';
import { RECOMMENDATION_ACTIONS } from 'constants/actions';


const receiveRecommendations = recommendations => ({
  recommendations,
  type: RECOMMENDATION_ACTIONS.RECEIVE_RECOMMENDATIONS,
});

export const requestRecommendations = (userId, establishmentsIds) => async (dispatch) => {
  const recommendations = await getUserRecommendations(userId, establishmentsIds);
  dispatch(receiveRecommendations(recommendations));
};
