import haversine from 'haversine-distance';


export default function calculateRadius(latNe, latSw, lngNe, lngSw) {
  const northEast = { latitude: parseFloat(latNe), longitude: parseFloat(lngNe) };
  const southWest = { latitude: parseFloat(latSw), longitude: parseFloat(lngSw) };
  return haversine(northEast, southWest) / 2000;
}

export const pricePerDayShouldUpdate = (currentProps, nextProps) => ['country', 'lat', 'lng', 'latNe', 'lngNe', 'latSw', 'lngSw'].some(field => currentProps[field] !== nextProps[field]);
