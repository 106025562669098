import Hr from 'service-client-js';

export const getURIS = () => (
  typeof window === 'undefined' ? {} : {
    DESTINATION: global.hr.apis.destination || 'http://dev.hundredrooms.com:13080',
    GEO: global.hr.apis.geoService || 'http://dev.hundredrooms.com:12080',
    LEAD: global.hr.apis.searchService || 'http://dev.hundredrooms.com:10080',
    POPUNDER: global.hr.apis.popunderService || 'http://dev.hundredrooms.com',
    PREDICTOR: global.hr.apis.pricePredictorService || 'http://dev.hundredrooms.com:17080',
    RECOMMENDATION: global.hr.apis.recommendationService || 'http://dev.hundredrooms.com:17080',
    SEARCH: global.hr.apis.searchService || 'http://dev.hundredrooms.com:10080',
    USER: global.hr.apis.userService || 'http://dev.hundredrooms.com:7080',
    WISHLIST: global.hr.apis.wishlistService || 'http://dev.hundredrooms.com:6080',
  }
);

export const getClientSecretKey = () => (
  typeof window === 'undefined' ? null : global.hr.apis.secretKey
);

export const getOneSignalAppID = () => (
  typeof window === 'undefined' ? null : global.hr.apis.oneSignalAppID
);

export const getResultsMapConfig = (destination, isPhone, isTablet) => ({
  center: {
    lat: destination.lat_map || destination.lat,
    lng: destination.lng_map || destination.lng,
  },
  fullscreenControl: false,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  streetViewControl: false,
  styles: [{
    elementType: 'labels',
    featureType: 'poi',
    stylers: [{
      visibility: 'off',
    }],
  }],
  zoomControl: !isPhone && !isTablet,
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.LEFT_TOP,
  },
});

export const STANDALONE_MODE = "progressive";

export const RATING_PRICES_RANGE = typeof window === 'undefined' ? {}
  : global.hr.intl.RATING_PRICES_RANGE;

export const APP_TOKEN = 'v1-fc95241df450dc5';
export const EXTRA_PAGES_IN_MAP = 0;
export const MAX_NUMBER_OF_RECENT_SEARCHES_TO_SHOW = 3;
export const MAX_NUMBER_OF_RECENTLY_VIEWED_TO_STORE = 25;
export const MAX_NUMBER_OF_SEACH_RESULTS_IN_COUNTER = 500;
export const MAX_PRICE = 1000000;
export const MAX_STORED_ESTABLISHMENTS = 600;
export const NOTIFICATION_TTL = 5 * 1000;
export const PAGE_SIZE = 41;
export const SEARCH_TIMEOUT_SECONDS = 20;
export const REAL_TIME_ORDERING_MOMENTS = [1, 2, 3, 5, 8, 13, SEARCH_TIMEOUT_SECONDS - 1];
export const SUGGEST_LOGIN_EACH_NUMBER_OF_FAVS = 5;

export const FILTERS = {
  AVAILABILITY: 'AVAILABILITY',
  DISTANCE: 'DISTANCE',
  ESTABLISHMENT_TYPE: 'ESTABLISHMENT_TYPE',
  POI: 'POI',
  PRICE: 'PRICE',
  RATING: 'RATING',
  REVIEWS: 'REVIEWS',
  ROOMS_NUMBER: 'ROOMS_NUMBER',
  SERVICES: 'SERVICES',
};

export const PRICE_FILTER_TYPE = {
  DAY: 1,
  TOTAL: 2,
};

export const PRICE_RANGE_LIMIT = typeof window === 'undefined' ? {} : global.hr.intl.PRICE_RANGE_LIMIT || {};
export const PRICE_RANGES = typeof window === 'undefined' ? {} : global.hr.intl.PRICE_RANGES || {};

export const AVAILABILITY = {
  INSTANTLY: 1,
  UNCONFIRMED: 3,
  UNDER_DEMAND: 2,
};

export const SERVICES = {
  SERVICE_AIR_CONDITIONING: 8,
  SERVICE_BALCONY: 17,
  SERVICE_BREAKFAST: 14,
  SERVICE_CABLE_TV: 5,
  SERVICE_CRADLE: 19,
  SERVICE_DOORMAN: 16,
  SERVICE_FREE_CLEANING: 10,
  SERVICE_GYM: 13,
  SERVICE_HANDICAPPED: 11,
  SERVICE_HEAT: 20,
  SERVICE_INTERNET: 4,
  SERVICE_KITCHEN: 2,
  SERVICE_LAUNDRY: 9,
  SERVICE_LIFT: 15,
  SERVICE_OUTDOOR_SPACE: 18,
  SERVICE_PARKING: 7,
  SERVICE_PETS: 21,
  SERVICE_SWIMMING_POOL: 12,
  SERVICE_TOWELS: 1,
  SERVICE_TV: 6,
  SERVICE_WIFI: 3,
};
export const SERVICES_IDS = {
  [SERVICES.SERVICE_AIR_CONDITIONING]: 'air_conditioning',
  [SERVICES.SERVICE_BALCONY]: 'balcony',
  [SERVICES.SERVICE_BREAKFAST]: 'breakfast',
  [SERVICES.SERVICE_CABLE_TV]: 'cable_tv',
  [SERVICES.SERVICE_CRADLE]: 'cradle',
  [SERVICES.SERVICE_DOORMAN]: 'doorman',
  [SERVICES.SERVICE_FREE_CLEANING]: 'free_cleaning',
  [SERVICES.SERVICE_GYM]: 'gym',
  [SERVICES.SERVICE_HANDICAPPED]: 'handicapped',
  [SERVICES.SERVICE_HEAT]: 'heat',
  [SERVICES.SERVICE_INTERNET]: 'internet',
  [SERVICES.SERVICE_KITCHEN]: 'kitchen',
  [SERVICES.SERVICE_LAUNDRY]: 'laundry',
  [SERVICES.SERVICE_LIFT]: 'lift',
  [SERVICES.SERVICE_OUTDOOR_SPACE]: 'outdoor_space',
  [SERVICES.SERVICE_PARKING]: 'parking',
  [SERVICES.SERVICE_PETS]: 'pets',
  [SERVICES.SERVICE_SWIMMING_POOL]: 'swimming_pool',
  [SERVICES.SERVICE_TOWELS]: 'towels',
  [SERVICES.SERVICE_TV]: 'tv',
  [SERVICES.SERVICE_WIFI]: 'wifi',
};
export const MAX_SERVICES_TO_SHOW = 6;
export const MAX_SERVICES_TO_SHOW_MORE_FILTERS = 8;
export const MAX_ZONES_TO_SHOW = 6;
export const SERVICES_ORDER = [3, 12, 21, 18, 6, 8, 1, 11, 7, 9, 2, 17, 20, 4, 15, 10, 14, 19, 5, 13, 16];

export const ESTABLISHMENT_TYPE = {
  APARTHOTEL: 5,
  APARTMENT: 1,
  HOTEL: 2,
  HOUSE: 3,
  ROOM: 4,
};

export const TYPE_ESTABLISHMENT = {
  [ESTABLISHMENT_TYPE.APARTHOTEL]: 'aparthotel',
  [ESTABLISHMENT_TYPE.APARTMENT]: 'apartment',
  [ESTABLISHMENT_TYPE.HOTEL]: 'hotel',
  [ESTABLISHMENT_TYPE.HOUSE]: 'house',
  [ESTABLISHMENT_TYPE.ROOM]: 'room',
};

export const ESTABLISHMENT_TYPES_WITH_NO_REQUIRED_LICENSE = [
  ESTABLISHMENT_TYPE.HOTEL,
  ESTABLISHMENT_TYPE.ROOM,
];

export const ESTABLISHMENT_ORDER = [1, 3, 4, 2, 5];
export const HOTEL_CATEGORIES = [ESTABLISHMENT_TYPE.HOTEL, ESTABLISHMENT_TYPE.APARTHOTEL];
export const { ORDERING, RANKING_LOW_LIMIT } = Hr;

export const ORDERING_EVENTS = {
  [ORDERING.ORDER_BY_HOT_DEALS]: 'Discount',
  [ORDERING.ORDER_BY_PRICE_ASC]: 'Cheapest',
  [ORDERING.ORDER_BY_PRICE_DESC]: 'Expensive',
  [ORDERING.ORDER_BY_RATING]: 'partner remark',
  [ORDERING.ORDER_NONE]: 'Default',
};

export const ADS = {
  CHECKBOXES_TO_SHOW: 1,
  CLICK: 'CLICK',
  IMPRESS: 'IMPRESS',
};

export const INLINE_SNIPPET_POSTIONS = {
  ar: { desktop: [1], mobile: [6] },
  au: { desktop: [1], mobile: [6] },
  ch: { desktop: [1], mobile: [6] },
  cl: { desktop: [1], mobile: [6] },
  co: { desktop: [1], mobile: [6] },
  de: { desktop: [1, 14, 22, 33], mobile: [6, 20, 28, 39] },
  es: { desktop: [1], mobile: [6] },
  fr: { desktop: [1], mobile: [6] },
  gb: { desktop: [1], mobile: [6] },
  it: { desktop: [1], mobile: [6] },
  jp: { desktop: [1], mobile: [6] },
  mx: { desktop: [1], mobile: [6] },
  pe: { desktop: [1], mobile: [6] },
  pl: { desktop: [1], mobile: [6] },
  us: { desktop: [1], mobile: [6] },
  uy: { desktop: [1], mobile: [6] },
};

export const RECOMMENDED_SNIPPET_POSITIONS = {
  ar: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  au: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  ch: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  cl: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  co: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  de: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  es: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  fr: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  gb: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  it: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  jp: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  mx: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  pe: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  pl: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  us: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
  uy: { desktop: [2, 3, 6, 7, 9, 10, 12, 15, 16, 18], mobile: [1, 2, 5, 7, 9, 10, 12, 15, 16, 18] },
};

export const CLICK_EVENTS = {
  SECTIONS: {
    MAP: 1,
    SEARCH: 0,
  },
  SUBSECTIONS: {
    BUTTON: 0,
    BUTTON_MAPCARD: 7,
    IMAGE: 5,
    IMAGE_MAPCARD: 8,
    MATCHING: 1,
    MORE_DETAILS: 2,
    PRICE_DETAILS: 3,
    TITLE: 4,
    TITLE_MAPCARD: 6,
  },
};

export const CLICK_SECTIONS = {
  ESTABLISHMENT: 2,
  FAVOURITES: 4,
  MAP: 1,
  RECENTLY_VIEWED: 3,
  SEO_ESTABLISHMENT: 5,
  SERP: 0,
};

export const CLICKOUT_SUBSECTIONS = {
  CARDMAP_CTA: 7,
  ESTABLISHMENT_BEST_PRICE: 40,
  ESTABLISHMENT_CANCELLATION: 28,
  ESTABLISHMENT_CTA: 11,
  ESTABLISHMENT_CTA_STICKY: 29,
  ESTABLISHMENT_IMAGE: 25,
  ESTABLISHMENT_LINK_STICKY: 30,
  ESTABLISHMENT_MATCHING: 12,
  ESTABLISHMENT_MATCHING_GRID_COMMENTS: 35,
  ESTABLISHMENT_MATCHING_GRID_CTA: 33,
  ESTABLISHMENT_MATCHING_GRID_EXTRAS: 37,
  ESTABLISHMENT_MATCHING_GRID_LOGO: 34,
  ESTABLISHMENT_MATCHING_GRID_PRICE: 36,
  ESTABLISHMENT_MATCHING_GRID_RATING: 38,
  ESTABLISHMENT_PARTNER_LOGO: 39,
  ESTABLISHMENT_PRICE_OTHER: 42,
  ESTABLISHMENT_PRICE_WINNER: 41,
  ESTABLISHMENT_READ_MORE: 13,
};

export const CLICKIN_SUBSECTIONS = {
  CARDMAP: 8,
  MULTICARD_AMENITIES: 1,
  MULTICARD_CANCELLATION: 10,
  MULTICARD_IMAGE: 4,
  MULTICARD_MATCHING: 5,
  MULTICARD_RATING: 9,
  MULTICARD_REVIEWS: 2,
  MULTICARD_TITLE: 0,
  PINTOOL: 10,
  SIMILAR: 7,
};

export const SHARE_METHODS = {
  COPY: 'COPY',
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  TWITTER: 'TWITTER',
  WHATSAPP: 'WHATSAPP',
};

export const SHARE_TYPES = {
  RESULT: 'RESULT',
  SEARCH: 'SEARCH',
};

export const SHARE_ORIGINS = {
  ESTABLISHMENT_MODAL: 'ESTABLISHMENT_MODAL',
  RECENT_SEARCHES: 'RECENT_SEARCHES',
  SEARCH: 'SEARCH',
  SEARCH_MODAL: 'SEARCH_MODAL',
  VIEWED: 'VIEWED',
  WISHLIST: 'WISHLIST',
};

export const SHARE_BASE_URLS = {
  FACEBOOK: `https://www.facebook.com/sharer.php?mode=message&${global.isPhone || global.isTablet ? 'u=' : 's=100&p[url]='}`,
  GOOGLE: 'https://plus.google.com/share?url=',
  TWITTER: 'https://twitter.com/intent/tweet?url=',
  WHATSAPP: 'whatsapp://send?text=',
};

export const SHARE_POPUP_SIZE = {
  HEIGHT: 350,
  WIDTH: 520,
};

export const LOGIN_METHODS = {
  EMAIL: 'EMAIL',
  FACEBOOK: 'FACEBOOK',
  GOOGLE: 'GOOGLE',
  TWITTER: 'TWITTER',
};

export const ACTIONS_ALERT_FAVORITE = {
  ADD: 'ADD',
  CLICK_ELEMENT: 'CLICK_ELEMENT',
  REMOVE: 'REMOVE',
  SHARE: 'SHARE',
};

export const ACTIONS_REGISTER = {
  FAVORITE: 'FAVORITE',
  LOGIN: 'LOGIN',
};

export const ALERT_POPUP_TYPES = {
  BUTTON_FIX: 'BUTTON_FIX',
  CARD: 'CARD',
  ESTABLISHMENT_MODAL: 'ESTABLISHMENT_MODAL',
  FLOAT_REGISTER: 'FLOAT_REGISTER',
  FOCUS_LOST: 'FOCUS_LOST',
  LEAD: 'LEAD',
};

export const FILTERS_TEXT = {
  AVAILABILITY: {
    [AVAILABILITY.INSTANTLY]: 'filter.instantly',
    [AVAILABILITY.UNCONFIRMED]: 'filter.unconfirmed',
    [AVAILABILITY.UNDER_DEMAND]: 'filter.demand',
  },
  ROOM_OPTIONS: [
    'filter.all',
    'filter.four',
    'filter.one',
    'filter.three',
    'filter.two',
  ],
  TYPE: {
    [ESTABLISHMENT_TYPE.APARTMENT]: 'filter.apartment',
    [ESTABLISHMENT_TYPE.HOTEL]: 'filter.hotel',
    [ESTABLISHMENT_TYPE.HOUSE]: 'filter.house',
    [ESTABLISHMENT_TYPE.ROOM]: 'filter.room',
  },
};

export const ESTABLISHMENT_INFO_ACTIONS = {
  ENTER: 'ENTER',
  RETURN: 'RETURN',
};

export const COOKIES = {
  CHECKED_CHECKBOX: 'checked-checkbox',
  CONSECUTIVE_VISIT_IN_SESSION: 'consecutive-visit-in-session',
  COOKIES_BANNER: 'cookies-banner',
  GOOGLE_CLICK_ID: 'gclid',
  MICROSOFT_CLICK_ID: 'msclid',
  OPENED_CHECKBOX: 'opened-checkbox',
  ORIGIN_PAGE: 'origin_page',
  PARTNERS: 'partners',
  REDIRECT: 'redirect-footer',
  SHOWED_CHECKBOX: 'showed-checkbox',
  SUGGESTION_BOX: 'suggestion-box',
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
};

export const MODALS = {
  COMPATIBILITY: 'compatibility',
  ESTABLISHMENT: 'establishment',
  LEADS_FORM: 'leads-form',
  LOGIN: 'login',
  NOTIFICATION: 'notification',
  POLICY: 'policy-modal',
  PUSH: 'push-modal',
  SEARCH_MOBILE: 'search-mobile',
};

export const MENUS = {
  FILTERS: 'menu_filters',
  USER: 'menu_user',
};

export const LAYERS = {
  ESPECIAL: 'ESPECIAL',
  MENUS: 'MENUS',
  NOTIFICATIONS: 'NOTIFICATIONS',
  USER_SOCIAL: 'USER_SOCIAL',
};

export const MODALS_TO_LAYERS = {
  [MENUS.FILTERS]: LAYERS.MENUS,
  [MENUS.USER]: LAYERS.MENUS,
  [MODALS.COMPATIBILITY]: LAYERS.USER_SOCIAL,
  [MODALS.DATE_PICKER]: LAYERS.USER_SOCIAL,
  [MODALS.ESTABLISHMENT]: LAYERS.ESPECIAL,
  [MODALS.LEADS_FORM]: LAYERS.USER_SOCIAL,
  [MODALS.LOGIN]: LAYERS.USER_SOCIAL,
  [MODALS.NOTIFICATION]: LAYERS.NOTIFICATIONS,
  [MODALS.POLICY]: LAYERS.USER_SOCIAL,
  [MODALS.PUSH]: LAYERS.NOTIFICATIONS,
  [MODALS.SEARCH_MOBILE]: LAYERS.USER_SOCIAL,
};

export const SIMILARS_TO_DISPLAY = {
  DESKTOP: { HIGH: 6, LOW: 3 },
  MOBILE: { HIGH: 6, LOW: 2, MEDIUM: 4 },
};

export const COUNTRIES_WITH_TV = [
  'es-es',
  'it-it',
];

export const COUNTRIES_PUSHES_ENABLED = [
  'de-de',
  'en-au',
  'en-gb',
  'en-us',
  'es-ar',
  'es-cl',
  'es-co',
  'es-es',
  'es-mx',
  'es-pe',
  'es-uy',
  'fr-fr',
  'it-it',
  'ja-jp',
];

export const DISABLED_POPUNDER_COUNTRIES = ['au', 'de', 'gb', 'us', 'ar', 'cl', 'co', 'es', 'mx', 'pe', 'uy', 'fr', 'it', 'jp', 'pl', 'ch', 'ca'];
export const PARTNERS_WITHOUT_AVAILABILITY_CLICKOUT = [23];
export const AGODA_ID = 31;
export const AIRBNB_ID = 9;
export const BELVILLA_ID = 29;
export const BOOKING_ID = 2;
export const EDOMIZIL_ID = 16;
export const HOMEAWAY_ID = 12;
export const NINEFLATS_ID = 5;
export const ONLYAPARTMENTS_ID = 15;
export const RENTALIA_ID = 19;
export const TRIPADVISOR_ID = 17;
export const TUI_ID = 32;
export const OTEL_ID = 33;
export const VACATIONSTAY_ID = 35;
export const EXPEDIA_ID = 36;
export const YOURRENTALS_ID = 38;
export const PARTNERS_WITH_AUTO_POSTBACK = {
  19: 'rentalia',
  35: 'vacationstay',
};

export const ESTAB_FALLBACK_IMAGES = [
  'https://r.100r.systems/image/upload/f_auto/1 general image-xudhflxy.jpg',
  'https://r.100r.systems/image/upload/f_auto/2 general image-zkkkthuj.jpg',
  'https://r.100r.systems/image/upload/f_auto/3 general image-rbwusrmu.jpg',
  'https://r.100r.systems/image/upload/f_auto/4 general image-mxfisqib.jpg',
  'https://r.100r.systems/image/upload/f_auto/5 general image--spnysbpg.jpg',
  'https://r.100r.systems/image/upload/f_auto/6 general image-gyabljiu.jpg',
  'https://r.100r.systems/image/upload/f_auto/7 general image-mgtxuise.jpg',
  'https://r.100r.systems/image/upload/f_auto/8 general image-pcmztjpa.jpg',
  'https://r.100r.systems/image/upload/f_auto/9 general image-cqmojiro.jpg',
  'https://r.100r.systems/image/upload/f_auto/10 general image-cujxythy.jpg',
  'https://r.100r.systems/image/upload/f_auto/11 general image-nscnyvnd.jpg',
  'https://r.100r.systems/image/upload/f_auto/12 general image-fdlgfjhp.jpg',
  'https://r.100r.systems/image/upload/f_auto/13 general image-angbwczr.jpg',
  'https://r.100r.systems/image/upload/f_auto/14 general image-xfwsjtdc.jpg',
  'https://r.100r.systems/image/upload/f_auto/15 general image-kbeqsnra.jpg',
  'https://r.100r.systems/image/upload/f_auto/16 general image-amsplprm.jpg',
  'https://r.100r.systems/image/upload/f_auto/17 general image-aamolpqw.jpg',
  'https://r.100r.systems/image/upload/f_auto/18 general image-wgmhthhf.jpg',
  'https://r.100r.systems/image/upload/f_auto/19 general image-lzjyvpgt.jpg',
  'https://r.100r.systems/image/upload/f_auto/20 general image-nfukglfn.jpg',
  'https://r.100r.systems/image/upload/f_auto/21 general image-cociwbox.jpg',
  'https://r.100r.systems/image/upload/f_auto/22 general image-yepdulbe.jpg',
  'https://r.100r.systems/image/upload/f_auto/23 general image-qqrtlzlx.jpg',
  'https://r.100r.systems/image/upload/f_auto/24 general image-toknttis.jpg',
  'https://r.100r.systems/image/upload/f_auto/25 general image-uevyqfry.jpg',
  'https://r.100r.systems/image/upload/f_auto/26 general image-nkftlcox.jpg',
  'https://r.100r.systems/image/upload/f_auto/27 general image-xwgkmsyv.jpg',
  'https://r.100r.systems/image/upload/f_auto/28 general image-upomfcby.jpg',
  'https://r.100r.systems/image/upload/f_auto/29 general image-rejikdmc.jpg',
  'https://r.100r.systems/image/upload/f_auto/30 general image-qfuclkmn.jpg',
  'https://r.100r.systems/image/upload/f_auto/31 general image-xufuqoyr.jpg',
  'https://r.100r.systems/image/upload/f_auto/32 general image-oqoyjxmk.jpg',
  'https://r.100r.systems/image/upload/f_auto/33 general image-eztfeqeo.jpg',
  'https://r.100r.systems/image/upload/f_auto/34 general image-efpusdmn.jpg',
  'https://r.100r.systems/image/upload/f_auto/35 general image-bvgbsssu.jpg',
  'https://r.100r.systems/image/upload/f_auto/36 general image-koutzybz.jpg',
  'https://r.100r.systems/image/upload/f_auto/37 general image-enmfpusi.jpg',
  'https://r.100r.systems/image/upload/f_auto/38 general image-ygomtzkz.jpg',
  'https://r.100r.systems/image/upload/f_auto/39 general image-dwuvvjkn.jpg',
  'https://r.100r.systems/image/upload/f_auto/40 general image-pccwmufl.jpg',
  'https://r.100r.systems/image/upload/f_auto/41 general image-zeubcvnn.jpg',
  'https://r.100r.systems/image/upload/f_auto/42 general image-tmfhtxam.jpg',
  'https://r.100r.systems/image/upload/f_auto/43 general image-xbfavdxt.jpg',
  'https://r.100r.systems/image/upload/f_auto/44 general image-lqssasto.jpg',
  'https://r.100r.systems/image/upload/f_auto/45 general image-ocrxaaql.jpg',
  'https://r.100r.systems/image/upload/f_auto/46 general image-gvnxycfh.jpg',
  'https://r.100r.systems/image/upload/f_auto/47 general image-szwszatp.jpg',
  'https://r.100r.systems/image/upload/f_auto/48 general image-hmlmvcsq.jpg',
  'https://r.100r.systems/image/upload/f_auto/49 general image-bcizgvuf.jpg',
  'https://r.100r.systems/image/upload/f_auto/50 general image-xxyywbfk.jpg',
  'https://r.100r.systems/image/upload/f_auto/51 general image-mxjevpcl.jpg',
  'https://r.100r.systems/image/upload/f_auto/52 general image-rdkafntx.jpg',
  'https://r.100r.systems/image/upload/f_auto/53 general image-tkbwxtuw.jpg',
  'https://r.100r.systems/image/upload/f_auto/54 general image-gmntkzez.jpg',
  'https://r.100r.systems/image/upload/f_auto/55 general image-wcsaxbyx.jpg',
  'https://r.100r.systems/image/upload/f_auto/56 general image-pjphewlm.jpg',
  'https://r.100r.systems/image/upload/f_auto/57 general image-sbkmfgnh.jpg',
  'https://r.100r.systems/image/upload/f_auto/58 general image-cbgmahei.jpg',
  'https://r.100r.systems/image/upload/f_auto/59 general image-iewnkfik.jpg',
  'https://r.100r.systems/image/upload/f_auto/60 general image-dhfsdtix.jpg',
  'https://r.100r.systems/image/upload/f_auto/61 general image-cnyjsrkw.jpg',
  'https://r.100r.systems/image/upload/f_auto/62 general image-rxzsmqka.jpg',
  'https://r.100r.systems/image/upload/f_auto/63 general image-kbeluwmb.jpg',
  'https://r.100r.systems/image/upload/f_auto/64 general image-njfgtsig.jpg',
  'https://r.100r.systems/image/upload/f_auto/65 general image-zpyyoscq.jpg',
  'https://r.100r.systems/image/upload/f_auto/66 general image-cdzrrsby.jpg',
  'https://r.100r.systems/image/upload/f_auto/67 general image-zuurjynr.jpg',
  'https://r.100r.systems/image/upload/f_auto/68 general image-ngsdazfl.jpg',
  'https://r.100r.systems/image/upload/f_auto/69 general image-mvodwnzh.jpg',
  'https://r.100r.systems/image/upload/f_auto/70 general image-pzuymifa.jpg',
  'https://r.100r.systems/image/upload/f_auto/71 general image-sxgslzfh.jpg',
  'https://r.100r.systems/image/upload/f_auto/72 general image-cnavizsw.jpg',
  'https://r.100r.systems/image/upload/f_auto/73 general image-vnxeigwy.jpg',
  'https://r.100r.systems/image/upload/f_auto/74 general image-vsbcjyzz.jpg',
  'https://r.100r.systems/image/upload/f_auto/75 general image-mkzpomwp.jpg',
  'https://r.100r.systems/image/upload/f_auto/76 general image-zeidgmvi.jpg',
  'https://r.100r.systems/image/upload/f_auto/77 general image-tsgqhplq.jpg',
  'https://r.100r.systems/image/upload/f_auto/78 general image-tgwnckxh.jpg',
  'https://r.100r.systems/image/upload/f_auto/79 general image-pwbpuqey.jpg',
  'https://r.100r.systems/image/upload/f_auto/80 general image-ymliqamf.jpg',
  'https://r.100r.systems/image/upload/f_auto/81 general image-vyznjtqx.jpg',
  'https://r.100r.systems/image/upload/f_auto/82 general image-xwghicuo.jpg',
  'https://r.100r.systems/image/upload/f_auto/83 general image-gfxellzs.jpg',
  'https://r.100r.systems/image/upload/f_auto/84 general image-fiuvfogy.jpg',
  'https://r.100r.systems/image/upload/f_auto/85 general image-buaofusy.jpg',
  'https://r.100r.systems/image/upload/f_auto/86 general image-ijrrsshz.jpg',
  'https://r.100r.systems/image/upload/f_auto/87 general image-vvakwjin.jpg',
  'https://r.100r.systems/image/upload/f_auto/88 general image-nbdaguuz.jpg',
  'https://r.100r.systems/image/upload/f_auto/89 general image-imdbzukw.jpg',
  'https://r.100r.systems/image/upload/f_auto/90 general image-ayubnyed.jpg',
  'https://r.100r.systems/image/upload/f_auto/91 general image-zfrutpxw.jpg',
  'https://r.100r.systems/image/upload/f_auto/92 general image-uoclrrkg.jpg',
  'https://r.100r.systems/image/upload/f_auto/93 general image-tvmfgpge.jpg',
  'https://r.100r.systems/image/upload/f_auto/94 general image-taznpxfx.jpg',
  'https://r.100r.systems/image/upload/f_auto/95 general image-gzduezib.jpg',
  'https://r.100r.systems/image/upload/f_auto/96 general image-oqeelptp.jpg',
  'https://r.100r.systems/image/upload/f_auto/97 general image-dddsfkjp.jpg',
  'https://r.100r.systems/image/upload/f_auto/98 general image-tegqwusm.jpg',
  'https://r.100r.systems/image/upload/f_auto/99 general image-dhrktwyl.jpg',
];

export const PAGES_BLOG = [
  'BLOG_CATEGORY',
  'BLOG_HOME',
  'BLOG_POST',
];

export const PAGES_SEO = [
  'SEO_LANDING_ESTABLISHMENT',
  'SEO_LANDING_PAGE_1',
  'SEO_LANDING_PAGE_1_SUBHOME_1',
  'SEO_LANDING_PAGE_1_SUBHOME_2',
  'SEO_LANDING_PAGE_2',
  'SEO_LANDING_PAGE_3',
  'SEO_PARTNER_PAGE_1',
  'SEO_PARTNER_PAGE_2',
  'SEO_PARTNER_PAGE_3',
];

export const NON_OCCIDENTAL_COUNTRIES = ['jp'];

export const ADJECTIVES = {
  EXCEPTIONAL: 'Exceptional',
  FANTASTIC: 'Fantastic',
  GOOD: 'Good',
  VERY_GOOD: 'Very good',
};

export const PARTNERS_WITH_MULTI_LOGO = { 12: ['de', 'fr'] };

export const DISABLED_ADSENSE = { jp: { mobile: true } };

export const HALF_HOUR_IN_MILIS = 1000 * 60 * 30;
