export const ID_ROUTES = {
  BLOG: 'BLOG',
  ERROR: 'ERROR',
  ESTABLISHMENT: 'ESTABLISHMENT',
  HOME: 'HOME',
  SEO: 'SEO',
  STATIC: 'STATIC',
};

export const DATA_ROUTES = {
  [ID_ROUTES.HOME]: { bodyClass: 'home' },
  [ID_ROUTES.ESTABLISHMENT]: { bodyClass: 'establishment' },
  [ID_ROUTES.SEO]: { bodyClass: 'search seo' },
  [ID_ROUTES.STATIC]: { bodyClass: 'static' },
  [ID_ROUTES.ERROR]: { bodyClass: 'error-page' },
};

export const ROUTES_POPUNDER = [ID_ROUTES.HOME];
export const ROUTES_CHECKBOXES = [ID_ROUTES.HOME];

export const PAGES = {
  BLOG_CATEGORY: 'BLOG_CATEGORY',
  BLOG_HOME: 'BLOG_HOME',
  BLOG_POST: 'BLOG_POST',
  CORPORATE: 'CORPORATE',
  ESTABLISHMENT: 'ESTABLISHMENT',
  HOME: 'HOME',
  SEO_LANDING_ESTABLISHMENT: 'SEO_LANDING_ESTABLISHMENT',
  SEO_LANDING_PAGE_1: 'SEO_LANDING_PAGE_1',
  SEO_LANDING_PAGE_1_SUBHOME_1: 'SEO_LANDING_PAGE_1_SUBHOME_1',
  SEO_LANDING_PAGE_1_SUBHOME_2: 'SEO_LANDING_PAGE_1_SUBHOME_2',
  SEO_LANDING_PAGE_2: 'SEO_LANDING_PAGE_2',
  SEO_LANDING_PAGE_3: 'SEO_LANDING_PAGE_3',
  SEO_PARTNER_PAGE_1: 'SEO_PARTNER_PAGE_1',
  SEO_PARTNER_PAGE_2: 'SEO_PARTNER_PAGE_2',
  SEO_PARTNER_PAGE_3: 'SEO_PARTNER_PAGE_3',
};

export const ID_PAGES = {
  [PAGES.HOME]: 2,
  [PAGES.ESTABLISHMENT]: 4,
  [PAGES.SEO_LANDING_ESTABLISHMENT]: 5,
  [PAGES.CORPORATE]: 7,
  [PAGES.BLOG_HOME]: 9,
  [PAGES.BLOG_CATEGORY]: 9,
  [PAGES.BLOG_POST]: 9,
  [PAGES.SEO_LANDING_PAGE_1_SUBHOME_1]: 10,
  [PAGES.SEO_LANDING_PAGE_1_SUBHOME_2]: 10,
  [PAGES.SEO_LANDING_PAGE_1]: 10,
  [PAGES.SEO_LANDING_PAGE_2]: 11,
  [PAGES.SEO_LANDING_PAGE_3]: 12,
  [PAGES.SEO_PARTNER_PAGE_1]: 13,
  [PAGES.SEO_PARTNER_PAGE_2]: 14,
  [PAGES.SEO_PARTNER_PAGE_3]: 15,
};

export const SEO_LANDING_PAGES = [
  ID_PAGES[PAGES.SEO_LANDING_PAGE_1_SUBHOME_1],
  ID_PAGES[PAGES.SEO_LANDING_PAGE_1_SUBHOME_2],
  ID_PAGES[PAGES.SEO_LANDING_PAGE_1],
  ID_PAGES[PAGES.SEO_LANDING_PAGE_2],
  ID_PAGES[PAGES.SEO_LANDING_PAGE_3],
  ID_PAGES[PAGES.SEO_PARTNER_PAGE_1],
  ID_PAGES[PAGES.SEO_PARTNER_PAGE_2],
  ID_PAGES[PAGES.SEO_PARTNER_PAGE_3],
];
