import translatable from './translatable';

function parseData(literal, data, key) {
  return Object.keys(data).reduce((acu, objKey) => {
    const patt = new RegExp(`{${objKey}}`, 'g');
    if (key === 'price') {
      return acu.replace(patt, parseInt(data[objKey], 10));
    }
    return acu.replace(patt, data[objKey]);
  }, literal);
}

/*
 *
 * @param literal {Object}
 * @param key {String}
 * @param data {Object}
 */
function translate(literal) {
  return function (key, data) {
    if (!literal[key]) {
      return !data ? key : parseData(key, data, key);
    }
    return !data ? literal[key] : parseData(literal[key], data, key);
  };
}

/**
 *
 * @param key {String}
 * @param data {Object}
 */
const t = (typeof window !== 'undefined')
  ? translate((global.hr && global.hr.intl && global.hr.intl.TEXTS) || {})
  : text => text;

export {
  t,
  translate,
  translatable,
};
