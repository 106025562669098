import { FILTERS_ACTIONS } from 'constants/actions';
import { filtersInitialState } from 'reducers/filtersReducer';


export function filtersApplied(state = filtersInitialState(), action) {
  switch (action.type) {
    case FILTERS_ACTIONS.UPDATE_FILTERS:
      return Object.assign({}, state, action.filters);

    default:
      return state;
  }
}
