import PropTypes from 'prop-types';
import React from 'react';


const PublishLink = ({ className, image, clickout, handleOnClick }) => (
  <a className={ `publish-link ${className}` } href={ clickout } onClick={ handleOnClick } rel="nofollow" target="_blank">
    <div className="publish-link__inner">
      <img alt="" className="publish-link__inner__image" src={ image } />
      <span className="publish-link__inner__caret hundredicons-arrow-right-filled" />
    </div>
  </a>
);

PublishLink.defaultProps = {
  className: '',
};

PublishLink.propTypes = {
  className: PropTypes.string,
  clickout: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default PublishLink;
