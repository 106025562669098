import { browserHistory, Router } from 'react-router';
import PropTypes from 'prop-types';
import React from 'react';
import routesBase from './base';

export default function Routes(props) {
  const { routes } = props;
  return <Router history={ browserHistory } routes={ routesBase(routes) } />;
}

Routes.propTypes = {
  routes: PropTypes.shape().isRequired,
};
