import { ESTABLISHMENT_DETAILS_ACTIONS } from 'constants/actions';

const initialState = {};

export default function establishmentDetails(state = initialState, action) {
  const estab = state[action.establishmentId] || {};
  switch (action.type) {
    case ESTABLISHMENT_DETAILS_ACTIONS.UPDATE_SIMILARS:
      return Object.assign({}, state, {
        [action.establishmentId]: Object.assign({}, estab, {
          similars: action.similarsIds,
        }),
      });

    case ESTABLISHMENT_DETAILS_ACTIONS.UPDATE_PARTNERS_RATING:
      return Object.assign({}, state, {
        [action.establishmentId]: Object.assign({}, estab, {
          partnersRating: action.partnersRating,
        }),
      });

    default:
      return state;
  }
}

export const EMPTY_ARRAY = [];
export const UNDEFINED = undefined;

export function getSimilarEstablishments(state, id) {
  if (!state[id] || !state[id].similars) return UNDEFINED;

  return state[id].similars;
}
