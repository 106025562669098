import { defaultWeekend, formatFromString, getNumberOfNights } from 'helpers/dates-helper';
import makeSlug, { hasKanji } from 'services/slug';
import { COOKIES } from 'constants/constants';
import { getCookieValue } from 'helpers/cookies-helper';
import { getState } from 'services/store';

export default class Ad {
  constructor(ad, search) {
    this.id = ad.id;
    this.active = ad.active;
    this.name = ad.name;
    this.visibleName = ad.visibleName;
    this.order = ad.order;
    this.cpc = ad.cpc;
    this.checked = ad.checked;
    this.destination = search.name;
    this.transactionId = ad.transactionId;
    this.search = search;
    this.clickout = this.getClickout(ad, search);
    this.image = ad.image;
    this.color = ad.color;
    this.logo = ad.logo;
  }

  static forSearch(ad, search) {
    return new Ad(ad, search);
  }

  getClickout(ad) {
    const state = getState();
    const currentSearch = Object.assign({}, state.searchFormReducer, state.destination);
    const destinationName = hasKanji(currentSearch.name) ? currentSearch.romaji_name : currentSearch.name;
    const { dateFormat } = ad.countryParams[ad.domain];
    const [defaultStartDate, defaultEndDate] = defaultWeekend(dateFormat);
    const destinationParts = destinationName && destinationName.split(', ');
    this.destination = destinationName;
    const macros = {
      '<AB_TEST>': ad.testAB,
      '<CITY>': destinationParts && destinationParts[0],
      '<CLIENT_ID>': global.hr.gacid || '',
      '<COUNTRY>': destinationParts && destinationParts[destinationParts.length - 1],
      '<COUNTRY_CODE>': currentSearch.country ? currentSearch.country.toLowerCase() : '',
      '<DEFAULT_END_DATE>': defaultEndDate,
      '<DEFAULT_START_DATE>': defaultStartDate,
      '<DESTINATION>': destinationName,
      '<DEVICE>': ad.device,
      '<ENCODED_DESTINATION>': makeSlug(destinationName),
      '<ENCODED_DESTINATION_NO_DASH>': makeSlug(destinationName, true),
      '<END_DATE>': formatFromString(currentSearch.dateLeaving, dateFormat),
      '<GCLID>': getCookieValue(COOKIES.GOOGLE_CLICK_ID) || '',
      '<GUESTS>': currentSearch.guestsNumber,
      '<LATITUDE>': currentSearch.lat,
      '<LONGITUDE>': currentSearch.lng,
      '<MSCLKID>': getCookieValue(COOKIES.MICROSOFT_CLICK_ID) || '',
      '<NIGHTS>': getNumberOfNights(currentSearch.dateArrival, currentSearch.dateLeaving),
      '<RENTALIA_GEO_ID>': currentSearch.rentalia_id || '',
      '<START_DATE>': formatFromString(currentSearch.dateArrival, dateFormat),
      '<START_MONTH_DAY>': formatFromString(currentSearch.dateArrival, 'DD'),
      '<UUID4>': this.transactionId,
    };

    let clickout = this.getRawClickout(ad, state);
    Object.keys(macros).forEach((macro) => { clickout = clickout.toString().split(macro).join(macros[macro]); });

    if (ad.countryParams[ad.domain].params) {
      Object.keys(ad.countryParams[ad.domain].params).forEach((param) => {
        clickout = clickout.split(`<PARTNER_PARAM_${param}>`).join(ad.countryParams[ad.domain].params[param]);
      });
    }

    return clickout;
  }

  getRawClickout(ad) {
    if (ad.type === 'suggestionbox' && !this.destination) return ad.fallback;
    return ad.clickout;
  }

  toObject() {
    return {
      checked: this.checked,
      clickout: this.clickout,
      color: this.color,
      cpc: this.cpc,
      destination: this.destination,
      id: this.id,
      image: this.image,
      logo: this.logo,
      name: this.name,
      order: this.order,
      transactionId: this.transactionId,
      visibleName: this.visibleName,
    };
  }

}
