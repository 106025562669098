import { APP_ACTIONS } from 'constants/actions';
import { STANDALONE_MODE } from 'constants/constants';

const appInitialState = {
  isPushNotificationsSubscribed: false,
  isShownCookieBanner: false,
  mode: '',
  notificationsSupported: false,
  pushNotificationPermission: false, // 'default', 'granted', or 'denied'
};

export default function appReducer(state = appInitialState, action) {

  switch (action.type) {

    case APP_ACTIONS.SET_STANDALONE_MODE:
      return {
        ...state,
        mode: STANDALONE_MODE,
      };

    case APP_ACTIONS.NOTIFICATIONS_SUPPORTED:
      return {
        ...state,
        notificationsSupported: action.isSupported,
      };

    case APP_ACTIONS.SET_PUSH_NOTIFICATIONS_PERMISSION:
      return {
        ...state,
        pushNotificationPermission: action.permission,
      };

    case APP_ACTIONS.SUBSCRIPTION_CHANGE:
      return {
        ...state,
        isPushNotificationsSubscribed: action.isSubscribed,
      };

    case APP_ACTIONS.TOGGLE_COOKIE_BANNER:
      return {
        ...state,
        isShownCookieBanner: !state.isShownCookieBanner,
      };

    default:
      return state;
  }
}
