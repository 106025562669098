import { ADS, COOKIES } from 'constants/constants';
import { getCookieValue, isCookieSet } from 'helpers/cookies-helper';
import Ad from 'services/AdService/Ad';
import { checkboxAds } from 'services/AdService/database';
import uuid4 from 'helpers/crypto';


export default class Checkbox {
  constructor(search) {
    this.countryCode = global.hr.intl.COUNTRY_CODE;
    this.openedAds = isCookieSet(COOKIES.OPENED_CHECKBOX) ? getCookieValue(COOKIES.OPENED_CHECKBOX).split(',') : [];
    this.search = search;
  }

  getCheckboxToShow() {
    const { airbnb } = checkboxAds;

    return [airbnb]
      .filter(ad => !this.openedAds.includes(ad.id))
      .filter(ad => !!ad.countryParams[this.countryCode])
      .slice(0, ADS.CHECKBOXES_TO_SHOW)
      .map(ad => (ad.dynamicUrl ? this.parseAd(ad) : ad));
  }

  getDevice() {
    if (global.isPhone) return 'mobile';
    if (global.isTablet) return 'tablet';
    return 'desktop';
  }

  getDeviceShort() {
    if (global.isPhone) return 'mo';
    if (global.isTablet) return 'ta';
    return 'dk';
  }

  parseAd(ad) {
    ad.order = this.openedAds.length + 1;
    ad.checked = isCookieSet(COOKIES.CHECKED_CHECKBOX) ? getCookieValue(COOKIES.CHECKED_CHECKBOX) === 'true' : true;
    ad.cpc = ad.countryParams[this.countryCode].cpc[this.getDeviceShort()];
    ad.domain = this.countryCode;
    ad.device = this.getDeviceShort();
    ad.transactionId = uuid4();
    ad.type = 'checkbox';

    return Ad.forSearch(ad, this.search).toObject();
  }

}
