import { ESTABLISHMENT_TYPE, SERVICES_IDS, TYPE_ESTABLISHMENT } from 'constants/constants';
import { formatFromString } from 'helpers/dates-helper';
import Hr from 'service-client-js';
import { isEmptyObject } from 'helpers/local-component-state';
import { PAGES } from 'constants/routes';
import queryString from 'querystring';


export const NONE = '(none)';
const SEARCH = '/search';
const SEM = '/landing';
const ESTABLISHMENT = '/establishment';
const CLICKOUT = '/clickout';
const VIRTUAL_PAGE = ' (virtual page)';
const haveQueryEqualTo = (url, query, value) => url.qs && Object.keys(url.qs).includes(query) && url.qs[query] === value;
const haveQueryDistinctTo = (url, query, value) => url.qs && Object.keys(url.qs).includes(query) && url.qs[query] !== value;

export function getPageType(url, params) {
  const isEstablishmentSeoLanding = !isNaN(url.pathname.slice(-4, -1));
  const routes = global.hr.intl.ROUTES;
  const path = url.pathname || '';

  if (typeof path !== 'string' || typeof path === 'object') return null;

  if (path.startsWith(SEM)) return PAGES.SEM;
  if (path.startsWith(`/${routes.blog}`)) {
    if (path === `/${routes.blog}/`) return PAGES.BLOG_HOME;
    if (global.pageType === 'blog-category') return PAGES.BLOG_CATEGORY;
    return PAGES.BLOG_POST;
  }
  if (path === '/') return PAGES.HOME;
  if (path.startsWith(`/${routes.search}`)) return params && params.view === 'map' ? PAGES.SERP_MAP : PAGES.SERP_LIST;
  if (path.startsWith(`/${routes.tipologyFirst}`)) {
    if (path === `/${routes.subhomeFirst}/` || path === `/${routes.subhomeFirst}`) return PAGES.SEO_LANDING_PAGE_1_SUBHOME_1;
    if (path === `/${routes.subhomeSecond}/` || path === `/${routes.subhomeSecond}`) return PAGES.SEO_LANDING_PAGE_1_SUBHOME_2;
    return isEstablishmentSeoLanding ? PAGES.SEO_LANDING_ESTABLISHMENT : PAGES.SEO_LANDING_PAGE_1;
  }
  if (path.startsWith(`/${routes.tipologySecond}`)) return isEstablishmentSeoLanding ? PAGES.SEO_LANDING_ESTABLISHMENT : PAGES.SEO_LANDING_PAGE_2;
  if (path.startsWith(`/${routes.tipologyThird}`)) return isEstablishmentSeoLanding ? PAGES.SEO_LANDING_ESTABLISHMENT : PAGES.SEO_LANDING_PAGE_3;
  if (path.startsWith(`/${routes.subhomeFirst}`)) return isEstablishmentSeoLanding ? PAGES.SEO_LANDING_ESTABLISHMENT : PAGES.SEO_LANDING_PAGE_1_SUBHOME_1;
  if (path.startsWith(`/${routes.subhomeSecond}`)) return isEstablishmentSeoLanding ? PAGES.SEO_LANDING_ESTABLISHMENT : PAGES.SEO_LANDING_PAGE_1_SUBHOME_2;
  if (path.startsWith(`/${routes.partnerFirst}`)) return PAGES.SEO_PARTNER_PAGE_1;
  if (path.startsWith(`/${routes.partnerSecond}`)) return PAGES.SEO_PARTNER_PAGE_2;
  if (path.startsWith(`/${routes.partnerThird}`)) return PAGES.SEO_PARTNER_PAGE_3;
  if (path.startsWith(ESTABLISHMENT)) {
    if (params && params.url && params.url.includes('leads-form')) return PAGES.MULTILEAD;
    return PAGES.ESTABLISHMENT;
  }
  if (path.startsWith(CLICKOUT)) return PAGES.CLICKOUT;
  if (path.startsWith(`/${routes.users}`)) return path.includes(routes.account) ? PAGES.PERSONAL_AREA : PAGES.PERSONAL_AREA_LIST;
  if (path.startsWith(`/${routes.privacy}`)) return PAGES.CORPORATE;
  if (path.startsWith(`/${routes.contact}`)) return PAGES.CONTACT;

  return null;
}


export function getUrl(url, params) {
  url.qs = queryString.parse(url.query);
  let isVirtualPage = false;
  let virtualPath = '';
  // On SERP page
  if (url.pathname === `/${global.hr.intl.ROUTES.search}`) url.pathname = SEARCH;
  // On paginate
  if (haveQueryDistinctTo(url, 'page', '1')) {
    const pageNum = url.qs.page;
    delete url.qs.page;
    virtualPath += url.pathname.endsWith('/') ? `page/${pageNum}` : `/page/${pageNum}`;
    isVirtualPage = true;
  }
  // On open login modal
  if (haveQueryEqualTo(url, 'modal', 'login')) {
    delete url.qs.modal;
    virtualPath += url.pathname.endsWith('/') ? 'login' : '/login';
    isVirtualPage = true;
  }
  if (params && params.view) url.qs.view = params.view;
  if (url.qs && !isEmptyObject(url.qs)) virtualPath += '?';
  const virtualPage = isVirtualPage ? VIRTUAL_PAGE : '';

  return `${url.pathname}${virtualPath}${queryString.stringify(url.qs)}${virtualPage}`;
}


export function getPageSectionAndType(page) {
  switch (page) {
    case PAGES.SEM:
      return ['sem', 'landings-sem'];
    case PAGES.BLOG_HOME:
      return ['inspiration', 'blog-home'];
    case PAGES.BLOG_CATEGORY:
      return ['inspiration', global.pageType];
    case PAGES.BLOG_POST:
      return ['inspiration', global.pageType];
    case PAGES.HOME:
      return [NONE, 'home'];
    case PAGES.SERP_LIST:
      return ['serp', 'serp-list'];
    case PAGES.SERP_MAP:
      return ['serp', 'serp-map'];
    case PAGES.SEO_LANDING_PAGE_1:
    case PAGES.SEO_LANDING_PAGE_2:
    case PAGES.SEO_LANDING_PAGE_3:
    case PAGES.SEO_LANDING_PAGE_1_SUBHOME_1:
    case PAGES.SEO_LANDING_PAGE_1_SUBHOME_2:
    case PAGES.SEO_PARTNER_PAGE_1:
    case PAGES.SEO_PARTNER_PAGE_2:
    case PAGES.SEO_PARTNER_PAGE_3:
      return ['landings', 'landings'];
    case PAGES.SEO_LANDING_ESTABLISHMENT:
      return ['landings', 'establishment'];
    case PAGES.ESTABLISHMENT:
      return ['serp', 'establishment'];
    case PAGES.MULTILEAD:
      return ['serp', 'multi-lead'];
    case PAGES.CLICKOUT:
      return ['serp', 'clickout'];
    case PAGES.PERSONAL_AREA:
      return ['personal-area', 'personal-area'];
    case PAGES.PERSONAL_AREA_LIST:
      return ['personal-area', 'personal-area-list'];
    case PAGES.CORPORATE:
      return ['corporate', 'corporate-pages'];
    case PAGES.CONTACT:
      return ['corporate', 'contact-form'];
    default:
      return [NONE, NONE];
  }
}


export function getPageConcept(page, url, state) {
  if (!state) return NONE;
  let qs = null;
  let establishmentType = null;
  switch (page) {
    case PAGES.SEM:
    case PAGES.SERP_MAP:
    case PAGES.SERP_LIST:
      return { 'destination-box': state.destination.name };
    case PAGES.SEO_LANDING_PAGE_1_SUBHOME_1:
    case PAGES.SEO_LANDING_PAGE_1_SUBHOME_2:
    case PAGES.SEO_LANDING_PAGE_1:
    case PAGES.SEO_LANDING_PAGE_2:
    case PAGES.SEO_LANDING_PAGE_3:
      return {
        'destination-box': state.destination.name,
        'establishment-type': global.SERVERDATA.props ? global.SERVERDATA.props.meta.pageType : NONE,
      };
    case PAGES.SEO_PARTNER_PAGE_1:
    case PAGES.SEO_PARTNER_PAGE_2:
    case PAGES.SEO_PARTNER_PAGE_3:
      return {
        'destination-box': state.destination.name,
        partner: global.SERVERDATA.props ? global.SERVERDATA.props.meta.pageType : NONE,
      };
    case PAGES.SEO_LANDING_ESTABLISHMENT:
      if (!global.SERVERDATA.props) return NONE;
      establishmentType = Object.keys(ESTABLISHMENT_TYPE).find(key => ESTABLISHMENT_TYPE[key] === global.SERVERDATA.props.establishment.category);
      return {
        'destination-box': state.destination.name,
        'establishment-type': (establishmentType || '').toLowerCase(),
      };
    case PAGES.ESTABLISHMENT:
    case PAGES.MULTILEAD:
      if (!global.SERVERDATA.props) return NONE;
      establishmentType = Object.keys(ESTABLISHMENT_TYPE).find(key => ESTABLISHMENT_TYPE[key] === state.establishments[Object.keys(state.establishments)[0]].category);
      return {
        'destination-box': state.destination.name,
        'establishment-type': (establishmentType || '').toLowerCase(),
      };
    case PAGES.CLICKOUT:
      qs = queryString.parse(url.query);
      return {
        partner: qs.partnerName,
      };
    default:
      return NONE;
  }
}


export function getPageCountry(page, state) {
  switch (page) {
    case PAGES.SEO_LANDING_PAGE_1:
    case PAGES.SEO_LANDING_PAGE_2:
    case PAGES.SEO_LANDING_PAGE_3:
    case PAGES.SEO_LANDING_PAGE_1_SUBHOME_1:
    case PAGES.SEO_LANDING_PAGE_1_SUBHOME_2:
    case PAGES.SEO_PARTNER_PAGE_1:
    case PAGES.SEO_PARTNER_PAGE_2:
    case PAGES.SEO_PARTNER_PAGE_3:
    case PAGES.SEM:
    case PAGES.SERP_LIST:
    case PAGES.SERP_MAP:
    case PAGES.ESTABLISHMENT:
    case PAGES.SEO_LANDING_ESTABLISHMENT:
    case PAGES.MULTILEAD:
    case PAGES.CLICKOUT:
      return state && state.destination && state.destination.country ? state.destination.country.toLowerCase() : NONE;
    default:
      return NONE;
  }
}


export function getEstablishmentInfo(page, url, state) {
  let qs = null;
  let estab = null;
  let sentimental = 'positive';
  let establishmentType = null;
  switch (page) {
    case PAGES.ESTABLISHMENT:
    case PAGES.MULTILEAD:
      if (!state) return NONE;
      estab = state.establishments[Object.keys(state.establishments)[0]];
      if (!estab) return NONE;
      sentimental = 'positive';
      if (estab.ranking < Hr.RANKING_LOW_LIMIT.POSITIVE) sentimental = 'neutral';
      if (estab.ranking < Hr.RANKING_LOW_LIMIT.NEUTRAL) sentimental = 'negative';
      if (estab.ranking === 0) sentimental = NONE;
      establishmentType = Object.keys(ESTABLISHMENT_TYPE).find(key => ESTABLISHMENT_TYPE[key] === estab.category);
      return {
        establishment: {
          'common-data': {
            'establishment-id': estab.id,
            'establishment-type': (establishmentType || '').toLowerCase(),
            meters: estab.area,
            'number-guests': estab.maxGuests,
            rating: estab.partnerRating,
            rooms: estab.rooms,
            sentimental,
            services: (estab.features || []).map(service => SERVICES_IDS[service]),
          },
          'partners-match': estab.partners.map(partner => ({ id: partner.partner_id, 'instant-booking': partner.instantly_booking })),
          'partners-price': {
            instantBooking: estab.partners.some(partner => partner.instantly_booking),
            list: estab.partners.map(partner => partner.partner_id),
            price: NONE,
          },
        },
      };
    case PAGES.SEO_LANDING_ESTABLISHMENT:
      estab = global.SERVERDATA.props ? global.SERVERDATA.props.establishment : null;
      if (!estab) return NONE;
      sentimental = 'positive';
      if (estab.ranking < Hr.RANKING_LOW_LIMIT.POSITIVE) sentimental = 'neutral';
      if (estab.ranking < Hr.RANKING_LOW_LIMIT.NEUTRAL) sentimental = 'negative';
      establishmentType = Object.keys(ESTABLISHMENT_TYPE).find(key => ESTABLISHMENT_TYPE[key] === estab.category);
      return {
        establishment: {
          'common-data': {
            'establishment-id': estab.id,
            'establishment-type': (establishmentType || '').toLowerCase(),
            meters: estab.area,
            'number-guests': estab.maxGuests,
            rating: estab.partnerRating,
            rooms: estab.rooms,
            sentimental,
            services: (estab.features || []).map(service => SERVICES_IDS[service]),
          },
          'partners-match': estab.partners.map(partner => ({ id: partner.partner_id, 'instant-booking': partner.instantly_booking })),
          'partners-price': {
            instantBooking: estab.partners.some(partner => partner.instantly_booking),
            list: estab.partners.map(partner => partner.partner_id),
            price: NONE,
          },
        },
      };
    case PAGES.CLICKOUT:
      qs = queryString.parse(url.query);
      return {
        establishment: {
          'common-data': {
            'establishment-id': qs.id,
            'number-guests': qs.guestsNumber,
          },
          'partners-price': {
            price: qs.price,
          },
        },
      };
    default:
      return NONE;
  }
}


export function getDatesAndGuests(page, url, state) {
  let qs = null;
  switch (page) {
    case PAGES.SERP_LIST:
    case PAGES.SERP_MAP:
    case PAGES.ESTABLISHMENT:
    case PAGES.MULTILEAD:
      if (!state || !state.searchFormReducer) return [NONE, NONE, NONE];
      return [
        state.searchFormReducer.dateArrival ? formatFromString(state.searchFormReducer.dateArrival, 'DD-MM-YYYY') : NONE,
        state.searchFormReducer.dateLeaving ? formatFromString(state.searchFormReducer.dateLeaving, 'DD-MM-YYYY') : NONE,
        state.searchFormReducer.guestsNumber,
      ];
    case PAGES.CLICKOUT:
      qs = queryString.parse(url.query);
      return [
        qs.dateArrival ? formatFromString(qs.dateArrival, 'DD-MM-YYYY', 'DD/MM/YYYY') : NONE,
        qs.dateLeaving ? formatFromString(qs.dateLeaving, 'DD-MM-YYYY', 'DD/MM/YYYY') : NONE,
        qs.guestsNumber,
      ];
    default:
      return [NONE, NONE, NONE];
  }
}


export function getSearchFilters(page, state) {
  if (!state) return NONE;
  const { zones } = state.searchResultsReducer;
  let filters = null;
  let options = null;
  let pricePerDay = null;
  let pricePerNight = null;
  let priceFiltersApplied = null;
  switch (page) {
    case PAGES.SERP_LIST:
    case PAGES.SERP_MAP:
      filters = state.filtersApplied;
      options = [];
      pricePerDay = filters.priceRange.type === 1;
      pricePerNight = !pricePerDay;
      priceFiltersApplied = !!Object.getOwnPropertyNames(filters.priceRange.applied).length;
      if (filters.cancellationSelected) options.push('cancellation');
      if (filters.discountSelected) options.push('bargain');
      if (filters.reviewsSelected) options.push('positive-rating');
      if (filters.recommendationSelected) options.push('recommendation');
      if (filters.availabilityFilterApplied.length) options.push('instant-booking');
      return {
        options: options.length ? options : NONE,
        'price-night-end': (pricePerDay && priceFiltersApplied) ? filters.priceRange.applied.a[1] : NONE,
        'price-night-start': (pricePerDay && priceFiltersApplied) ? filters.priceRange.applied.a[0] : NONE,
        'price-total-end': (pricePerNight && priceFiltersApplied) ? filters.priceRange.applied.a[1] : NONE,
        'price-total-start': (pricePerNight && priceFiltersApplied) ? filters.priceRange.applied.a[0] : NONE,
        query: state.destination.name,
        rooms: filters.roomsSelected,
        services: filters.servicesFilterApplied.length ? filters.servicesFilterApplied.map(service => SERVICES_IDS[service]) : NONE,
        type: filters.typeFilterApplied.length ? filters.typeFilterApplied.map(type => TYPE_ESTABLISHMENT[type]) : NONE,
        zones: filters.zonesFilterApplied.length ? filters.zonesFilterApplied.map(zoneId => zones.find(zone => zoneId === zone.id).name) : NONE,
      };
    default:
      return NONE;
  }
}
