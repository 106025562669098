import PropTypes from 'prop-types';
import React from 'react';

export const NAVIGATION_BAR_ACTIONS = {
  BACK: 'back',
  FAVS: 'favs',
  HOME: 'home',
  PERSONAL_AREA: 'personal_area',
};


export default function NavigationBar({ className, favouritesCount, handleOnClick, hasBack, isLogged, inFavourites, inPersonalArea }) {
  return (
    <div className={ `navigation-bar ${className}` }>
      <div className="navigation-bar__nav-container">
        { hasBack
          && (
            <a className="navigation-bar__nav-container__back" onClick={ handleOnClick.bind(this, NAVIGATION_BAR_ACTIONS.BACK) }>
              <span className="hundredicons-arrow-left-filled" />
            </a>
          )
        }
        <a className="navigation-bar__nav-container__home" href="/">
          <img alt="Hundredrooms logo" className="navigation-bar__nav-container__home__logo" src="https://static.100r.systems/img/general/HR-Logo-Horizontal.svg" />
        </a>
      </div>
      <div className="navigation-bar__container">
        <a className="navigation-bar__container__favourites" onClick={ handleOnClick.bind(this, NAVIGATION_BAR_ACTIONS.FAVS) }>
          <span className={ `navigation-bar__container__favourites__logo ${inFavourites ? 'hundredicons-hr-icon-favorito-filled' : 'hundredicons-hr-icon-favorito'}` } />
          { favouritesCount > 0
            && (
              <div className="navigation-bar__container__favourites__counter">
                <span className="navigation-bar__container__favourites__counter__number">
                  { favouritesCount < 1000 ? favouritesCount : '∞' }
                </span>
              </div>
            )
          }
        </a>
        <a className="navigation-bar__container__personal" onClick={ handleOnClick.bind(this, NAVIGATION_BAR_ACTIONS.PERSONAL_AREA) }>
          <span className={ `navigation-bar__container__personal__logo ${inPersonalArea ? 'hundredicons-hr-icon-area-personal-filled' : 'hundredicons-hr-icon-area-personal'}` } />
          { isLogged
              && (
                <div className="navigation-bar__container__personal__is-logged">
                  <span className="navigation-bar__container__personal__is-logged__text hundredicons-check" />
                </div>
              )
          }
        </a>
      </div>
    </div>
  );
}

NavigationBar.defaultProps = {
  className: '',
  favouritesCount: 0,
};

NavigationBar.propTypes = {
  className: PropTypes.string,
  favouritesCount: PropTypes.number,
  handleOnClick: PropTypes.func.isRequired,
  hasBack: PropTypes.bool.isRequired,
  inFavourites: PropTypes.bool.isRequired,
  inPersonalArea: PropTypes.bool.isRequired,
  isLogged: PropTypes.bool.isRequired,
};
