import PropTypes from 'prop-types';
import React from 'react';


export default function SubmitSearch({ handleOnClick, isLoading, t }) {
  const isIE = typeof window !== 'undefined' ? global.isExplorer : null;
  return (
    <div className="search-bar-molecule__desktop__box__action -serp-search-button">
      <button
        className={ `search-bar-molecule__desktop__box__action__button-serp ${isLoading ? 'hundredicons-icon-loading -loading-serp' : ''} ` }
        onClick={ handleOnClick(false) }
        type="button"
      >
        <span className={ `search-bar-molecule__desktop__box__action__button-serp__text ${isIE ? '-ie-button-fallback' : ''} ` }>
          { t('Search') }
        </span>
      </button>
    </div>
  );
}

SubmitSearch.defaultProps = {
  isLoading: false,
};

SubmitSearch.propTypes = {
  handleOnClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
