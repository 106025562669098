function isObject(element) {
  return element === Object(element);
}

function calculateNestedStateValue(currentState, newState) {
  if (!isObject(newState)) return newState;

  return Object.keys(newState).reduce((state, key) => (
    calculateNestedState(state, key, newState[key])
  ), currentState);
}

export function calculateNestedState(currentState, nestedStateKey, newNestedState) {
  const currentNestedState = currentState[nestedStateKey];
  return Object.assign({}, currentState, {
    [nestedStateKey]: calculateNestedStateValue(currentNestedState, newNestedState),
  });
}

export const isEmptyObject = value => JSON.stringify(value) === JSON.stringify({});
