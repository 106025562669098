import DestinationInput from 'molecules/search-bar/destination-input';
import GuestInput from 'molecules/search-bar/guests-input';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDatesContainer from 'components/react-dates-container';
import { recentSearchesInterface } from 'constants/interfaces';
import SubmitSearch from 'molecules/search-bar/submit-search';


export default function DesktopSearchBar({ destinationName, destinationLat, destinationLng, domainCulture, onDestinationSelected,
  onRecenSearchSelect, selectDestination, dateArrival, dateLeaving, guests, onGuestChange, onSubmitSearch,
  destinationTyping, clearDestination, storedSearches, showCalendar, onDatesChange, hideCalendar,
  searchInputFocused, scrollFocus, isOnModal, needsButton, isLoading, closeCalendar, toggleCalendar, t }) {
  return (
    <div className="search-bar-molecule__desktop">
      <div className={ `search-bar-molecule__desktop__box -${domainCulture}` }>
        <DestinationInput
          clearDestination={ clearDestination }
          destinationLat={ destinationLat }
          destinationLng={ destinationLng }
          destinationName={ destinationName }
          destinationTyping={ destinationTyping }
          isOnModal={ isOnModal }
          onDestinationSelected={ onDestinationSelected }
          onRecenSearchSelect={ onRecenSearchSelect }
          scrollFocus={ scrollFocus }
          searchInputFocused={ searchInputFocused }
          selectDestination={ selectDestination }
          storedSearches={ storedSearches }
          t={ t }
        />
        <ReactDatesContainer
          closeCalendar={ closeCalendar }
          endDate={ dateLeaving }
          handleOnClick={ onSubmitSearch }
          hideCalendar={ hideCalendar }
          isOnModal={ !!global.isPhone || !!global.isTablet }
          needsButton={ needsButton }
          onDatesChange={ onDatesChange }
          showCalendar={ showCalendar }
          startDate={ dateArrival }
          toggleCalendar={ toggleCalendar }
        />
        <GuestInput
          guests={ guests }
          handleOnClick={ onGuestChange }
          t={ t }
        />
        <SubmitSearch
          handleOnClick={ onSubmitSearch }
          isLoading={ isLoading }
          t={ t }
        />
      </div>
    </div>
  );
}

DesktopSearchBar.defaultProps = {
  dateArrival: null,
  dateLeaving: null,
  destinationLat: null,
  destinationLng: null,
  domainCulture: '',
  isLoading: null,
  searchInputFocused: null,
  showCalendar: null,
};

DesktopSearchBar.propTypes = {
  clearDestination: PropTypes.func.isRequired,
  closeCalendar: PropTypes.bool.isRequired,
  dateArrival: PropTypes.number,
  dateLeaving: PropTypes.number,
  destinationLat: PropTypes.number,
  destinationLng: PropTypes.number,
  destinationName: PropTypes.string.isRequired,
  destinationTyping: PropTypes.func.isRequired,
  domainCulture: PropTypes.string,
  guests: PropTypes.number.isRequired,
  hideCalendar: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isOnModal: PropTypes.bool.isRequired,
  needsButton: PropTypes.bool.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onDestinationSelected: PropTypes.func.isRequired,
  onGuestChange: PropTypes.func.isRequired,
  onRecenSearchSelect: PropTypes.func.isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
  scrollFocus: PropTypes.func.isRequired,
  searchInputFocused: PropTypes.bool,
  selectDestination: PropTypes.func.isRequired,
  showCalendar: PropTypes.bool,
  storedSearches: recentSearchesInterface.isRequired,
  t: PropTypes.func.isRequired,
  toggleCalendar: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
};
