import PropTypes from 'prop-types';
import React from 'react';
import { recentSearchesInterface } from 'constants/interfaces';
import SearchFormDestination from 'app/components/forms/SearchFormDestination';


export default function DestinationInput({ storedSearches, destinationName, destinationLat, destinationLng,
  onDestinationSelected, onRecenSearchSelect, selectDestination, submitIncompleteFlag, destinationTyping,
  clearDestination, searchInputFocused, scrollFocus, isOnModal, t }) {
  return (
    <div className="search-bar-molecule__desktop__box__destination" onClick={ global.isPhone ? scrollFocus : () => {} }>
      <span className="search-bar-molecule__desktop__box__destination__title">
        { t('Destination') }
      </span>
      <SearchFormDestination
        className="search-bar-molecule__desktop__box__destination__input"
        clearDestination={ clearDestination }
        destinationLat={ destinationLat }
        destinationLng={ destinationLng }
        destinationName={ destinationName }
        destinationTyping={ destinationTyping }
        error={ submitIncompleteFlag && destinationName === '' }
        isOnModal={ isOnModal }
        onDestinationSelected={ onDestinationSelected }
        onRecenSearchSelect={ onRecenSearchSelect }
        searchInputFocused={ searchInputFocused }
        selectDestination={ selectDestination }
        storedSearches={ storedSearches }
      />
    </div>
  );
}

DestinationInput.defaultProps = {
  destinationLat: null,
  destinationLng: null,
  searchInputFocused: null,
  submitIncompleteFlag: null,
};

DestinationInput.propTypes = {
  clearDestination: PropTypes.func.isRequired,
  destinationLat: PropTypes.number,
  destinationLng: PropTypes.number,
  destinationName: PropTypes.string.isRequired,
  destinationTyping: PropTypes.func.isRequired,
  isOnModal: PropTypes.bool.isRequired,
  onDestinationSelected: PropTypes.func.isRequired,
  onRecenSearchSelect: PropTypes.func.isRequired,
  scrollFocus: PropTypes.func.isRequired,
  searchInputFocused: PropTypes.bool,
  selectDestination: PropTypes.func.isRequired,
  storedSearches: recentSearchesInterface.isRequired,
  submitIncompleteFlag: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
