import PropTypes from 'prop-types';
import React from 'react';


const Claim = ({ className, title, subtitle }) => (
  <header className={ `claim ${className}` }>
    <p className="claim__subtitle">
      { subtitle }
    </p>
    <h1 className="claim__title">
      { title }
    </h1>
  </header>
);

Claim.defaultProps = {
  className: '',
  subtitle: '',
  title: '',
};

Claim.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

export default Claim;
