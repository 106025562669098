import PropTypes from 'prop-types';
import PublishLink from 'atoms/publish-link';
import React from 'react';


export default function PublishAd({ className, title, body, ads, handleOnClick, device }) {

  function clickoutReplace(clickout) {
    return clickout.replace('<DEVICE>', device);
  }

  return (
    <div className={ `publish-ad ${className}` } id="publishAdInline">
      <div className="publish-ad__content">
        <span className="publish-ad__content__line" />
        <span className="publish-ad__content__title">
          { title }
        </span>
        <span className="publish-ad__content__body">
          { body }
        </span>
        <div className="publish-ad__content__ads">
          { ads.map((ad, i) => (
            <PublishLink
              key={ i }
              clickout={ clickoutReplace(ad.clickout) }
              handleOnClick={ () => handleOnClick(ad.name) }
              image={ ad.image }
            />
          )) }
        </div>
        <span className="publish-ad__content__last-line" />
      </div>
    </div>
  );
}

PublishAd.defaultProps = {
  className: '',
  device: 'dk',
  handleOnClick: () => {},
};

PublishAd.propTypes = {
  ads: PropTypes.arrayOf(
    PropTypes.shape({
      clickout: PropTypes.string,
      image: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  body: PropTypes.string.isRequired,
  className: PropTypes.string,
  device: PropTypes.string,
  handleOnClick: PropTypes.func,
  title: PropTypes.string.isRequired,
};
