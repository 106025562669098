/* eslint-disable jsx-a11y/anchor-has-content */
import PropTypes from 'prop-types';
import React from 'react';


export default function Permanent({ shouldHaveLinks, routes, socialMediaLinks, follow, t }) {
  return (
    <div className="footer-molecule__second-footer">
      <div className="footer-molecule__second-footer__copyright">
        <span
          className="footer-molecule__second-footer__copyright__logo"
          src="/img/pages/home/icon-gray.png"
        />
        <p className="footer-molecule__second-footer__copyright__text">
          { t('2017 © All rights reserved') }
          <a href={ `/${routes.termsOfServ}/` } rel={ follow ? '' : 'nofollow' } target="_blank">
            <br />
            { t('Privacy and Cookies Policy') }
          </a>
        </p>
      </div>
      <div className="footer-molecule__second-footer__made">
        <p className="footer-molecule__second-footer__made__text">
          { "Made with " }
          <span className="footer-molecule__second-footer__copyright__made__icon hundredicons-heart-filled" />
          { " in Palma" }
        </p>
      </div>
      <div className={ `footer-molecule__second-footer__social-links ${shouldHaveLinks ? '' : '-not-border-top'}` }>
        <ul className="footer-molecule__second-footer__social-links__list">
          { socialMediaLinks.map(media => (
            <li key={ media.name } className="footer-molecule__second-footer__social-links__list__item">
              <a
                className={ `footer-molecule__second-footer__social-links__list__item__link  -social-icons-${media.name}` }
                href={ media.url }
                rel={ follow ? '' : 'nofollow' }
                target="_blank"
              />
            </li>
          )) }
        </ul>
      </div>
    </div>
  );
}

Permanent.defaultProps = {
  shouldHaveLinks: null,
  socialMediaLinks: [],
};

Permanent.propTypes = {
  follow: PropTypes.bool.isRequired,
  routes: PropTypes.shape().isRequired,
  shouldHaveLinks: PropTypes.bool,
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  t: PropTypes.func.isRequired,
};
