import { cleanZones, getZones } from 'actions/geoActions';
import { getSearch, isFormOk } from 'reducers/main';
import { requestCheckboxes, requestInlines } from 'actions/adActions';
import { browserHistory } from 'react-router';
import { generateSearchUrlNew } from 'helpers/urlGenerator';
import { getBoundingBoxById } from 'services/geo';
import { getCurrentRoute } from 'app/route-container';
import gtmPageView from 'services/tracking/gtm/page-view';
import Hr from 'service-client-js';
import { ID_ROUTES } from 'constants/routes';
import moment from 'moment';
import { requestCreateSearch } from 'actions/requestActions';
import { SEARCH_FORM_ACTIONS } from 'constants/actions';
import { storeLoggerSearchId } from 'actions/searchResultsActions';


export const createSearch = noCallbacks => (dispatch, getState) => {

  const state = getState();
  const formState = state.searchFormReducer;
  const { destination } = state;

  const data = {
    country_code: destination.country,
    date_arrival: moment(formState.dateArrival).format('DD/MM/YYYY'),
    date_arrival_timestamp: moment(formState.dateArrival).valueOf(),
    date_leaving: moment(formState.dateLeaving).format('DD/MM/YYYY'),
    date_leaving_timestamp: moment(formState.dateLeaving).valueOf(),
    destination: destination.name,
    guests_number: formState.guestsNumber,
    latitude_ne: destination.lat_ne,
    latitude_sw: destination.lat_sw,
    longitude_ne: destination.lng_ne,
    longitude_sw: destination.lng_sw,
    place_id: destination.place_id,
    polygon_id: destination.polygon_id,
  };
  dispatch(requestCreateSearch(data, noCallbacks));
};

export const updateLoadingState = loadingState => ({
  loadingState,
  type: SEARCH_FORM_ACTIONS.UPDATE_LOADING_STATE,
});

export const createSearchByEstablishment = (establisments, noCallbacks, loggerSearchId = '', lat = false, lng = false) => (dispatch, getState) => {
  const formState = getState().searchFormReducer;
  const data = {
    date_arrival: moment(formState.dateArrival).format('DD/MM/YYYY'),
    date_arrival_timestamp: moment(formState.dateArrival).valueOf(),
    date_leaving: moment(formState.dateLeaving).format('DD/MM/YYYY'),
    date_leaving_timestamp: moment(formState.dateLeaving).valueOf(),
    establishments: establisments,
    guests_number: formState.guestsNumber,
  };

  if (lat && lng) {
    data.lat = lat;
    data.long = lng;
  }

  dispatch({ destination: null, formState, type: SEARCH_FORM_ACTIONS.SUBMIT });
  dispatch(requestCreateSearch(data, noCallbacks));
  dispatch(storeLoggerSearchId(loggerSearchId));
};

export const destinationTyping = destination => ({
  type: SEARCH_FORM_ACTIONS.DESTINATION_TYPING,
  value: destination,
});

export const clearDestination = () => (dispatch) => {
  dispatch({ type: SEARCH_FORM_ACTIONS.CLEAR_DESTINATION });
};

export const selectDate = (dateArrival, dateLeaving) => (dispatch, getState) => {
  dispatch({ dateArrival, dateLeaving, type: SEARCH_FORM_ACTIONS.DATE_SELECTION });
  dispatch(requestCheckboxes(getSearch(getState())));

  if (isFormOk(getState())
      && getCurrentRoute() !== ID_ROUTES.ESTABLISHMENT) {
    dispatch(createSearch(true));
  }
};

export const selectGuests = guestsNumber => (dispatch, getState) => {
  dispatch({ guestsNumber, type: SEARCH_FORM_ACTIONS.GUESTS_SELECTION });
  dispatch(requestCheckboxes(getSearch(getState())));
  (global.IntentMediaProperties || {}).travelers = guestsNumber;
  (global.IntentMediaProperties || {}).hotel_rooms = Math.ceil(guestsNumber / 2);
};

export const submitIncomplete = () => ({
  type: SEARCH_FORM_ACTIONS.SUBMIT_WITH_ERRORS,
});

/* ****************************************************************** */
/* *************************  MIDDLEWARE **************************** */
/* ****************************************************************** */

export function selectHoliduDestination(name, path) {
  return {
    name,
    path,
    type: SEARCH_FORM_ACTIONS.STORE_HOLIDU_DESTINATION,
  };
}

export function selectDestination(name, data, placeId) {
  return (dispatch, getState) => {
    if (data && typeof data !== 'string') {
      dispatch(Object.assign({ name, type: SEARCH_FORM_ACTIONS.DESTINATION_SELECTED }, data));
      const state = getState();
      dispatch(requestCheckboxes(getSearch(state)));
    }
    if (placeId) {
      dispatch(updateLoadingState(true));
      return getBoundingBoxById(placeId)
        .then((resp = {}) => {
          const { polygon_id: polygonId } = resp;
          const state = getState();

          if (polygonId) dispatch(getZones(polygonId));
          else dispatch(cleanZones());

          dispatch(selectDestination(name, resp));
          dispatch(requestCheckboxes(getSearch(state)));
          dispatch(requestInlines(getSearch(state)));

          if (isFormOk(getState())
            && getCurrentRoute() !== ID_ROUTES.ESTABLISHMENT) {
            dispatch(createSearch(true));
          }
          dispatch(updateLoadingState(false));
        });
    }
  };
}

export function resetForm() {
  return { type: SEARCH_FORM_ACTIONS.RESET_FORM };
}

/**
 * Performs a search action and redirects to a search url.
 * It might open the results in the same or a new window
 * (whether checkboxes are marked or not)
 */
function updateUrlAndRequest(state, dispatch) {
  const url = generateSearchUrlNew(state);
  if (url === global.location.pathname + global.location.search) {
    dispatch({ type: SEARCH_FORM_ACTIONS.DESTINATION_SELECTED, ...state.destination });
    dispatch(createSearch());
  }

  /**
   * Check during 1 second that the popup isn't blocked.
   * If that's the case, just navigate to results in the same window
   * This is because some browsers block the new window when the search is not triggered
   * with the submit button.
   */
  const checkNewWindowIsOpen = (newWindow, timesChecked = 0) => {
    if (newWindow && newWindow.closed === false) {

      const checkboxs = state.adsReducer.checkboxes;

      if (checkboxs.length) {
        window.name = 'checkbox';
        window.hr.checkboxHref = checkboxs[0].clickout;
        setTimeout(() => { window.location.href = window.hr.checkboxHref; }, 100);
      }

    } else if (timesChecked <= 10) {
      setTimeout(() => checkNewWindowIsOpen(newWindow, timesChecked + 1), 100);
    } else {
      window.open(url, '_self');
    }
  };

  if (areThereAdCheckboxesChecked(state.adsReducer)) {
    const isCached = Hr.establishment.search.startData ? `&is_cached=${Hr.establishment.search.startData.is_cached}` : '';
    const newWindowForResults = window.open(url.concat(isCached), '_blank');
    checkNewWindowIsOpen(newWindowForResults);
  } else {
    gtmPageView();
    window.open(url, '_self');
  }
}

export const setSearchOrigin = () => ({
  searchOrigin: getMatchingRoute(),
  type: SEARCH_FORM_ACTIONS.SEARCH_START_ORIGIN,
});

export const isSearchFromMap = isMap => ({
  isSearchFromMap: isMap,
  type: SEARCH_FORM_ACTIONS.SEARCH_FROM_MAP,
});

export const searchFromRecentSearches = url => (dispatch) => {
  dispatch(setSearchOrigin());
  dispatch(isSearchFromMap(false));
  global.setTimeout(() => browserHistory.push(url), 120);
};

/**
 * User requested a form submit by clicking a button
 * @return {function}
 */
export const submitRequest = () => (dispatch, getState) => {
  const currentState = getState();
  if (isFormOk(currentState)) {
    // if user has changed guests number we discard
    // advanced search and trigger a new one
    if (getCurrentRoute() === 'HOME' && currentState.searchFormReducer.guestsNumber !== 2) {
      dispatch(createSearch(true));
    }
    dispatch(setSearchOrigin());
    dispatch(isSearchFromMap(false));
    updateUrlAndRequest(currentState, dispatch);
    return Promise.resolve(null);
  }
  dispatch(submitIncomplete());
  return Promise.resolve(true);
};

function areThereAdCheckboxesChecked(adsState) {
  return adsState.checkboxes.some(checkbox => checkbox.checked);
}

export function getMatchingRoute(url = null) {
  const location = (url && url.pathname) || window.location.pathname;
  if (decodeURIComponent(location) === '/') return ['home'];
  const basePath = decodeURIComponent(location).split('/').filter(path => path.length > 0)[0];
  const regexp = new RegExp(basePath, 'gi');
  return Object.keys(window.hr.intl.ROUTES).filter(route => regexp.test(window.hr.intl.ROUTES[route]));
}

export const reverseDestination = destination => dispatch => dispatch({
  destination,
  type: SEARCH_FORM_ACTIONS.REVERSE_DESTINATION,
});

export const updateSearchSubsection = (subSection = 0) => ({
  subSection,
  type: SEARCH_FORM_ACTIONS.UPDATE_SEARCH_SECTION,
});

export const setSearchKeywords = keywords => ({
  keywords, type: SEARCH_FORM_ACTIONS.SET_SEARCH_KEYWORDS,
});
