import { generateSearchUrlNew } from 'helpers/urlGenerator';
import { RECENT_SEARCHES_ACTIONS } from '../constants/actions';

/**
 * Stores a search
 * @param  {array} search
 */
export function save(searchId, state) {
  const search = _prepareRecentSearchToBeStored(searchId, state);
  return {
    search,
    type: RECENT_SEARCHES_ACTIONS.SAVE,
  };
}

function _prepareRecentSearchToBeStored(searchId, state) {
  const recentSearch = Object.assign({}, state.searchApplied, state.destination);
  // We also want to store the search id and query in the object we will save
  recentSearch.id = searchId;
  recentSearch.query = generateSearchUrlNew(state);
  return recentSearch;
}
