import { SEARCH_FORM_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';
import Prediction from 'services/Prediction';


export const updateCheapestDays = days => ({
  days,
  type: SEARCH_FORM_ACTIONS.UPDATE_CHEAPEST_DAYS,
});

export const removeCheapestDays = () => ({
  type: SEARCH_FORM_ACTIONS.REMOVE_CHEAPEST_DAYS,
});

export const updatePricePrediction = prediction => ({
  prediction,
  type: SEARCH_RESULTS_ACTIONS.UPDATE_PRICE_PREDICTION,
});

export const cleanPricePrediction = () => ({
  type: SEARCH_RESULTS_ACTIONS.CLEAN_PRICE_PREDICTION,
});

export const retrieveCheapestDays = (country, lat, lng, radius) => async (dispatch) => {
  try {
    const days = await Prediction.forPlace(country, lat, lng, radius).getCheapestDays();
    dispatch(updateCheapestDays(days));
  } catch (e) { /* */ }
};

export const getPredictionPrice = (dateArrival, dateLeaving, guests, country, lat, lng, radius) => dispatch => new Prediction().getPredictionPrice(dateArrival, dateLeaving, guests, country, lat, lng, radius)
  .then(res => dispatch(updatePricePrediction(res)))
  .catch(() => dispatch(cleanPricePrediction()));
