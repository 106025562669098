import PropTypes from 'prop-types';
import React from 'react';
import { trackPublishEstablishment } from 'services/tracking';


export default function PublishEstablishment({ partnersLinks, device, t }) {
  if (!Object.keys(partnersLinks).length) return null;

  function clickoutReplace(clickout) {
    return clickout.replace('<DEVICE>', device);
  }

  return (
    <ul className="footer-molecule__first-footer__links__publish-establishment-container">
      <p className="footer-molecule__first-footer__links__publish-establishment__title">
        { t('Publish your establishment') }
      </p>
      {
        Object.keys(partnersLinks).map((key, index) => (
          <li
            key={ index }
            className="footer-molecule__first-footer__links__publish-establishment__link"
          >
            <a
              className="footer-molecule__first-footer__links__publish-establishment__link__a"
              href={ clickoutReplace(partnersLinks[key].clickout) }
              onClick={ () => trackPublishEstablishment('click to publish', partnersLinks[key].name) }
              rel="nofollow"
              target="_blank"
            >
              { partnersLinks[key].name }
            </a>
          </li>
        ))
      }
    </ul>
  );
}

PublishEstablishment.propTypes = {
  device: PropTypes.string.isRequired,
  partnersLinks: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};
