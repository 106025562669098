import { ID_PAGES, SEO_LANDING_PAGES } from "constants/routes";
import Ad from 'services/AdService/Ad';
import { getPageType } from 'services/tracking/gtm/gtm-data';
import { INLINE_SNIPPET_POSTIONS } from 'constants/constants';
import { inlineAds } from 'services/AdService/database';
import uuid4 from 'helpers/crypto';


export default class AdInline {
  constructor(search) {
    this.countryCode = global.hr.intl.COUNTRY_CODE;
    this.search = search;
  }

  getInlinesToShow() {
    const ads = [];
    const sortedAds = this.getAds();
    const device = global.isPhone || global.isTablet ? 'mobile' : 'desktop';
    const numberOfAds = INLINE_SNIPPET_POSTIONS[global.hr.intl.COUNTRY_CODE][device].length;
    sortedAds.forEach((ad) => {
      if (ads.length < numberOfAds) {
        ads.push(ad.dynamicUrl ? this.parseAd(ad) : ad);
      }
    });
    return ads;
  }

  getAds() {
    const { airbnb, airbnbSeo, booking, bookingSeo, tui, edomizil, onlyapartments } = inlineAds;
    const pageId = ID_PAGES[getPageType(window.location)];
    let ads = [airbnb];

    switch (this.countryCode) {
      case 'de':
        ads = [airbnb, tui, edomizil, onlyapartments];
        break;
      case 'es':
        if (SEO_LANDING_PAGES.includes(pageId)) {
          ads = global.isPhone ? [bookingSeo] : [airbnbSeo];
        } else if (global.isPhone) {
          ads = [booking];
        }
        break;
      default:
        break;
    }

    return ads
      .filter(ad => ad && ad.countryParams && !!ad.countryParams[this.countryCode])
      .sort((a, b) => a.order - b.order);
  }

  getDeviceShort() {
    if (global.isPhone) return 'mo';
    if (global.isTablet) return 'ta';
    return 'dk';
  }

  parseAd(ad) {
    ad.cpc = ad.countryParams[this.countryCode].cpc[this.getDeviceShort()];
    ad.domain = this.countryCode;
    ad.device = this.getDeviceShort();
    ad.transactionId = uuid4();
    ad.type = 'inline';

    return Ad.forSearch(ad, this.search).toObject();
  }

}
