
const t = literal => literal; // fake 't' to mark for push-literals without eval of translations on import

const _literals = {
  fullRefund: t("Holiday rental with free cancellation until the day of your arrival."),
  fullRefundOneDayWithNotice: t("If you cancel this reservation up to {cancellationPolicyDays} day before your arrival, you will get a 100% refund on the initial price."),
  fullRefundWithNotice: t("If you cancel this reservation up to {cancellationPolicyDays} days before your arrival, you will get a 100% refund on the initial price."),
  partialRefund: t("If you cancel your reservation on the day of your arrival, you will get a {cancellationPolicyRefund}% refund on the initial price."),
  partialRefundOneDayWithNotice: t("If you cancel your reservation up to {cancellationPolicyDays} day before your arrival, you will get a {cancellationPolicyRefund}% refund on the initial price."),
  partialRefundWithNotice: t("If you cancel your reservation up to {cancellationPolicyDays} days before your arrival, you will get a {cancellationPolicyRefund}% refund on the initial price."),
};

export const getSortedCancellationPoliciesPercentages = cancellationPolicies => (
  Object.keys(cancellationPolicies)
    .map(policy => Number(policy))
    .sort((a, b) => a < b)
);

export const getCancellationPolicyLiteral = (policy, translatable) => {
  const policyRefund = Number(Object.keys(policy)[0]);
  const policyDays = policy[policyRefund];

  const literalContext = { cancellationPolicyDays: policyDays, cancellationPolicyRefund: policyRefund };
  if (policyRefund === 100) {
    if (policyDays === 0) return translatable(_literals.fullRefund, literalContext);
    if (policyDays === 1) return translatable(_literals.fullRefundOneDayWithNotice, literalContext);
    return translatable(_literals.fullRefundWithNotice, literalContext);
  }
  if (policyDays === 0) return translatable(_literals.partialRefund, literalContext);
  if (policyDays === 1) return translatable(_literals.partialRefundOneDayWithNotice, literalContext);
  return translatable(_literals.partialRefundWithNotice, literalContext);
};

export const getCancellationPolicyLiterals = (cancellationPolicies, translatable) => (
  getSortedCancellationPoliciesPercentages(cancellationPolicies)
    .map(policy => getCancellationPolicyLiteral({ [policy]: cancellationPolicies[policy] }, translatable))
);
