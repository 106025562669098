import PropTypes from 'prop-types';
import React from 'react';


export default function Dropdown({ className, title, content, follow }) {
  return (
    <li className={ `${className} dropdown` }>
      <p className="dropdown__title">
        { title }
      </p>
      <ul className="dropdown__list">
        {
          content.map((item, i) => (
            <li key={ i } className="dropdown__list__item" data-index={ i }>
              <a
                className="dropdown__list__item__link"
                href={ item.href }
                onClick={ item.onClick ? item.onClick : null }
                rel={ follow ? '' : 'nofollow' }
                target={ item.target ? item.target : '' }
              >
                { item.text }
              </a>
            </li>
          ))
        }
      </ul>
    </li>
  );
}

Dropdown.defaultProps = {
  className: '',
  follow: false,
  title: '',
};

Dropdown.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      onClick: PropTypes.func,
      target: PropTypes.string,
      text: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]).isRequired,
    })
  ).isRequired,
  follow: PropTypes.bool,
  title: PropTypes.string,
};
