import { hideModal, showModal } from 'actions/modals';
import { RECOMMENDATION_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';
import { browserHistory } from 'react-router';
import { establishmentOpen } from 'actions/map';
import { generateEstablishmentPageUrl } from 'helpers/urlGenerator';
import { getEstablishmentById } from 'reducers/main';
import { MODALS } from 'constants/constants';
import queryString from 'querystring';
import { requestEstablishmentInfo } from 'actions/requestActions';
import { requestRecommendations } from 'actions/recommendation-actions';
import { saveRecentlyViewed } from 'actions/recentlyViewedAction';
import { trackMapMobile } from 'services/tracking';
import Url from 'url';

export function startSearch(hash) {
  return {
    hash,
    type: SEARCH_RESULTS_ACTIONS.START_SEARCH,
  };
}

// Sets the results page to its initial values
export const resetResultsPage = () => (dispatch, getState) => {
  const state = getState();
  const shownEstablishmentId = (state.modals.name === MODALS.ESTABLISHMENT
    && state.modals.props.id) || (global.SERVERDATA && global.SERVERDATA.id);

  return dispatch(Object.assign({
    type: SEARCH_RESULTS_ACTIONS.RESET_RESULTS_PAGE,
  }, shownEstablishmentId ? {
    establishment: {
      [shownEstablishmentId]: getEstablishmentById(state, shownEstablishmentId),
    },
  } : {}));
};

// Ordering
export const orderEstablishments = order => (dispatch, getState) => dispatch({
  filters: getState().filtersApplied,
  order,
  type: SEARCH_RESULTS_ACTIONS.ORDER_ESTABLISHMENTS,
});

// Paginate
export const selectPage = page => (dispatch) => {
  dispatch({
    page,
    type: SEARCH_RESULTS_ACTIONS.SELECT_ESTABLISHMENTS_PAGE,
  });
};

// Display details of an establishment
export const establishmentOver = establishmentId => ({
  establishmentId,
  type: SEARCH_RESULTS_ACTIONS.ESTABLISHMENT_OVER,
});

export const showEstablishment = establishmentId => (dispatch) => {
  dispatch(showModal(MODALS.ESTABLISHMENT, { id: establishmentId }));
  dispatch(saveRecentlyViewed(establishmentId));
};

export const hideEstablishment = establishmentId => (dispatch) => {
  dispatch(hideModal({ name: MODALS.ESTABLISHMENT, props: { id: establishmentId } }));
};

export const displayEstablishment = (establishmentId, { replace, onComplete } = {}) => (dispatch, getState) => {
  const state = getState();
  const searchHash = state.searchResultsReducer.searchHash || '';
  const searchId = state.searchResultsReducer.loggerSearchId || '';
  const currentLocation = window.location;
  const referrerLocation = Url.parse(currentLocation.href, true);
  let newReferrerLocation = null;

  setTimeout(() => dispatch(requestRecommendations(state.loggerReducer.userId, Object.keys(state.establishments).map(k => Number(k)))), 2000);

  if (!global.isPhone) {
    const establishmentPageURL = generateEstablishmentPageUrl(establishmentId, '');
    window.open(establishmentPageURL, '_self');
  } else {
    dispatch(requestEstablishmentInfo(establishmentId));
    newReferrerLocation = Url.parse(`/establishment/${establishmentId}`, true);
    newReferrerLocation.search = queryString.stringify(referrerLocation.query);
    if (searchId) newReferrerLocation.search += `&searchId=${searchId}`;
    if (searchHash) newReferrerLocation.search += `&searchHash=${searchHash}`;

    if (!newReferrerLocation) browserHistory[replace ? 'replace' : 'push'](Url.parse(Url.format(referrerLocation)).path);
    else browserHistory[replace ? 'replace' : 'push'](Url.parse(Url.format(newReferrerLocation)).path);
    if (onComplete) onComplete.call(null);
  }
};

// Fetching data
export const isFetchingEstablishmentsInArea = polygon => ({
  polygon,
  type: SEARCH_RESULTS_ACTIONS.FETCHING_ESTABLISHMENTS_IN_AREA,
});

// Receives the establishments requested asynchronously
export const receiveEstablishments = establishments => ({
  establishments,
  type: SEARCH_RESULTS_ACTIONS.RECEIVE_ESTABLISHMENTS,
});

// Receives the establishments requested asynchronously
export const receiveVisibleEstablishments = (establishments, searchStatus) => ({
  establishments,
  searchStatus,
  type: SEARCH_RESULTS_ACTIONS.RECEIVE_VISIBLE_ESTABLISHMENTS,
});

export const showOldModal = (modalShowed, veilType) => ({
  modalShowed,
  type: SEARCH_RESULTS_ACTIONS.SHOW_MODAL,
  veilType,
});

export const showMap = (show) => {
  window.scrollTo(0, 0);
  trackMapMobile(show);
  return {
    show,
    type: SEARCH_RESULTS_ACTIONS.SHOW_MAP,
  };
};

export const removeEstablishment = establishmentId => (dispatch, getState) => {
  const currentSelectedEstablishment = getState().map.openEstablishment;
  if (currentSelectedEstablishment === establishmentId) {
    // filter visibleEstablishments because we need to assign new openStablishment before remove it,
    // and if the establishment removed is the first one, we get indexOf == -1
    const visibleEstablismentListWithoutRemovedEstablishment = getState().searchResultsReducer.visibleEstablishments.filter(estb => estb !== establishmentId);
    dispatch(establishmentOpen(visibleEstablismentListWithoutRemovedEstablishment.length ? visibleEstablismentListWithoutRemovedEstablishment[0] : null));
  }
  dispatch({ establishmentId, type: SEARCH_RESULTS_ACTIONS.REMOVE_ESTABLISHMENT });
  dispatch({ type: RECOMMENDATION_ACTIONS.RECEIVE_RECOMMENDATIONS });
};

export function storeLoggerSearchId(loggerSearchId) {
  return {
    loggerSearchId,
    type: SEARCH_RESULTS_ACTIONS.STORE_SEARCH_ID,
  };
}

export const initPriceRange = () => ({ type: SEARCH_RESULTS_ACTIONS.INIT_PRICE_RANGE });
