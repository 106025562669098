import { COOKIES, HALF_HOUR_IN_MILIS } from 'constants/constants';
import { deleteCookie, getCookieValue, setCookie } from 'helpers/cookies-helper';
import { dispatch, getState } from 'services/store';
import { formatFromString, getNumberOfNights } from 'helpers/dates-helper';
import { storeUserId, storeVisitId } from 'actions/logger-actions';
import axios from 'axios';
import { getMatchingRoute } from 'actions/searchFormActions';
import { getPageType } from 'services/tracking/gtm/gtm-data';
import { getSortedCancellationPoliciesPercentages } from 'services/cancellation';
import { ID_PAGES } from 'constants/routes';
import { sleep } from 'helpers/async';
import { storeLoggerSearchId } from 'actions/searchResultsActions';
import Url from 'url';
import uuid4 from 'helpers/crypto';


const LOGGER_TYPES = {
  CHECKBOX: 'checkboxes',
  CLICK: 'clicks',
  CLICKIN: 'clickins',
  FAVOURITES: 'favourites',
  FILTERS: 'filters',
  INLINE: 'inline',
  KEYWORDS: 'keywords',
  LEADS: 'leads',
  PINMAP: 'pinmap',
  SEARCH: 'searches',
  SHARE_ESTABLISHMENT: 'share_establishment',
  SUGGESTIONBOX: 'suggestionboxes',
  VISIT: 'visit',
};

const PLATFORM_IDS = {
  DESKTOP: 0,
  PHONE: 1,
  TABLET: 2,
};

export const SEARCH_SUBSECTIONS = {
  AMENITIES: 6,
  CALENDAR: 7,
  ESTABLISHMENT_CARD: 5,
  LOW_AVAILABILITY: 8,
  MAP_DRAW: 3,
  MAP_MOVE: 4,
  MAP_RESEARCH: 2,
  OTHERS: 0,
  SEARCHBAR: 1,
};

const MAX_RETRIES = 3;
const HTTP_VALID_STATUS = 202;

function getPlatformData() {
  if (global.isPhone) {
    return { platform_id: PLATFORM_IDS.PHONE };
  }
  if (global.isTablet) {
    return { platform_id: PLATFORM_IDS.TABLET };
  }
  return { platform_id: PLATFORM_IDS.DESKTOP };
}

function getCookiesData() {
  const cookies = [
    COOKIES.UTM_SOURCE,
    COOKIES.UTM_MEDIUM,
    COOKIES.GOOGLE_CLICK_ID,
    COOKIES.MICROSOFT_CLICK_ID,
    '_ga',
  ];
  const nameMapping = {
    _ga: 'google_id',
    [COOKIES.MICROSOFT_CLICK_ID]: 'msclkid',
  };
  return cookies.reduce((result, value) => {
    const key = nameMapping[value] || value;
    result[key] = getCookieValue(value) || null;
    return result;
  }, {});
}

function getPageData(parsedURL) {
  return {
    origin_page_id: Number(ID_PAGES[getPageType(window.location)]) || null,
    origin_url: parsedURL.pathname,
  };
}

function getSearchCommonData(search) {
  return {
    city_search: search.name,
    country_search: (search.country || '').toLowerCase(),
    date_arrival: formatFromString(search.dateArrival, 'YYYY-MM-DD'),
    date_leaving: formatFromString(search.dateLeaving, 'YYYY-MM-DD'),
    guest_search: search.guestsNumber,
  };
}

async function send() {
  // do nothing
}

function getCancellationPolicyFromEstablishment(establishment) {
  if (!establishment.cancellationPolicy) return null;
  const cancellationPolicyPercentages = getSortedCancellationPoliciesPercentages(establishment.partners[0].cancellation_policy);
  return Number(cancellationPolicyPercentages[0]) || null;
}

function getOriginSearchCode(originPage, referrerLocation) {

  const routes = {
    contact: 8,
    establishment: 9,
    home: 2,
    landing: 3,
    notFound: 6,
    others: 0,
    privacy: 7,
    search: 1,
    subhomeFirst: 4,
    subhomeSecond: 4,
    tipologyFirst: 4,
    tipologySecond: 4,
    tipologyThird: 4,
    users: 5,
  };

  const originPageCode = Object.keys(routes).find(route => originPage.includes(route));
  if (!originPageCode) {
    const referrerPageCode = Object.keys(routes).find(route => referrerLocation.includes(route));
    if (!referrerPageCode) {
      return 0;
    }
    return routes[referrerPageCode];
  }
  return routes[originPageCode];
}

export function logVisit() {
  const { loggerReducer } = getState();

  if (loggerReducer.visit.id && Date.now() - loggerReducer.visit.timestamp < HALF_HOUR_IN_MILIS) {
    return null;
  }

  const { href } = window.location;
  const parsedURL = Url.parse(href, true);
  setCookie(COOKIES.ORIGIN_PAGE, ID_PAGES[getPageType(window.location)]);

  if (parsedURL.query) {
    const { query } = parsedURL;
    if (query.utm_source) {
      setCookie(COOKIES.UTM_SOURCE, parsedURL.query.utm_source);
    } else if (query.source) {
      setCookie(COOKIES.UTM_SOURCE, parsedURL.query.source);
    } else {
      deleteCookie(COOKIES.UTM_SOURCE);
    }
    if (query.utm_medium) {
      setCookie(COOKIES.UTM_MEDIUM, parsedURL.query.utm_medium);
    } else if (query.medium) {
      setCookie(COOKIES.UTM_MEDIUM, parsedURL.query.medium);
    } else {
      deleteCookie(COOKIES.UTM_MEDIUM);
    }
    if (query.gclid) {
      setCookie(COOKIES.GOOGLE_CLICK_ID, parsedURL.query.gclid, 90);
    }
    if (query.msclkid) {
      setCookie(COOKIES.MICROSOFT_CLICK_ID, parsedURL.query.msclkid, 90);
    }
  }

  const visitId = uuid4();
  const userId = loggerReducer.userId || uuid4();
  dispatch(storeUserId(userId));
  dispatch(storeVisitId(visitId));

  const data = {
    country: global.hr.intl.COUNTRY_CODE,
    device: navigator.userAgent,
    event_type: LOGGER_TYPES.VISIT,
    id: visitId,
    user_id: userId,
    ...getCookiesData(),
    ...getPageData(parsedURL),
    ...getPlatformData(),
  };

  data.gclid = data.gclid || '0';
  data.msclkid = data.msclkid || '0';

  send(data);
}

export function logPinmap(partnerId, transactionId) {
  const originPageId = ID_PAGES[getPageType(window.location)];
  const data = {
    click_page: Number(originPageId) || null,
    click_pos: null,
    event_type: LOGGER_TYPES.PINMAP,
    id: transactionId,
    partner_id: Number(partnerId) || null,
    search_id: getState().searchResultsReducer.loggerSearchId,
  };
  send(data);
}

export function logInline(clickPos, partnerId, transactionId) {
  const { authReducer, loggerReducer } = getState();
  const { href } = window.location;
  const parsedURL = Url.parse(href, true);

  const data = {
    click_page: Number(ID_PAGES[getPageType(window.location)]) || null,
    click_pos: Number(clickPos) || null,
    device: navigator.userAgent,
    event_type: LOGGER_TYPES.INLINE,
    id: transactionId,
    partner_id: Number(partnerId) || null,
    registered_user_id: authReducer.user.id || null,
    search_id: getState().searchResultsReducer.loggerSearchId,
    testab_id: global.experiment ? Number(global.experiment.id) : null,
    testab_option: global.experiment ? global.experiment.variant : null,
    user_id: loggerReducer.userId,
    utm_medium: getCookieValue(COOKIES.UTM_MEDIUM) || null,
    utm_source: getCookieValue(COOKIES.UTM_SOURCE) || null,
    visit_id: loggerReducer.visit.id,
    ...getCookiesData(),
    ...getPageData(parsedURL),
    ...getPlatformData(),
  };

  data.gclid = data.gclid || '0';
  data.msclkid = data.msclkid || '0';
  send(data);
}

export async function logSearch(originPage = []) {
  await logVisit();

  const currentLocation = window.location;
  const referrerLocation = Url.parse(document.referrer);
  const { authReducer, loggerReducer, searchApplied, searchFormReducer } = getState();

  const { place_id: placeId, polygon_id: polygonId } = searchApplied;
  const data = {
    country: global.hr.intl.COUNTRY_CODE,
    device: navigator.userAgent,
    event_type: LOGGER_TYPES.SEARCH,
    id: uuid4(),
    place_id: placeId,
    registered_user_id: authReducer.user.id || null,
    search_polygon: polygonId,
    section_id: (currentLocation.host === referrerLocation.host || !referrerLocation.host)
      ? getOriginSearchCode(originPage, getMatchingRoute(referrerLocation))
      : 0,
    subsection_id: searchFormReducer.subSection,
    user_id: loggerReducer.userId,
    visit_id: loggerReducer.visit.id,
    ...getSearchCommonData(searchApplied),
    ...getPlatformData(),
    ...getCookiesData(),
  };

  data.gclid = data.gclid || '0';
  data.msclkid = data.msclkid || '0';

  dispatch(storeLoggerSearchId(data.id));
  send(data);
}

export function logClickOut({ allPartners, cheapestPartner, clickPos, sectionId, subSectionId, id, partners, establishment, transactionId }) {
  const winner = allPartners[cheapestPartner];
  const eventValue = parseFloat(winner.cpc) || null;
  const { loggerReducer, searchResultsReducer } = getState();
  const { currentPage, loggerSearchId, recommendedEstablishments } = searchResultsReducer;
  const data = {
    cancellation_policy: getCancellationPolicyFromEstablishment(establishment),
    click_page: Number(currentPage) || null,
    click_pos: Number(clickPos),
    comments_number: Number(establishment.numberOfComments),
    discount: Number(establishment.discount),
    establishment_id: Number(id) || null,
    event_type: LOGGER_TYPES.CLICK,
    id: transactionId,
    instantly_booking: Boolean(partners.find(partner => partner.partner_id === cheapestPartner).instantly_booking),
    matching_number: Number(partners.length) || null,
    max_price: Number(establishment.maxDayPrice) || null,
    min_price: Number(establishment.minDayPrice) || null,
    partner_id: Number(cheapestPartner) || null,
    price_night: Number(partners.find(partner => partner.partner_id === cheapestPartner).price_per_day) || null,
    prices_number: Number(partners.filter(partner => !!partner.price_per_day).length) || null,
    property_type: establishment.category || null,
    rating: Number(establishment.rating) || null,
    recommendation: (recommendedEstablishments && recommendedEstablishments[establishment.id]) || null,
    revenue: eventValue,
    search_id: loggerSearchId,
    section_id: Number(sectionId),
    sentimental: Number(establishment.ranking),
    subsection_id: Number(subSectionId),
    visit_id: loggerReducer.visit.id,
  };

  send(data);
}

export function logCheckbox(checkbox) {
  const { loggerReducer } = getState();
  const data = {
    activated: true,
    country: global.hr.intl.COUNTRY_CODE,
    event_type: LOGGER_TYPES.CHECKBOX,
    id: checkbox.transactionId,
    partner_id: String(checkbox.id) || null,
    position: Number(checkbox.order) || null,
    revenue: parseFloat(checkbox.cpc),
    user_id: loggerReducer.userId,
    visit_id: loggerReducer.visit.id,
    ...getCookiesData(),
    ...getPlatformData(),
  };

  data.gclid = data.gclid || '0';
  data.msclkid = data.msclkid || '0';

  send(data);
}

export function loggerFilters(orderingId = null) {
  const state = getState();
  const {
    filtersApplied: {
      cancellationSelected,
      discountSelected,
      recommendationSelected,
      reviewsSelected,
      roomsSelected,
      orderingSelected,
    },
  } = state;
  const instantlyBooking = state.filtersApplied.availabilityFilterApplied.length === 1;
  const nights = getNumberOfNights(state.searchApplied.dateArrival, state.searchApplied.dateLeaving);
  const divider = state.filtersApplied.priceRange.type === 1 ? 1 : nights;
  const priceRange = state.filtersApplied.priceRange.applied.a || [null, null];
  const minPrice = priceRange[0] / divider;
  const maxPrice = priceRange[1] / divider;
  const searchId = state.searchResultsReducer.loggerSearchId;
  send({
    cancellation: cancellationSelected,
    event_type: LOGGER_TYPES.FILTERS,
    hot_deals: discountSelected,
    id: uuid4(),
    instantly_booking: instantlyBooking,
    max_price: maxPrice || null,
    min_price: minPrice || null,
    ordering_id: orderingId || orderingSelected,
    recommendation: recommendationSelected,
    review: reviewsSelected,
    rooms: roomsSelected || null,
    search_id: searchId,
  });
}

export function logClickin(section, subsection, position, establishmentId) {
  const { establishments, searchResultsReducer } = getState();
  const establishment = establishments[establishmentId];
  const clickId = uuid4();
  const data = {
    cancellation_policy: getCancellationPolicyFromEstablishment(establishment),
    click_page: searchResultsReducer.currentPage || 1,
    click_pos: position,
    discount: Number(establishment.discount) || null,
    establishment_id: establishment.id,
    event_type: LOGGER_TYPES.CLICKIN,
    id: clickId,
    instantly_booking: !!establishment.availability,
    partner_id: establishment.cheapestPartner,
    price_night: establishment.minDayPrice,
    recommendation: searchResultsReducer.recommendedEstablishments[establishment.id] || null,
    search_id: searchResultsReducer.loggerSearchId,
    section_id: section,
    sentimental: null,
    subsection_clickin_id: subsection,
  };

  data.gclid = data.gclid || '0';
  data.msclkid = data.msclkid || '0';

  send(data);
}

export function logKeywords(keywords) {
  const { loggerReducer } = getState();
  if (!loggerReducer.visit.id) return null;
  keywords.forEach((keyword) => {
    send({
      event_type: LOGGER_TYPES.KEYWORDS,
      id: uuid4(),
      keyword,
      visit_id: loggerReducer.visit.id,
    });
  });
}

export function logFavourite(establishmentId, index = null) {
  const state = getState();
  const establishment = state.establishments[establishmentId];
  const searchId = state.searchResultsReducer.loggerSearchId;
  if (!searchId || !establishment) return null;
  send({
    click_pos: index,
    establishment_id: establishmentId,
    event_type: LOGGER_TYPES.FAVOURITES,
    id: uuid4(),
    partner_id: establishment.partners && establishment.partners[0] ? establishment.partners[0].partner_id : null,
    price_night: establishment.partners && establishment.partners[0] ? establishment.partners[0].price_per_day : null,
    recommendation: establishment.recommendation,
    search_id: searchId,
  });
}

function logSingleLead(params, isMulti, phoneCode) {
  const { establishments, searchResultsReducer } = getState();
  const { searchId } = searchResultsReducer.Url.parse(window.location.href, true).query;
  const data = {
    date_arrival: formatFromString(params.date_arrival, 'DD-MM-YYYY', 'DD/MM/YYYY'),
    date_leaving: formatFromString(params.date_leaving, 'DD-MM-YYYY', 'DD/MM/YYYY'),
    email: params.email,
    establishment_id: params.establishment_id,
    event_type: LOGGER_TYPES.LEADS,
    guests: params.guests_number,
    id: uuid4(),
    is_multilead: isMulti,
    name: `${params.name} ${params.surname}`,
    phone: phoneCode + params.phone,
    price_night: establishments[params.establishment_id].minDayPrice,
    search_id: searchId,
  };
  send(data);
}

export function logLeads(params, isMulti, countries) {
  const displayCountry = countries.filter(element => element.key === params.country)[0].display;
  const phoneCode = /\((\+[0-9]{2,3})\)/.exec(displayCountry)[1];
  if (isMulti) {
    params.establishment_ids.split(',').forEach(establishmentId => logSingleLead({ ...params, establishment_id: parseInt(establishmentId, 10) }, isMulti, phoneCode));
  } else {
    logSingleLead(params, isMulti, phoneCode);
  }
}

export function logSharing(establishmentId, shareType, clickPos = -1) {
  const state = getState();
  const establishment = state.establishments[establishmentId];
  const searchId = state.searchResultsReducer.loggerSearchId;
  if (!establishment) return null;
  send({
    click_pos: clickPos,
    establishment_id: establishmentId,
    event_type: LOGGER_TYPES.SHARE_ESTABLISHMENT,
    id: uuid4(),
    partner_id: establishment.partners && establishment.partners[0] ? establishment.partners[0].partner_id : null,
    search_id: searchId,
    share_type: shareType,
  });
}

export function logSuggestionbox(ad) {
  const { loggerReducer } = getState();
  const { id, transactionId } = ad;
  send({
    event_type: LOGGER_TYPES.SUGGESTIONBOX,
    id: transactionId,
    partner_id: Number(id) || null,
    source_page_id: Number(ID_PAGES[getPageType(window.location)]) || null,
    visit_id: loggerReducer.visit.id,
    ...getPlatformData(),
  });
}
