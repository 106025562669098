import { LAYERS, MODALS } from 'constants/constants';
import { MODALS_ACTIONS } from 'constants/actions';

const getLayer = (layers, layerName) => layers[layerName];
const areModalsInLayer = layer => layer.length;
const getCurrentModalInLayer = layer => layer[layer.length - 1];

export function showModal(name, props = {}) {
  return (dispatch, getState) => {
    if (name === MODALS.NOTIFICATION) {
      const _layers = JSON.parse(JSON.stringify(getState().modals.layers)); // eslint-disable-line
      const notificationLayer = getLayer(_layers, LAYERS.NOTIFICATIONS);
      if (areModalsInLayer(notificationLayer)) {
        dispatch(hideModal(getCurrentModalInLayer(notificationLayer)));
      }
    }

    return dispatch({
      name,
      props,
      type: MODALS_ACTIONS.SHOW_MODAL,
    });
  };
}

export function hideModal(modal) {
  return dispatch => dispatch({
    modal,
    type: MODALS_ACTIONS.HIDE_MODAL,
  });
}

export function hideModalByName(modal) {
  return dispatch => dispatch({
    modal,
    type: MODALS_ACTIONS.HIDE_MODAL_BY_NAME,
  });
}
