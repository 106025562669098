import PropTypes from 'prop-types';
import React from 'react';


export default function Steps({ className, title, subtitle, textFirstStep, textSecondStep, textThirdStep, descriptionFirstStep, descriptionSecondStep, descriptionThirdStep }) {
  return (
    <section className={ `${className} steps` }>
      <p className="steps__title">
        { `${title}` }
      </p>
      { subtitle
        && (
          <p className="steps__subtitle">
            { subtitle }
          </p>
        )}
      <ul className="steps__list">
        <li className="steps__list__item">
          <span className="steps__list__item__image">
            <span
              className="steps__list__item__image__first"
            />
          </span>
          <p className="steps__list__item__text">
            { textFirstStep }
          </p>
          { descriptionFirstStep
          && (
            <p className="steps__list__item__description">
              { descriptionFirstStep }
            </p>
          )
          }
        </li>
        <span className="steps__list__line" />
        <li className="steps__list__item">
          <span className="steps__list__item__image">
            <span
              className="steps__list__item__image__second"
            />
          </span>
          <p className="steps__list__item__text" dangerouslySetInnerHTML={ { __html: textSecondStep } } />
          { descriptionSecondStep
          && (
            <p className="steps__list__item__description">
              { descriptionSecondStep }
            </p>
          )
          }
        </li>
        <span className="steps__list__line" />
        <li className="steps__list__item">
          <span className="steps__list__item__image">
            <span
              className="steps__list__item__image__third"
            />
          </span>
          <p className="steps__list__item__text">
            { textThirdStep }
          </p>
          { descriptionThirdStep
          && (
            <p className="steps__list__item__description">
              { descriptionThirdStep }
            </p>
          )
          }
        </li>
      </ul>
    </section>
  );
}

Steps.defaultProps = {
  className: '',
  descriptionFirstStep: null,
  descriptionSecondStep: null,
  descriptionThirdStep: null,
  subtitle: '',
  title: '',
};

Steps.propTypes = {
  className: PropTypes.string,
  descriptionFirstStep: PropTypes.string,
  descriptionSecondStep: PropTypes.string,
  descriptionThirdStep: PropTypes.string,
  subtitle: PropTypes.string,
  textFirstStep: PropTypes.string.isRequired,
  textSecondStep: PropTypes.string.isRequired,
  textThirdStep: PropTypes.string.isRequired,
  title: PropTypes.string,
};
