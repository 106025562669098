import destination, * as fromDestination from 'reducers/destination';
import establishmentDetails, * as fromDetails from 'reducers/establishment-details';
import establishments, * as fromEstablishments from 'reducers/establishments';
import recentlyViewedReducer, * as fromRecentlyViewed from 'reducers/recentlyViewedReducer';
import searchFormReducer, * as fromSearchForm from 'reducers/searchFormReducer';
import searchResultsReducer, * as fromSearchResults from 'reducers/searchResultsReducer';
import wishlistReducer, * as fromWishlist from 'reducers/wishlistReducer';
import { adsReducer } from 'reducers/adsReducer';
import ApiJs from 'service-client-js';
import appReducer from 'reducers/appReducer';
import { authReducer } from 'reducers/authReducer';
import { combineReducers } from 'redux';
import { filtersApplied } from 'reducers/filters-applied';
import { filtersReducer } from 'reducers/filtersReducer';
import { landing } from 'reducers/landing';
import { locationReducer } from 'reducers/locationReducer';
import loggerReducer from 'reducers/logger-reducer';
import map from 'reducers/map';
import { modals } from 'reducers/modals';
import { recentSearchesReducer } from 'reducers/recentSearchesReducer';
import { searchApplied } from 'reducers/search-applied';
import { STANDALONE_MODE } from 'constants/constants';


const { CANCELLATION_POLICY } = ApiJs;

export default combineReducers({
  adsReducer,
  appReducer,
  authReducer,
  destination,
  establishmentDetails,
  establishments,
  filtersApplied,
  filtersReducer,
  landing,
  locationReducer,
  loggerReducer,
  map,
  modals,
  recentlyViewedReducer,
  recentSearchesReducer,
  searchApplied,
  searchFormReducer,
  searchResultsReducer,
  wishlistReducer,
});

export function getAreAnySearchResults(state) {
  return fromSearchResults.getAreAnySearchResults(state.searchResultsReducer);
}

export function getVisibleEstablishmentsNumber(state) {
  return fromSearchResults.getVisibleEstablishmentsNumber(state.searchResultsReducer);
}

export function getVisibleEstablishmentsNumPages(state, pageSize) {
  return fromSearchResults.getVisibleEstablishmentsNumPages(state.searchResultsReducer, pageSize);
}

export function getIsEstablishmentInCurrentPage(state, id, pageSize) {
  return fromSearchResults.getIsEstablishmentInCurrentPage(state.searchResultsReducer, id, pageSize);
}

export function getPricePrediction(state) {
  return fromSearchResults.getPricePrediction(state.searchResultsReducer, getSearchApplied(state).dateArrival);
}

export function getEstablishmentById(state, id) {
  return fromEstablishments.getEstablishmentById(state.establishments, id);
}

export function isFavouriteEstablishment(state, id) {
  return fromWishlist.isFavouriteEstablishment(state.wishlistReducer, id);
}

export function getFavouriteEstablishmentsNumber(state) {
  return fromWishlist.getFavouriteEstablishmentsNumber(state.wishlistReducer);
}

export function getFavouriteEstablishmentIds(state) {
  return fromWishlist.getFavouriteEstablishmentIds(state.wishlistReducer);
}

export function getFavouritesPage(state) {
  return fromWishlist.getFavouritesPage(state.wishlistReducer);
}

export function getIsProcessingFavourite(state, establishmentId) {
  return fromWishlist.getIsProcessingFavourite(state.wishlistReducer, establishmentId);
}

export function getRecentlyViewedEstablishmentsNumber(state) {
  return fromRecentlyViewed.getRecentlyViewedEstablishmentsNumber(state.recentlyViewedReducer);
}

export function getRecentlyViewedEstablishmentIds(state) {
  return fromRecentlyViewed.getRecentlyViewedEstablishmentIds(state.recentlyViewedReducer);
}

export function getDestinationName(state) {
  return fromDestination.getDestinationName(state.destination);
}

export function isFormOk(state) {
  return fromDestination.isDestinationOk(state.destination) && fromSearchForm.isFormOk(state.searchFormReducer);
}

export function getSearch(state) {
  return Object.assign({}, state.searchFormReducer, state.destination);
}

export function getSearchApplied(state) {
  return state.searchApplied;
}

export function getSimilarEstablishments(state, id) {
  const similars = fromDetails.getSimilarEstablishments(state.establishmentDetails, id);

  if (!similars || !similars.length) return similars;

  return similars.map(similarId => getEstablishmentById(state, similarId))
    .filter(similar => typeof similar !== 'undefined');
}

export function hasRecommendation(state) {
  return !!Object.keys(state.searchResultsReducer.recommendedEstablishments || {}).length;
}

export function hasFreeCancellation(state) {
  return Object.keys(state.establishments).some((id) => {
    const haveCancellation = getCancellationPolicy(state, id);
    return haveCancellation && haveCancellation.has100PercentCancellationPolicy;
  });
}

export function isProgressiveWebApp(state) {
  return state.appReducer.mode === STANDALONE_MODE;
}

export function getCancellationPolicy(state, id) {
  const establishment = getEstablishmentById(state, id);
  if (!establishment || !establishment.cancellationPolicy) return false;
  return {
    has100PercentCancellationPolicy: establishment.cancellationPolicy === CANCELLATION_POLICY.FULL,
  };
}
