import { HALF_HOUR_IN_DAYS, setCookie } from 'helpers/cookies-helper';
import Checkbox from 'atoms/checkbox';
import { checkboxInterface } from 'constants/interfaces';
import { COOKIES } from 'constants/constants';
import PropTypes from 'prop-types';
import React from 'react';


function changeCheckboxAndSetCookie(adId, checked, callback) {
  setCookie(COOKIES.CHECKED_CHECKBOX, checked, HALF_HOUR_IN_DAYS);
  callback(adId, checked);
}

export default function AdCheckboxes({ adCheckboxes, className, isOnCalendar, handleChange, t }) {
  const hide = !adCheckboxes.length;
  return (
    <div className={ `search-bar-molecule__bottom__checkboxes ${hide ? '-is-empty' : ''} ${className}` }>
      { hide
        ? null
        : (
          <p className={ `search-bar-molecule__bottom__checkboxes__title ${className}` }>
            { `${t('See more results on')}:` }
          </p>
        )
      }
      { hide
        ? null
        : adCheckboxes.map(ad => (
          <Checkbox
            key={ `search-bar-molecule__bottom__checkboxes__item-${ad.id}` }
            checked={ ad.checked }
            className={ `search-bar-molecule__bottom__checkboxes__item ${ad.checked ? '-is-checked' : '-not-checked'} ${className}` }
            handleChange={ evt => changeCheckboxAndSetCookie(ad.id, evt.currentTarget.checked, handleChange) }
            id={ `search-bar-molecule__bottom__checkboxes__item-${ad.id}` }
            isOnCalendar={ isOnCalendar }
            text={ ad.visibleName }
            textPosition="right"
          />
        ))
      }
    </div>
  );
}

AdCheckboxes.defaultProps = {
  adCheckboxes: [],
  className: '',
  isOnCalendar: false,
};

AdCheckboxes.propTypes = {
  adCheckboxes: PropTypes.arrayOf(checkboxInterface),
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  isOnCalendar: PropTypes.bool,
  t: PropTypes.func.isRequired,
};
