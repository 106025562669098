import { filterInteraction, resetFilters, selectEstablishmentType, selectService, setPriceRange, updateFilters } from 'actions/filtersActions';
import { getFavouriteEstablishmentsNumber, getRecentlyViewedEstablishmentsNumber } from 'reducers/main';
import { getUserExtraInfo, logout } from 'actions/authActions';
import { isInFavourites, isInPersonalArea } from 'reducers/locationReducer';
import NavigationBar, { NAVIGATION_BAR_ACTIONS } from 'atoms/navigation-bar';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { changeActualPage } from 'actions/locationActions';
import { connect } from 'react-redux';
import { getCurrentRoute } from 'app/route-container';
import { getSliderRanges } from 'reducers/filtersReducer';
import Header from 'molecules/header';
import { hideFilters } from 'helpers/filters';
import { ID_ROUTES } from 'constants/routes';
import { MENUS } from 'constants/constants';
import PropTypes from 'prop-types';
import { showModal } from 'actions/modals';
import { trackHeader } from 'services/tracking';


const ROUTES_WITHOUT_BACK = [ID_ROUTES.HOME];

function hasBack() {
  if (typeof window === 'undefined') return false;
  return !ROUTES_WITHOUT_BACK.includes(getCurrentRoute()) && window.history.state;
}

export class HeaderContainer extends Component {
  constructor(props) {
    super(props);
    this.hideFilters = hideFilters.bind(this);
  }

  componentWillMount() {
    const { userIsAuthenticated, isExtraInfoFetched, getUserExtraInfoAction } = this.props;
    if (userIsAuthenticated && !isExtraInfoFetched) getUserExtraInfoAction();
  }

  render() {
    const { className, showModalAction, userIsAuthenticated, hasSearchBar, hasSeoLinks, visitedCount, favouritesCount, showMap, headerSeoLinks, filterInteractionResult } = this.props;

    function handleOnClick(e, key) {
      switch (key) {
        default:
          break;
      }
    }

    function handleOnClickMobile(type) {
      const { changeActualPageAction } = this.props;
      const currentRoute = getCurrentRoute();
      const mapActualToTracking = {
        ESTABLISHMENT: 'page-establishment',
        HOME: 'home',
        SEARCH: { MAP: 'map', SERP: 'serp' },
        USERS: 'personal-area',
      };

      let actual;
      if (currentRoute === 'SEARCH') {
        actual = showMap
          ? mapActualToTracking.SEARCH.MAP
          : mapActualToTracking.SEARCH.SERP;
      } else { actual = mapActualToTracking[currentRoute]; }

      switch (type) {

        case NAVIGATION_BAR_ACTIONS.BACK:
          browserHistory.goBack();
          return trackHeader(actual, 'back');

        case NAVIGATION_BAR_ACTIONS.HOME:
          changeActualPageAction(ID_ROUTES.HOME);
          browserHistory.push('/');
          return trackHeader(actual, 'home');

        default:
          return null;
      }
    }

    let seoLinks = null;
    if (headerSeoLinks) seoLinks = headerSeoLinks;
    if (typeof window !== 'undefined') seoLinks = global.hr.intl.HEADER_SEO_LINKS;
    const { inFavourites, inPersonalArea, filtersApplied } = this.props;

    return (
      <div>
        <NavigationBar
          className={ className }
          favouritesCount={ favouritesCount }
          handleOnClick={ handleOnClickMobile.bind(this) }
          hasBack={ !!hasBack() }
          inFavourites={ inFavourites }
          inPersonalArea={ inPersonalArea }
          isLogged={ userIsAuthenticated }
        />
        <Header
          className={ className }
          favouritesCount={ favouritesCount }
          filterInteractionResult={ filterInteractionResult }
          filtersApplied={ filtersApplied }
          handleOnClick={ handleOnClick }
          hasSearchBar={ hasSearchBar }
          hasSeoLinks={ hasSeoLinks }
          hideFilters={ e => this.hideFilters(e) }
          selectEstablishmentType={ selectEstablishmentType }
          seoLinks={ seoLinks }
          showHeaderMenu={ !showMap }
          showPublishAd={ getCurrentRoute() === ID_ROUTES.HOME }
          userIsAuthenticated={ userIsAuthenticated }
          visitedCount={ visitedCount }
        />
      </div>
    );
  }
}

HeaderContainer.defaultProps = {
  className: '',
  filterInteractionResult: '',
  filtersApplied: {},
  hasSearchBar: null,
  hasSeoLinks: null,
  headerSeoLinks: null,
};

HeaderContainer.propTypes = {
  changeActualPageAction: PropTypes.func.isRequired,
  className: PropTypes.string,
  favouritesCount: PropTypes.number.isRequired,
  filterInteractionResult: PropTypes.string,
  filtersApplied: PropTypes.shape(),
  getUserExtraInfoAction: PropTypes.func.isRequired,
  hasSearchBar: PropTypes.bool,
  hasSeoLinks: PropTypes.bool,
  headerSeoLinks: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  })),
  inFavourites: PropTypes.bool.isRequired,
  inPersonalArea: PropTypes.bool.isRequired,
  isExtraInfoFetched: PropTypes.bool.isRequired,
  showMap: PropTypes.bool.isRequired,
  showModalAction: PropTypes.func.isRequired,
  userIsAuthenticated: PropTypes.bool.isRequired,
  visitedCount: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    favouritesCount: getFavouriteEstablishmentsNumber(state),
    filterInteractionResult: state.filtersReducer.filterInteraction,
    filtersApplied: state.filtersApplied,
    inFavourites: isInFavourites(state.locationReducer.actualPage),
    inPersonalArea: isInPersonalArea(state.locationReducer.actualPage),
    isExtraInfoFetched: state.authReducer.extraInfoFetched,
    showMap: state.searchResultsReducer.showMap,
    sliderRange: getSliderRanges(state),
    userIsAuthenticated: state.authReducer.authenticated,
    visitedCount: getRecentlyViewedEstablishmentsNumber(state),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeActualPageAction: changeActualPage,
    filterInteraction,
    getUserExtraInfoAction: getUserExtraInfo,
    logoutAction: logout,
    resetFiltersAction: resetFilters,
    selectEstablishmentType,
    selectServiceAction: selectService,
    setPriceRange,
    showModalAction: showModal,
    updateFiltersAction: updateFilters,
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
