import { COUNTRY_PARAMS, MACROS } from 'services/AdService/constants';


export const inlineAds = {
  airbnb: {
    active: true,
    clickout: MACROS.INLINE.AIRBNB_PREMIUM_POSITION,
    color: '#FF5A5F',
    countryParams: COUNTRY_PARAMS.INLINE.AIRBNB,
    dynamicUrl: true,
    id: '9',
    name: 'Airbnb',
    order: 1,
    visibleName: 'Airbnb',
  },
  airbnbSeo: {
    active: true,
    clickout: MACROS.INLINE.AIRBNB_SLP,
    color: '#FF5A5F',
    countryParams: COUNTRY_PARAMS.INLINE.AIRBNB,
    dynamicUrl: true,
    id: '9',
    name: 'Airbnb',
    order: 1,
    visibleName: 'Airbnb',
  },
};

export const checkboxAds = {
  airbnb: {
    clickout: MACROS.CHECKBOX.AIRBNB,
    countryParams: COUNTRY_PARAMS.CHECKBOX.AIRBNB,
    dynamicUrl: true,
    id: '9',
    name: 'Airbnb',
    visibleName: 'Airbnb',
  },
};

export const pinmapAds = {
  airbnb: {
    active: true,
    clickout: MACROS.PINMAP.AIRBNB,
    countryParams: COUNTRY_PARAMS.PINMAP.AIRBNB,
    dynamicUrl: true,
    id: '9',
    name: 'Airbnb',
    order: 1,
    restrictions: [],
    visibleName: 'Airbnb',
  },
};

export const suggestionBoxAds = {
  airbnb: {
    active: true,
    clickout: MACROS.SUGGESTIONBOX.AIRBNB,
    countryParams: COUNTRY_PARAMS.SUGGESTIONBOX.AIRBNB,
    dynamicUrl: true,
    fallback: MACROS.SUGGESTIONBOX.AIRBNB_FALLBACK,
    id: '9',
    name: 'Airbnb',
    order: 1,
    restrictions: [],
    visibleName: 'Airbnb',
  },
};
