import { getURIS } from 'constants/constants';


const BASE_URL = getURIS().PREDICTOR;

export default class PredictionService {

  static forPlace(country, lat, lng, radius) {
    const upperCountry = typeof country === 'string' ? country.toUpperCase() : country;
    const url = `${BASE_URL}/1.0/predict_price_per_day?country=${upperCountry}&lat=${lat}&lng=${lng}&radius=${radius}`;
    return new PredictionService(url);
  }

  constructor(resourcePath) {
    this.path = resourcePath;
  }

  getCheapestDays() {}

  getPredictionPrice() {}

}
