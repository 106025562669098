import React from 'react';


export default function Partners() {
  return (
    <section className="home__body__partners">
      <div className="home__body__partners__scroll" />
      <div className="home__body__partners-container">
        <span className="home__body__partners__line" />
        <div className="home__body__partners__image" />
        <span className="home__body__partners__line" />
      </div>
    </section>
  );
}
