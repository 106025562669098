import PropTypes from 'prop-types';
import React from 'react';
import SelectCountry from 'molecules/language-currency';


export default function International({ t, domainCulture }) {
  return (
    <li className="footer-molecule__first-footer__links__international">
      <ul className="footer-molecule__first-footer__links__international-container">
        <p className="footer-molecule__first-footer__links__international__title">
          { t('Hundredrooms international') }
        </p>
        <p className="footer-molecule__first-footer__links__international__subtext">
          { t('Select the country of origin that you want here.') }
        </p>
        <div id="language-currency">
          <SelectCountry
            culture={ domainCulture }
            t={ t }
          />
        </div>
      </ul>
    </li>
  );
}

International.propTypes = {
  domainCulture: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
