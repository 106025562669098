/* eslint-disable no-case-declarations */
import { SEARCH_RESULTS_ACTIONS } from 'constants/actions';


const resultsPageInitialState = {};

export default function establishments(state = resultsPageInitialState, action) {
  switch (action.type) {
    case SEARCH_RESULTS_ACTIONS.RESET_RESULTS_PAGE:
    case SEARCH_RESULTS_ACTIONS.FETCHING_ESTABLISHMENTS_IN_AREA:
      return action.establishment || resultsPageInitialState;
    case SEARCH_RESULTS_ACTIONS.RECEIVE_ESTABLISHMENTS:
      if (Object.keys(action.establishments).length === 1) {
        const establishmentId = Object.keys(state)[0];
        const licence = state[establishmentId] ? state[establishmentId].licence : null;
        const establishment = Object.assign({}, state, action.establishments);
        if (establishment[establishmentId]) establishment[establishmentId].licence = licence;
        return establishment;
      }
      return Object.assign({}, state, action.establishments);
    default:
      return state;
  }
}

export function getEstablishmentById(state, id) {
  return state[id];
}
