import moment from 'moment';

export function isSameDate(firstDateString, otherDateString, format = null) {
  return moment(firstDateString, format).isSame(otherDateString, 'day');
}

export function isSameOrAfterDate(firstDateString, otherDateString = moment(), format = null) {
  return moment(firstDateString, format).isSameOrAfter(otherDateString, 'day');
}

export function formatFromString(stringDate, format, formatIn = null, convertToUTC = false) {
  if (convertToUTC) return moment.utc(stringDate, formatIn).format(format);
  return moment(stringDate, formatIn).format(format);
}

export function parseToMoment(stringDate, format) {
  return moment(stringDate, format);
}

export function getNumberOfNights(dateArrival, dateLeaving) {
  return moment(dateLeaving).diff(dateArrival, 'days');
}

export function addNights(dateArrival, nights) {
  return moment(dateArrival).add(nights, 'days');
}

export const addHours = (date, format, hours) => moment(date, format).add(hours, 'hours');

export function defaultWeekend(datesFormat) {
  const date = moment().isoWeekday();
  let start = 0;
  let end = 0;

  if (date < 4) start = moment().add((5 - date), 'd');
  else start = moment().add((5 - date) + 7, 'd');

  end = moment(start).add(2, 'd');
  switch (datesFormat) {
    case 'slash':
      return [start.format('DD/MM/YYYY'), end.format('DD/MM/YYYY')];
    case 'point':
      return [start.format('DD.MM.YYYY'), end.format('DD.MM.YYYY')];
    case 'dash':
      return [start.format('DD-MM-YYYY'), end.format('DD-MM-YYYY')];
    default:
      return [start.format(datesFormat), end.format(datesFormat)];
  }
}
