import { browserHistory } from 'react-router';
import { dispatch } from 'services/store';
import { getMatchingRoute } from 'actions/searchFormActions';
import { MODALS } from 'constants/constants';
import { recoverFavourite } from 'actions/wishlistActions';
import { showModal } from 'actions/modals';
import { t } from 'services/Intl';


const isCurrentRouteUserRoute = () => {
  const { users } = global.hr.intl.ROUTES;
  const currentRoute = getMatchingRoute(document.location);
  return currentRoute.some(route => global.hr.intl.ROUTES[route] === users);
};

export function dispatchNotification(props) {
  if (!isCurrentRouteUserRoute()) return Promise.resolve(false);
  return Promise.resolve(dispatch(showModal(MODALS.NOTIFICATION, props)));
}

export function dispatchMapNotification(props) {
  return Promise.resolve(dispatch(showModal(MODALS.NOTIFICATION, { ...props, className: 'map-notification' })));
}

export function addedFavouriteNotification() {
  return dispatchNotification({
    title: t("The establishment has been added correctly to your favourites."),
    buttons: [
      {
        type: 'primary',
        text: t("See my favourites"),
        handleClick: (closeCallback) => {
          const { users, mylist } = global.hr.intl.ROUTES;
          browserHistory.push(`/${users}/${mylist}`);
          closeCallback();
        },
      },
    ],
  });
}

export function deleteUserNotification() {
  return dispatchNotification({ title: t('Your account has been deleted.') });
}

export function addedFavouriteMapNotification() {
  return dispatchMapNotification({
    title: t("Added to favourites. You can find this holiday rental in your personal area"),
  });
}

export function removeEstablishmentMapNotification() {
  return dispatchMapNotification({
    title: t("You have removed this holiday rental from this search."),
  });
}

export function deletedFavouriteNotification(establishmentId) {
  return dispatchNotification({
    title: t("Establishment removed from favourites."),
    buttons: [
      {
        type: 'primary',
        text: t("Undo"),
        handleClick: (closeCallback) => {
          dispatch(recoverFavourite(establishmentId)).then(closeCallback);
        },
      },
    ],
  });
}

export function passwordSuccessNotification() {
  return dispatchNotification({
    title: t("Changes saved succesfully."),
  });
}
