import { LAYERS, MENUS, MODALS } from 'constants/constants';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import { generateModalUrl } from 'helpers/urlGenerator';
import { getContext } from 'services/context';
import { hideModal } from 'actions/modals';
import LazilyLoad from 'services/lazy-loading';
import Modal from 'components/modal/new-modal/modal';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';


const moduleList = {
  compatibility: () => new Promise(resolve => require(['../compatibility-modal'], module => resolve(module))),
  dialog: () => new Promise(resolve => require(['../../../organisms/confirm-modal'], module => resolve(module))),
  filters: () => new Promise(resolve => require(['../../../organisms/filters/container'], module => resolve(module))),
  leads: () => new Promise(resolve => require(['../../../molecules/leads-form/container'], module => resolve(module))),
  login: () => new Promise(resolve => require(['../../../organisms/login-modal/container'], module => resolve(module))),
  menuMobile: () => new Promise(resolve => require(['../../../molecules/menu-mobile/container'], module => resolve(module))),
  notification: () => new Promise(resolve => require(['../../../molecules/notification-modal'], module => resolve(module))),
  policy: () => new Promise(resolve => require(['../../../molecules/policy-modal'], module => resolve(module))),
  search: () => new Promise(resolve => require(['../search-modal'], module => resolve(module))),
};

const loader = module => (modalProps, closeHandler) => (
  <LazilyLoad handleClose={ closeHandler } { ...modalProps } module={ module } />
);

const excludeModalsFromRouting = [MODALS.NOTIFICATION, MENUS.USER];
let excludeRoutesFromRouting = [];

export class ModalContainer extends Component {

  componentWillMount() {
    const { routes } = this.props;
    excludeRoutesFromRouting = [
      routes.blog,
      routes.establishment,
      routes.partnerFirst,
      routes.partnerFourth,
      routes.partnerSecond,
      routes.partnerThird,
      routes.tipologyFirst,
      routes.tipologySecond,
      routes.tipologyThird,
    ];
  }

  needToOpenModalOnUrl(modal) {
    return modal.name
      && modal.name !== MODALS.ESTABLISHMENT
      && !excludeModalsFromRouting.includes(modal.name)
      && !excludeRoutesFromRouting.includes(window.location.pathname.split('/')[1]);
  }

  needToGoBackOnCloseModal(modal) {
    return !excludeModalsFromRouting.includes(modal.name)
      && !excludeRoutesFromRouting.includes(window.location.pathname.split('/')[1]);
  }

  render() {
    const { handleClose, modalLayers } = this.props;
    const modalsConfig = {
      props: {
        contentClass: '',
      },
      [MODALS.SHARE]: {
        component: loader(moduleList.share),
      },
      [MODALS.COMPATIBILITY]: {
        component: loader(moduleList.compatibility),
        props: {
          contentClass: 'compatibility-modal',
          isClosable: false,
        },
      },
      [MODALS.LOGIN]: {
        component: loader(moduleList.login),
      },
      [MODALS.POLICY]: {
        component: loader(moduleList.policy),
      },
      [MODALS.LEADS_FORM]: {
        component: loader(moduleList.leads),
        props: {
          isLongModal: true,
          showClose: false,
        },
      },
      [MODALS.ESTABLISHMENT]: {
        component: loader(moduleList.establishment),
        props: {
          isLongModal: true,
          showClose: false,
        },
      },
      [MODALS.ESTABLISHMENT_SHARE]: {
        component: loader(moduleList.share),
      },
      [MENUS.USER]: {
        component: loader(moduleList.menuMobile),
        props: {
          showClose: false,
        },
      },
      [MENUS.FILTERS]: {
        component: loader(moduleList.filters),
        props: {
          showClose: false,
        },
      },
      [MODALS.SEARCH_MOBILE]: {
        component: loader(moduleList.search),
        props: {
          isClosable: false,
          showClose: false,
        },
      },
      [MODALS.NOTIFICATION]: {
        component: loader(moduleList.notification),
        props: {
          contentClass: 'notification-modal',
          isClosable: false,
          showClose: false,
        },
      },
      [MODALS.PUSH]: {
        component: loader(moduleList.push),
        props: {
          isClosable: false,
          showClose: false,
        },
      },
    };

    const layersWithoutNotifications = Object.keys(modalLayers)
      .filter(layer => layer !== LAYERS.NOTIFICATIONS)
      .map((layer) => {
        const currentLayer = modalLayers[layer];
        return currentLayer[currentLayer.length - 1] || null;
      })
      .filter(layer => layer !== null);

    if (typeof window !== typeof undefined) {
      const isShown = layersWithoutNotifications.length;
      if (isShown) {
        document.querySelector('body').classList.add('no-scroll');
      } else {
        document.querySelector('body').classList.remove('no-scroll');
      }
    }

    let returned = false;
    const layers = Object.keys(modalLayers).map((layer) => {
      const currentLayer = modalLayers[layer];
      if (currentLayer.length === 0) {
        return null;
      }

      const modal = currentLayer[currentLayer.length - 1] || null;
      const handleBrowserHistoryClose = () => {
        if (this.needToGoBackOnCloseModal(modal) && !returned) {
          returned = true;
          browserHistory.goBack();
        }
        handleClose.call(this, modal);
      };

      if (this.needToOpenModalOnUrl(modal)) {
        const [url, push] = generateModalUrl(location.search, modal.name);
        if (push) browserHistory.push(`${location.pathname}${url}`);
      }

      return (
        <Modal
          key={ `MODAL${layer}` }
          handleClose={ handleBrowserHistoryClose }
          layerId={ `${layer.toLowerCase()}_layer` }
          wrapperClass={ `${modal.name}-container-modal` }
          { ...modalsConfig[modal.name].props }
        >
          { modalsConfig[modal.name].component(modal.props, handleBrowserHistoryClose) }
        </Modal>
      );
    });

    return (
      <ReactCSSTransitionGroup
        id="modalsLayer"
        transitionEnterTimeout={ 500 }
        transitionLeaveTimeout={ 500 }
        transitionName="-animation"
      >
        { layers }
      </ReactCSSTransitionGroup>
    );
  }
}

ModalContainer.defaultProps = {
  modalName: null,
};

ModalContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  modalLayers: PropTypes.shape().isRequired,
  modalName: PropTypes.string,
  modalProps: PropTypes.shape().isRequired,
  routes: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalLayers: state.modals.layers,
    modalName: state.modals.name,
    modalProps: state.modals.props,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign({}, { handleClose: hideModal }),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(getContext(ModalContainer));
