import Claim from 'atoms/claim';
import { getContext } from 'services/context';
import Partners from 'templates/home/partners';
import PropTypes from 'prop-types';
import React from 'react';
import SearchBarContainer from 'molecules/search-bar/container';
import Steps from 'atoms/steps';


function Home({ t }) {
  return (
    <div className="home">
      <main className="home__body home-version-new">
        <article className="home__body-container">
          <Claim
            className="home__body__claim-mobile home-version-new"
            subtitle={ t('Discover, save, enjoy') }
            title={ t('We compare millions of holiday rentals to find the best price for you.') }
          />
          <SearchBarContainer
            className="home__body__search-bar -show-color-box"
          />
          { <Partners /> }
          <Steps
            className="home__body__steps home-version-new"
            subtitle={ t('Travel and feel like home anywhere you are') }
            textFirstStep={ t('Different prices for the same accommodation') }
            textSecondStep={ t('We compare holiday rentals from Booking.com, Wimdu, Homeaway...') }
            textThirdStep={ t('We find the best price available, to save you both time and money') }
            title={ t('6.868.052 establishments around the world') }
          />
        </article>
      </main>
    </div>
  );
}

Home.propTypes = {
  t: PropTypes.func.isRequired,
};

export default getContext(Home);
