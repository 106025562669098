import { AUTH_ACTIONS, WISHLIST_ACTIONS } from 'constants/actions';
import { requestAddFavourite, requestRemoveFavourite, retrieveFavourites } from 'actions/wishlistActions';
import gtmPageView from 'services/tracking/gtm/page-view';


export function changeToAuthenticatedUserMode(user) {
  return {
    type: AUTH_ACTIONS.CHANGE_TO_AUTHENTICATED_MODE,
    user,
  };
}

export function getUserExtraInfo(user = null) {
  return function (dispatch) {
    dispatch({
      type: AUTH_ACTIONS.EXTRA_INFO_FETCHED,
      user,
    });
    return dispatch(retrieveFavourites());
  };
}

export const storeHref = () => ({ type: AUTH_ACTIONS.STORE_HREF });

export const changeToGuestMode = () => ({ type: AUTH_ACTIONS.CHANGE_TO_GUEST_MODE });

export function changeToAuthenticatedMode(user) {
  return function (dispatch) {
    if (user.email && user.id && user.user_token) {
      dispatch(changeToAuthenticatedUserMode(user));
      return dispatch(getUserExtraInfo(user));
    }
    return dispatch(changeToGuestMode());
  };
}

export const loadAuthenticatedUserInfo = user => (dispatch, getState) => dispatch(changeToAuthenticatedMode(user)).then(() => {
  const state = getState().modals.props;
  if (!state || !state.establishmentId) return;

  if (state.action === WISHLIST_ACTIONS.ADD_FAVOURITE) {
    dispatch(requestAddFavourite(state.establishmentId, state.onCompleteCallback));
  } else if (state.action === WISHLIST_ACTIONS.REMOVE_FAVOURITE) {
    dispatch(requestRemoveFavourite(state.establishmentId));
  }
});

export const logout = ({ redirectToHome = false } = {}) => (dispatch) => {
  dispatch(changeToGuestMode());
  gtmPageView();
  if (redirectToHome) window.location.href = '/';
};
