/* global ga */
import makeSlug from 'services/slug';

const WISHLIST = `/wishlist`;

function percentTransform(value) {
  return Math.round(value * 100);
}

export function clickOut(partner, propID, destination) {
  const slug = destination ? makeSlug(destination) : WISHLIST;
  return ga('send', 'event', 'clickout', slug, `${partner.name}-${propID}`, percentTransform(partner.cpc));
}
