import PropTypes from 'prop-types';
import React from 'react';


export default function translatable(Module) {
  function Translatable(props, { t, routes, domainCulture }) {
    return (
      <Module
        { ...props }
        domainCulture={ domainCulture || '' }
        routes={ routes || {} }
        t={ t || (text => text) }
      />
    );
  }
  Translatable.contextTypes = {
    domainCulture: PropTypes.string,
    routes: PropTypes.shape(),
    t: PropTypes.func,
  };
  return Translatable;
}
