/* eslint-disable */
import { changeCheckboxChecked, requestCheckboxes } from 'actions/adActions';
import { END_DATE, START_DATE } from 'react-dates/constants';
import { formatFromString, getNumberOfNights } from 'helpers/dates-helper';
import { ID_ROUTES, ROUTES_CHECKBOXES } from 'constants/routes';
import { PAGES_BLOG, PAGES_SEO } from 'constants/constants';
import React, { Component } from 'react';
import AdCheckboxes from 'molecules/search-bar/ad-checkboxes';
import { bindActionCreators } from 'redux';
import calculateRadius from 'helpers/geometry-helper';
import { connect } from 'react-redux';
import DateRangePicker from 'react-dates/lib/components/DateRangePicker';
import { gACheckbox } from 'services/tracking/g-analytics';
import { getContext } from 'services/context';
import { getCurrentRoute } from 'app/route-container';
import { getPageType } from 'services/tracking/gtm/gtm-data';
import { gtmMicroconversions } from 'services/tracking/gtm/gtm-events';
import isInclusivelyAfterDay from 'react-dates/lib/utils/isInclusivelyAfterDay';
import moment from 'moment';
import PropTypes from 'prop-types';
import { retrieveCheapestDays } from 'actions/predictionActions';
import Scroll from 'react-scroll';
import { triggerPopunder } from 'components/ads/popunder-controller';


class ReactDatesContainer extends Component {
  constructor(props) {
    super(props);
    this.firstTime = true;
    this.highlightedDays = {};
    this.state = {
      focusedInput: props.showCalendar ? START_DATE : null,
      startDate: props.startDate,
      endDate: props.endDate,
      checkboxLogged: false,
      isCalendarShown: false,
    };
  }

  componentDidMount() {
    if (this.props.destination.name) {
      const { destination:
        { country, lat, lng, lat_ne: latNe, lat_sw: latSw, lng_ne: lngNe, lng_sw: lngSw },
      } = this.props;
      const radius = calculateRadius(latNe, latSw, lngNe, lngSw);
      this.props.retrieveCheapestDays(country, lat, lng, radius);
      this.props.requestCheckboxes(null, true);
    }
    if (PAGES_BLOG.includes(getPageType(window.location))) this.requestCheckboxes();
  }

  componentWillReceiveProps({ showCalendar, startDate, endDate, checkboxes, destination, guestsNumber, searchHash, toggleCalendar, closeCalendar }) {
    const currentRoute = getCurrentRoute() || getPageType(window.location);
    toggleCalendar();
    if (closeCalendar) this.setState({ focusedInput: null });
    if (searchHash !== this.props.searchHash) this.setState({ checkboxLogged: false });
    if (
      (PAGES_BLOG.includes(currentRoute) || searchHash)
      && (['', ID_ROUTES.ESTABLISHMENT].includes(currentRoute) || PAGES_SEO.includes(currentRoute) || searchHash !== this.props.searchHash)
    ) {
      if ((!ROUTES_CHECKBOXES.includes(getCurrentRoute()) && !this.state.checkboxLogged && checkboxes.length)) {
        this.setState({ checkboxLogged: true }, () => {
          const dates = document.querySelectorAll('.DateInput__input') || [];
          const oneTime = () => {
            gtmMicroconversions(
              'checkbox-impression',
              `${checkboxes[0].name}/${destination.name}/${guestsNumber}/${formatFromString(startDate, 'DD-MM-YYYY')}_${formatFromString(endDate, 'DD-MM-YYYY')}`
            );
            gACheckbox(checkboxes, 'impression', currentRoute);
            Array.prototype.forEach.call(dates, el => el.removeEventListener('click', oneTime));
          };
          Array.prototype.forEach.call(dates, el => el.addEventListener('click', oneTime));
        });
      }
    }

    if (showCalendar && !this.state.focusedInput && !this.state.startDate) {
      this.setState({ focusedInput: START_DATE });
    }

    if (showCalendar && !this.state.focusedInput && this.state.startDate && !this.state.endDate) {
      this.setState({ focusedInput: END_DATE });
    }

    if (showCalendar && startDate && !endDate) {
      this.setState({ focusedInput: END_DATE });
    }

    if (startDate && startDate !== this.props.startDate) {
      this.setState({ startDate: startDate, endDate: endDate ? endDate : null });
    }

    if (checkboxes.length !== this.props.checkboxes.length || destination.name !== this.props.destination.name || guestsNumber !== this.props.guestsNumber) {
      this.requestCheckboxes();
    }
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput }, () => {
      if (!focusedInput && this.props.hideCalendar) {
        this.props.hideCalendar();
        this.setState({ isCalendarShown: false });
      }
    });
    if (focusedInput === START_DATE) {
      this.showAllCalendar();
      const destinationBox = document.querySelector('.search-bar-molecule__desktop__box__destination');
      destinationBox.classList.remove('-grow');
    }
    if (focusedInput === END_DATE && !this.state.isCalendarShown) this.showAllCalendar();
  }

  onDatesChange({ startDate, endDate }) {
    this.setState({ startDate, endDate }, () => this.requestCheckboxes());
    if (this.state.focusedInput === START_DATE) endDate = null;
    this.props.onDatesChange({ startDate, endDate });
    if (endDate !== null) {
      triggerPopunder();
      this.setState({ focusedInput: this.props.needsButton ? START_DATE : null });
    }
  }

  isDayHighlighted(day) {
    if (this.props.cheapestDays && this.props.cheapestDays.length) {
      return this.props.cheapestDays.includes(day.format('DD-MM-YYYY'));
    }
    return false;
  }

  requestCheckboxes() {
    if (!ROUTES_CHECKBOXES.includes(getCurrentRoute())) {
      this.props.requestCheckboxes(null, true);
    }
  }

  initialVisibleMonth() {
    if (this.state.startDate) return moment(this.state.startDate);
    if (this.state.endDate) return moment(this.state.endDate);
    return moment();
  }

  showAllCalendar() {
    const currentPage = getPageType(window.location);
    const inBlog = PAGES_BLOG.includes(currentPage);
    if (inBlog && !this.state.isCalendarShown && this.props.checkboxes.length) {
      gACheckbox(this.props.checkboxes, 'impression', currentPage);
    }
    this.setState({ isCalendarShown: true });
    if (!this.props.isOnModal && !this.state.isCalendarShown) {
      setTimeout(() => {
        const calendar = document.querySelector('.DateRangePicker__picker');
        if (calendar) {
          const height = window.innerHeight;
          const px = calendar.getBoundingClientRect().bottom - height + 10;
          if (calendar.getBoundingClientRect().bottom > height) {
            Scroll.animateScroll.scrollTo(px, {
              duration: 500,
              delay: 0,
              smooth: true,
            });
          }
        }
      });
    }
  }

  isOutsideRange(startDate, endDate, focusedInput) {
    return startDate && !endDate && focusedInput !== START_DATE
      ? day => isInclusivelyAfterDay(startDate, day)
      : day => !isInclusivelyAfterDay(day, moment());
  }

  renderCalendarInfo() {
    const { cheapestDays, checkboxes, handleOnClick, needsButton, startDate, endDate, t } = this.props;
    const ads = !checkboxes.length || ROUTES_CHECKBOXES.includes(getCurrentRoute())
      ? null
      : (<div className="search-bar-molecule__desktop__box__dates__info__checkboxes">
          <AdCheckboxes
            className="-is-on-calendar"
            isOnCalendar={ true }
            adCheckboxes={ checkboxes }
            handleChange={ this.props.changeCheckboxChecked }
            t={ t }
          />
      </div>);

    const lowestPrice = (!cheapestDays || !cheapestDays.length)
      ? null
      : (<div className={ `search-bar-molecule__desktop__box__dates__info ${!ads && !needsButton ? '-without-checkboxes' : ''}` }>
        <span className="search-bar-molecule__desktop__box__dates__info__dot" />
        <span className="search-bar-molecule__desktop__box__dates__info__info">
          { t('Lowest average price') }
        </span>
      </div>
);

    const numNights = this.state.startDate !== null && this.state.endDate !== null ? getNumberOfNights(this.state.startDate, this.state.endDate) : null;

    const searchButton = !needsButton
      ? null
      : <span className="search-bar-molecule__desktop__box__dates__info__button-container">
        { numNights && <span className="search-bar-molecule__desktop__box__dates__info__button-container__nights">
{' '}
{ `${numNights} ${numNights > 1 ? t('nights') : t('night')}` }
{' '}
 </span> }

        <button
          className="search-bar-molecule__desktop__box__dates__info__button-container__button"
          onClick={ handleOnClick(true) }
          disabled={ !(startDate && endDate) }
        >
          { t('Search').toUpperCase() }
        </button>
      </span>;

    if (lowestPrice) {
      const classAdd = `${needsButton || checkboxes.length ? '-above-margin' : '-below-margin'}`;
      const calendar = document.querySelector('.transition-container--horizontal');
      if (calendar) calendar.classList.add(classAdd);
    } else {
      const classAdd = `${needsButton || checkboxes.length ? null : '-no-margin'}`;
      const calendar = document.querySelector('.transition-container--horizontal');
      if (calendar) calendar.classList.add(classAdd);
      if (calendar && (needsButton || checkboxes.length)) {
        calendar.classList.remove('-no-margin');
      }
    }
    return (
      <div className="search-bar-molecule__desktop__box__dates__conatiner">
        { lowestPrice }
        { ads }
        { searchButton }
      </div>
    );
  }

  renderMonthFormat() {
    const { domainCulture } = this.props;
    return domainCulture === 'ja-jp' ? 'YYYY年M月' : 'MMMM YYYY';
  }

  render() {
    const { isOnModal, needsButton, t } = this.props;
    const { focusedInput } = this.state;
    const startDate = this.state.startDate ? moment(this.state.startDate) : null;
    const endDate = this.state.endDate ? moment(this.state.endDate) : null;

    return (
      <div className="search-bar-molecule__desktop__box__dates">
        <span className="search-bar-molecule__desktop__box__dates__title">
          { t('Dates') }
        </span>
        <DateRangePicker
          displayFormat={ () => moment.localeData().longDateFormat('LL') }
          endDate={ endDate }
          endDateId={ END_DATE }
          endDatePlaceholderText={ t('Leaving') }
          focusedInput={ focusedInput }
          initialVisibleMonth={ this.initialVisibleMonth.bind(this) }
          isDayHighlighted={ this.isDayHighlighted.bind(this) }
          isOutsideRange={ this.isOutsideRange(startDate, endDate, focusedInput) }
          keepOpenOnDateSelect={ needsButton }
          minimumNights={ 1 }
          monthFormat={ this.renderMonthFormat() }
          numberOfMonths={ isOnModal ? 1 : 2 }
          onDatesChange={ this.onDatesChange.bind(this) }
          onFocusChange={ this.onFocusChange.bind(this) }
          renderCalendarInfo={ this.renderCalendarInfo.bind(this) }
          startDate={ startDate }
          startDateId={ START_DATE }
          startDatePlaceholderText={ t('Arrival') }
          withFullScreenPortal={ isOnModal }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    destination: state.destination,
    cheapestDays: state.searchFormReducer.cheapestDays,
    startDate: state.searchFormReducer.dateArrival,
    endDate: state.searchFormReducer.dateLeaving,
    guestsNumber: state.searchFormReducer.guestsNumber,
    checkboxes: state.adsReducer.checkboxes,
    searchHash: state.searchResultsReducer.searchHash,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    retrieveCheapestDays,
    requestCheckboxes,
    changeCheckboxChecked,
  }, dispatch);
}

ReactDatesContainer.propTypes = {
  cheapestDays: PropTypes.arrayOf(PropTypes.string),
  domainCulture: PropTypes.string,
  showCalendar: PropTypes.bool,
  onDatesChange: PropTypes.func,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  isOnModal: PropTypes.bool,
  hideCalendar: PropTypes.func,
  destination: PropTypes.object,
  dateArrival: PropTypes.number,
  dateLeaving: PropTypes.number,
  guestsNumber: PropTypes.number,
  checkboxes: PropTypes.array,
  searchHash: PropTypes.string,
  retrieveCheapestDays: PropTypes.func.isRequired,
  requestCheckboxes: PropTypes.func.isRequired,
  changeCheckboxChecked: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  needsButton: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(getContext(ReactDatesContainer));
