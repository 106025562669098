/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'services/Intl';

export function setContext(Module, constants, isPhone, isTablet) {
  const { TEXTS, ROUTES, DOMAIN_CULTURE, DOMAIN } = constants;

  class Context extends Component {
    getChildContext() {
      return {
        t: translate(TEXTS),
        routes: ROUTES,
        domainCulture: DOMAIN_CULTURE,
        domain: DOMAIN,
        isPhone,
        isTablet,
      };
    }

    render() {
      return <Module { ...this.props } />;
    }
  }

  Context.childContextTypes = {
    t: PropTypes.func,
    routes: PropTypes.shape(),
    domainCulture: PropTypes.string,
    domain: PropTypes.string,
    isPhone: PropTypes.string,
    isTablet: PropTypes.string,
  };
  return Context;
}

export function getContext(Module) {

  function Context(props, { t, routes, domainCulture, domain, isPhone, isTablet }) {
    return (
      <Module
        { ...props }
        t={ t || (text => text) }
        routes={ routes || {} }
        domainCulture={ domainCulture || '' }
        domain={ domain || '' }
        isPhone={ isPhone }
        isTablet={ isTablet }
      />
    );
  }

  Context.contextTypes = {
    t: PropTypes.func,
    routes: PropTypes.shape(),
    domainCulture: PropTypes.string,
    domain: PropTypes.string,
    isPhone: PropTypes.string,
    isTablet: PropTypes.string,
  };

  return Context;
}
