import moment from 'moment';
import url from 'url';


export function generateSearchUrlNew(state, options = {}) {
  const form = Object.assign({}, state.searchFormReducer, state.destination);
  const query = Object.assign({
    adults: form.guestsNumber,
    checkin: moment(new Date(form.dateArrival)).format('YYYY-MM-DD'),
    checkout: moment(new Date(form.dateLeaving)).format('YYYY-MM-DD'),
  },
  options,
  !state.modals || state.modals.name !== 'establishment' ? {}
    : { establishment: state.modals.props.id });

  return url.format({
    pathname: form.path,
    query,
  });
}

export function generateEstablishmentUrl() {}

export function generateAbsoluteUrl(relativeUrl) {
  if (!location.origin) {
    return `${location.protocol}//${location.host}`;
  }
  return `${location.origin}/${relativeUrl.replace(/^\//, '')}`;
}

export function generateModalUrl(searchUrl, modal) {
  if (searchUrl === '') return [`?modal=${modal}`, true];
  const match = /modal=([\w-,]+)/.exec(searchUrl);
  if (match !== null) {
    if (match[1] === modal) return [searchUrl, false];
    return [searchUrl.replace(`&modal=${match[1]}`, `&modal=${modal}`), true];
  }
  return [searchUrl.endsWith('&') ? `${searchUrl}modal=${modal}` : `${searchUrl}&modal=${modal}`, true];
}

export function generatePageUrl(searchUrl, pageNumber) {
  if (searchUrl === '') return `?page=${pageNumber}`;
  const match = /page=(\d+)+/.exec(searchUrl);
  if (match !== null) {
    return searchUrl.replace(`&page=${match[1]}`, `&page=${pageNumber}`);
  }
  return searchUrl.endsWith('&') ? `${searchUrl}page=${pageNumber}` : `${searchUrl}&page=${pageNumber}`;
}

export function generateEstablishmentPageUrl() {}

export function matchRoute(location, route) {
  const regexp = new RegExp(route);
  return location.match(regexp);
}

export function urlSlash(urlStr) {
  if (!urlStr.includes('?')) return urlStr.endsWith('/') ? urlStr : `${urlStr}/`;
  const parts = urlStr.split('?');
  return parts[0].endsWith('/') ? parts.join('?') : `${parts[0]}/?${parts[1]}`;
}

export function generateSeoPaginateUrl(location, page) {
  const href = location.endsWith('/') ? location.slice(0, -1) : location;
  const parts = href.split('/');
  if (page === 1) { parts.pop(); return `${parts.join('/')}/`; }
  if (isNaN(parts[parts.length - 1])) return `${location}${page}/`;
  parts.splice(parts.length - 1, 1, page);
  return `${parts.join('/')}/`;
}
