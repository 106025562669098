import moment from 'moment';

const reducersToPersist = [
  'recentSearchesReducer',
  'recentlyViewedReducer',
  'authReducer',
  'loggerReducer',
];

const proccessLocalStorage = {
  recentSearchesReducer: state => ({
    storedSearches: state.storedSearches.filter(search => moment().isSameOrBefore(search.dateArrival, 'day')),
  }),
  authReducer: state => ({ ...state, extraInfoFetched: false }),
  loggerReducer: state => ({ ...state }),
};

const prevState = {};

export function getLocalStorageState() {
  try {
    return Object.assign((global.SERVERDATA && global.SERVERDATA.state) || {},
      reducersToPersist.reduce((state, key) => {
        const reducer = global.localStorage.getItem(key);
        if (reducer) {
          state[key] = proccessLocalStorage[key]
            ? proccessLocalStorage[key](JSON.parse(reducer))
            : JSON.parse(reducer);
        }
        return state;
      }, {}));
  } catch (err) {
    return {};
  }
}

export function persistStore(state) {
  try {
    return typeof window === 'undefined' ? {} : reducersToPersist.forEach((key) => {
      if (prevState[key] !== state[key]) {
        global.localStorage.setItem(key, JSON.stringify(state[key]));
        prevState[key] = state[key];
      }
    });
  } catch (e) {
    return {};
  }
}
