export const SEARCH_FORM_ACTIONS = {
  CLEAR_DESTINATION: 'CLEAR_DESTINATION',
  DATE_SELECTION: 'DATE_SELECTION',
  DESTINATION_SELECTED: 'DESTINATION_SELECTED',
  DESTINATION_TYPING: 'DESTINATION_TYPING',
  GUESTS_SELECTION: 'GUESTS_SELECTION',
  REMOVE_CHEAPEST_DAYS: 'REMOVE_CHEAPEST_DAYS',
  RESET_FORM: 'RESET_FORM',
  REVERSE_DESTINATION: 'REVERSE_DESTINATION',
  SEARCH_FROM_MAP: 'SEARCH_FROM_MAP',
  SEARCH_START_ORIGIN: 'SEARCH_START_ORIGIN',
  SET_SEARCH_KEYWORDS: 'SET_SEARCH_KEYWORDS',
  STORE_HOLIDU_DESTINATION: 'STORE_HOLIDU_DESTINATION',
  SUBMIT: 'SUBMIT',
  SUBMIT_WITH_ERRORS: 'SUBMIT_WITH_ERRORS',
  UPDATE_BOUNDINGBOX: 'UPDATE_BOUNDINGBOX',
  UPDATE_CHEAPEST_DAYS: 'UPDATE_CHEAPEST_DAYS',
  UPDATE_LOADING_STATE: 'UPDATE_LOADING_STATE',
  UPDATE_SEARCH_SECTION: 'UPDATE_SEARCH_SECTION',
};

export const SEARCH_RESULTS_ACTIONS = {
  CLEAN_PRICE_PREDICTION: 'CLEAN_PRICE_PREDICTION',
  CLEAN_ZONES: 'CLEAN_ZONES',
  ESTABLISHMENT_OVER: 'ESTABLISHMENT_OVER',
  FETCHING_ESTABLISHMENTS_IN_AREA: 'FETCHING_ESTABLISHMENTS_IN_AREA',
  FINISH_SEARCH: 'FINISH_SEARCH',
  INIT_PRICE_RANGE: 'INIT_PRICE_RANGE',
  ORDER_ESTABLISHMENTS: 'ORDER_ESTABLISHMENTS',
  RECEIVE_ESTABLISHMENTS: 'RECEIVE_ESTABLISHMENTS',
  RECEIVE_PARTNERS: 'RECEIVE_PARTNERS',
  RECEIVE_VISIBLE_ESTABLISHMENTS: 'RECEIVE_VISIBLE_ESTABLISHMENTS',
  REMOVE_ESTABLISHMENT: 'REMOVE_ESTABLISHMENT',
  RESET_RESULTS_PAGE: 'RESET_RESULTS_PAGE',
  SELECT_ESTABLISHMENTS_PAGE: 'SELECT_ESTABLISHMENTS_PAGE',
  SHOW_MAP: 'SHOW_MAP',
  SHOW_MODAL: 'SHOW_SEARCH_MODAL',
  START_SEARCH: 'START_SEARCH',
  STORE_SEARCH_ID: 'STORE_SEARCH_ID',
  UPDATE_PRICE_PREDICTION: 'UPDATE_PRICE_PREDICTION',
  UPDATE_ZONES: 'UPDATE_ZONES',
};

export const FILTERS_ACTIONS = {
  FILTER_INTERACTION: 'FILTER_INTERACTION',
  INITIALIZE: 'INITIALIZE',
  ORDERING: 'ORDERING',
  RESET_PRICE_RANGE: 'RESET_PRICE_RANGE',
  SELECT_AVAILABILITY: 'SELECT_AVAILABILITY',
  SELECT_CANCELLATION: 'SELECT_CANCELLATION',
  SELECT_DISCOUNT: 'SELECT_DISCOUNT',
  SELECT_ESTABLISHMENT_TYPE: 'SELECT_ESTABLISHMENT_TYPE',
  SELECT_PRICE_FILTER_TYPE: 'SELECT_PRICE_FILTER_TYPE',
  SELECT_PRICE_RANGE: 'SELECT_PRICE_RANGE',
  SELECT_RATING: 'SELECT_RATING',
  SELECT_RECOMMENDATION: 'SELECT_RECOMMENDATION',
  SELECT_ROOMS_NUMBER: 'SELECT_ROOMS_NUMBER',
  SELECT_SERVICE: 'SELECT_SERVICE',
  SELECT_STARS: 'SELECT_STARS',
  SELECT_ZONE: 'SELECT_ZONE',
  SET_PRICE_RANGE: 'SET_PRICE_RANGE',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_PRICE_RANGES: 'UPDATE_PRICE_RANGES',
};

export const WISHLIST_ACTIONS = {
  ADD_FAVOURITE: 'ADD_FAVOURITE',
  CHANGE_PAGE: 'CHANGE_PAGE',
  REMOVE_FAVOURITE: 'REMOVE_FAVOURITE',
  REQUEST_FAVOURITE_ACTION: 'REQUEST_FAVOURITE_ACTION',
  UPDATE_FAVOURITES: 'UPDATE_FAVOURITES',
};

export const AUTH_ACTIONS = {
  CHANGE_TO_AUTHENTICATED_MODE: 'CHANGE_TO_AUTHENTICATED_MODE',
  CHANGE_TO_GUEST_MODE: 'CHANGE_TO_GUEST_MODE',
  EXTRA_INFO_FETCHED: 'EXTRA_INFO_FETCHED',
  STORE_HREF: 'STORE_HREF',
};

export const RECENT_SEARCHES_ACTIONS = {
  SAVE: 'SAVE_RECENT_SEARCHES',
};

export const RECENTLY_VIEWED_ACTIONS = {
  SAVE: 'SAVE_RECENTLY_VIEWED',
};

export const AD_ACTIONS = {
  CHANGE_CHECKBOX_CHECKED: 'CHANGE_CHECKBOX_CHECKED',
  CLEAR_CHECKBOXES: 'CLEAR_CHECKBOXES',
  RECEIVE_CHECKBOXES: 'RECEIVE_CHECKBOXES',
  RECEIVE_INLINES: 'RECEIVE_INLINES',
  RECEIVE_PINMAP: 'RECEIVE_PINMAP',
  RECEIVE_SUGGESTION_BOX: 'RECEIVE_SUGGESTION_BOX',
};

export const USER_PROFILE_ACTIONS = {
  CHANGE_USER_INFO: 'CHANGE_USER_INFO',
};

export const MODALS_ACTIONS = {
  HIDE_MODAL: 'HIDE_MODAL',
  HIDE_MODAL_BY_NAME: 'HIDE_MODAL_BY_NAME',
  SHOW_MODAL: 'SHOW_MODAL',
};

export const ESTABLISHMENT_DETAILS_ACTIONS = {
  UPDATE_PARTNERS_RATING: 'UPDATE_PARTNERS_RATING',
  UPDATE_SIMILARS: 'UPDATE_SIMILARS',
};

export const APP_ACTIONS = {
  NOTIFICATIONS_SUPPORTED: 'NOTIFICATIONS_SUPPORTED',
  SET_PUSH_NOTIFICATIONS_PERMISSION: 'SET_PUSH_NOTIFICATIONS_PERMISSION',
  SET_STANDALONE_MODE: 'SET_STANDALONE_MODE',
  SUBSCRIPTION_CHANGE: 'SUBSCRIPTION_CHANGE',
  TOGGLE_COOKIE_BANNER: 'TOGGLE_COOKIE_BANNER',
};

export const MAP_ACTIONS = {
  ESTABLISHMENT_OPEN: 'ESTABLISHMENT_OPEN',
};

export const LOCATION_ACTIONS = {
  SET_ACTUAL_PAGE: 'SET_ACTUAL_PAGE',
};

export const LOGGER_ACTIONS = {
  STORE_USER_ID: 'STORE_USER_ID',
  STORE_VISIT_ID: 'STORE_VISIT_ID',
};

export const RECOMMENDATION_ACTIONS = {
  RECEIVE_RECOMMENDATIONS: 'RECEIVE_RECOMMENDATIONS',
};
