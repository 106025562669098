import { SEARCH_FORM_ACTIONS } from 'constants/actions';


export function searchApplied(state = {}, action) {
  switch (action.type) {

    case SEARCH_FORM_ACTIONS.SUBMIT:
      return Object.assign({}, state, action.formState, action.destination);

    default:
      return state;
  }
}
