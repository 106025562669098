import { SEARCH_FORM_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';
import moment from 'moment';


const searchFormInitialState = {
  cheapestDays: [],
  dateArrival: null,
  dateLeaving: null,
  findByNameOnly: false,
  guestsNumber: 2,
  isLoading: false,
  keywords: [],
  searchOrigin: [],
  submitIncomplete: false,
  subSection: 1,
};

export default function searchFormReducer(state = searchFormInitialState, action) {
  switch (action.type) {
    case SEARCH_FORM_ACTIONS.SUBMIT_WITH_ERRORS:
      return Object.assign({}, state, {
        submitIncomplete: true,
      });

    case SEARCH_FORM_ACTIONS.DATE_SELECTION:
      return Object.assign({}, state, {
        dateArrival: action.dateArrival,
        dateLeaving: action.dateLeaving,
      });

    case SEARCH_FORM_ACTIONS.GUESTS_SELECTION:
      return Object.assign({}, state, {
        guestsNumber: action.guestsNumber,
      });

    case SEARCH_FORM_ACTIONS.RESET_FORM:
      return Object.assign({}, searchFormInitialState);

    case SEARCH_RESULTS_ACTIONS.RESET_RESULTS_PAGE:
      return Object.assign({}, state, {
        submitIncomplete: false,
      });

    case SEARCH_FORM_ACTIONS.SEARCH_START_ORIGIN:
      return Object.assign({}, state, {
        searchOrigin: action.searchOrigin,
      });

    case SEARCH_FORM_ACTIONS.UPDATE_CHEAPEST_DAYS:
      return Object.assign({}, state, {
        cheapestDays: action.days,
      });

    case SEARCH_FORM_ACTIONS.REMOVE_CHEAPEST_DAYS:
      return Object.assign({}, state, {
        cheapestDays: [],
      });

    case SEARCH_FORM_ACTIONS.UPDATE_SEARCH_SECTION:
      return Object.assign({}, state, {
        subSection: action.subSection,
      });

    case SEARCH_FORM_ACTIONS.UPDATE_LOADING_STATE:
      return Object.assign({}, state, {
        isLoading: action.loadingState,
      });

    case SEARCH_FORM_ACTIONS.SET_SEARCH_KEYWORDS:
      return Object.assign({}, state, {
        keywords: action.keywords,
      });

    default:
      return state;
  }
}

export function isFormOk(state) {
  return state.dateArrival && state.dateLeaving && state.guestsNumber;
}

export function totalNights(state) {
  return moment(state.dateLeaving).diff(state.dateArrival, 'days');
}
