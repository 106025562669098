import PropTypes from 'prop-types';
import React from 'react';


function getIconOrImg(source, sourceType) {
  const SOURCE_TYPE = {
    icon: source ? <i className={ `hundred-ui-checkbox__label__icon ${source}` } /> : null,
    image: <img alt="Checkbox label" className="hundred-ui-checkbox__label__image" src={ `${source}` } />,
  };
  return SOURCE_TYPE[sourceType];
}

export default function Checkbox({ className, id, text, subtext, textPosition, source, sourceType, checked, tooltipText, handleChange, disabled, isOnCalendar }) {
  const iconLabel = getIconOrImg(source, sourceType);

  return (
    <span className={ `hundred-ui-checkbox ${checked ? '-is-checked' : ''} -text-position-${textPosition} ${className}` } name={ tooltipText }>
      <label className="hundred-ui-checkbox__label" htmlFor={ id }>
        <span className={ `hundred-ui-checkbox__label__text ${isOnCalendar ? '-is-on-calendar' : ''}` }>
          { text }
        </span>
        { iconLabel }
      </label>
      <input
        checked={ checked }
        className={ `hundred-ui-checkbox__input ${isOnCalendar ? '-is-on-calendar' : ''}` }
        disabled={ disabled }
        id={ id }
        name={ id }
        onChange={ handleChange }
        type="checkbox"
      />
      {
        subtext
        && (
          <div className="hundred-ui-checkbox__subtext">
            { subtext }
          </div>
        )
      }
    </span>
  );
}

Checkbox.defaultProps = {
  className: '',
  disabled: false,
  isOnCalendar: false,
  source: null,
  sourceType: null,
  subtext: null,
  textPosition: 'right',
  tooltipText: null,
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isOnCalendar: PropTypes.bool,
  source: PropTypes.string,
  sourceType: PropTypes.string,
  subtext: PropTypes.string,
  text: PropTypes.node.isRequired,
  textPosition: PropTypes.string,
  tooltipText: PropTypes.string,
};
