import { LAYERS, MODALS_TO_LAYERS } from 'constants/constants';
import { MODALS_ACTIONS } from 'constants/actions';


const initialLayers = Object.keys(LAYERS).reduce((acc, key) => { acc[LAYERS[key]] = []; return acc; }, {});
const initialState = {
  layers: { ...initialLayers },
  name: null,
  props: {},
};

export function modals(state = initialState, action) {
  const _layers = { ...state.layers };
  switch (action.type) {

    case MODALS_ACTIONS.SHOW_MODAL: {
      _layers[MODALS_TO_LAYERS[action.name]].push({ name: action.name, props: action.props });

      return {
        layers: _layers,
        name: action.name,
        props: action.props,
      };
    }

    case MODALS_ACTIONS.HIDE_MODAL: {
      const _layer = MODALS_TO_LAYERS[action.modal.name];
      _layers[_layer] = _layers[_layer].filter(modal => JSON.stringify(modal) !== JSON.stringify(action.modal));
      return {
        layers: _layers,
        name: null,
        props: state.props,
      };
    }

    case MODALS_ACTIONS.HIDE_MODAL_BY_NAME: {
      const _layer = MODALS_TO_LAYERS[action.modal];
      _layers[_layer] = _layers[_layer].filter(modal => modal.name !== action.modal);
      return {
        layers: _layers,
        name: null,
        props: state.props,
      };
    }

    default: {
      return state;
    }
  }
}
