import { checkboxInterface, destinationInterface, recentSearchesInterface } from 'constants/interfaces';
import AdCheckboxes from 'molecules/search-bar/ad-checkboxes';
import DesktopSearchBar from 'molecules/search-bar/desktop-search-bar';
import MobileSearchBar from 'molecules/search-bar/mobile-search-bar';
import PropTypes from 'prop-types';
import React from 'react';


function SearchBar({ className, destination, guests, dateArrival, dateLeaving, domainCulture, adCheckboxes, searchMobileRequest,
  onDestinationSelected, onRecenSearchSelect, selectDestination, changeDateRequest, onGuestChange, onSubmitSearch,
  onCheckboxChange, destinationTyping, clearDestination, storedSearches, showCalendar, onDatesChange, isDayHighlighted,
  initialVisibleMonth, hideCalendar, showInsideColorBox, searchInputFocused, scrollFocus, isLandingSeo, isOnModal,
  needsButton, isLoading, closeCalendar, toggleCalendar, t }) {
  return (
    <form className={ `${className} search-bar-molecule ${showInsideColorBox ? '-show-color-box' : ''}` }>
      <span className="search-bar-molecule__desktop__container">
        <DesktopSearchBar
          changeDateRequest={ changeDateRequest }
          clearDestination={ clearDestination }
          closeCalendar={ closeCalendar }
          dateArrival={ dateArrival }
          dateLeaving={ dateLeaving }
          destinationLat={ destination.lat }
          destinationLng={ destination.lng }
          destinationName={ destination.name }
          destinationTyping={ destinationTyping }
          domainCulture={ domainCulture }
          guests={ guests }
          hideCalendar={ hideCalendar }
          initialVisibleMonth={ initialVisibleMonth }
          isDayHighlighted={ isDayHighlighted }
          isLoading={ isLoading }
          isOnModal={ isOnModal }
          needsButton={ needsButton }
          onDatesChange={ onDatesChange }
          onDestinationSelected={ onDestinationSelected }
          onGuestChange={ onGuestChange }
          onRecenSearchSelect={ onRecenSearchSelect }
          onSubmitSearch={ onSubmitSearch }
          scrollFocus={ scrollFocus }
          searchInputFocused={ searchInputFocused }
          selectDestination={ selectDestination }
          showCalendar={ showCalendar }
          storedSearches={ storedSearches }
          t={ t }
          toggleCalendar={ toggleCalendar }
        />
        <div className="search-bar-molecule__bottom">
          <AdCheckboxes
            adCheckboxes={ adCheckboxes }
            handleChange={ onCheckboxChange }
            t={ t }
          />
          <div className="search-bar-molecule__bottom__compare-image">
            <span className="search-bar-molecule__bottom__compare-image__text">
              { t('Compare free') }
            </span>
            <img alt={ t('Compare free') } className="search-bar-molecule__bottom__compare-image__img" src="https://static.100r.systems/img/general/blank.gif" />
          </div>
        </div>
      </span>
      { isLandingSeo
        && (
          <MobileSearchBar
            destinationName={ destination.name }
            searchMobileRequest={ searchMobileRequest }
            t={ t }
          />
        )
      }
    </form>
  );
}

SearchBar.defaultProps = {
  changeDateRequest: null,
  dateArrival: null,
  dateLeaving: null,
  initialVisibleMonth: null,
  isDayHighlighted: null,
  isLandingSeo: false,
  isLoading: null,
  searchInputFocused: null,
  showCalendar: null,
  showInsideColorBox: false,
};

SearchBar.propTypes = {
  adCheckboxes: PropTypes.arrayOf(checkboxInterface).isRequired,
  changeDateRequest: PropTypes.func,
  className: PropTypes.string.isRequired,
  clearDestination: PropTypes.func.isRequired,
  closeCalendar: PropTypes.bool.isRequired,
  dateArrival: PropTypes.number,
  dateLeaving: PropTypes.number,
  destination: destinationInterface.isRequired,
  destinationTyping: PropTypes.func.isRequired,
  domainCulture: PropTypes.string.isRequired,
  guests: PropTypes.number.isRequired,
  hideCalendar: PropTypes.func.isRequired,
  initialVisibleMonth: PropTypes.string,
  isDayHighlighted: PropTypes.func,
  isLandingSeo: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOnModal: PropTypes.bool.isRequired,
  needsButton: PropTypes.bool.isRequired,
  onCheckboxChange: PropTypes.func.isRequired,
  onDatesChange: PropTypes.func.isRequired,
  onDestinationSelected: PropTypes.func.isRequired,
  onGuestChange: PropTypes.func.isRequired,
  onRecenSearchSelect: PropTypes.func.isRequired,
  onSubmitSearch: PropTypes.func.isRequired,
  scrollFocus: PropTypes.func.isRequired,
  searchInputFocused: PropTypes.bool,
  searchMobileRequest: PropTypes.func.isRequired,
  selectDestination: PropTypes.func.isRequired,
  showCalendar: PropTypes.bool,
  showInsideColorBox: PropTypes.bool,
  storedSearches: recentSearchesInterface.isRequired,
  t: PropTypes.func.isRequired,
  toggleCalendar: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
};

export default SearchBar;
