export const countryTLDMatching = {
  ar: 'com.mx',
  cl: 'com.mx',
  co: 'com.mx',
  de: 'de',
  es: 'es',
  fr: 'fr',
  gb: 'co.uk',
  it: 'it',
  jp: 'com',
  mx: 'com.mx',
  pe: 'com.mx',
  us: 'com',
  uy: 'com.mx',
};

export const domainIDMatching = {
  ar: 1462,
  cl: 1462,
  co: 1462,
  de: 1719,
  es: 1679,
  fr: 1701,
  gb: 1700,
  it: 1703,
  jp: 2,
  mx: 1702,
  pe: 1462,
  us: 1699,
  uy: 1462,
};

export const partnerToProviderMatching = {
  2: 'BOOKINGCOM',
  9: "AIRBNB",
  15: "ONLY_APARTMENTS",
  17: "TRIPADVISOR",
  19: "RENTALIA",
  31: "AGODA",
  36: "EXPEDIA",
};
