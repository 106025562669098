import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Home from 'templates/home/head';
import { logout } from 'actions/authActions';
import PropTypes from 'prop-types';
import React from 'react';
import { showModal } from 'actions/modals';


export function HomeContainer({ userIsAuthenticated }) {

  function handleOnClick(e, key) {
    switch (key) {
      default:
        break;
    }
  }

  return (
    <Home
      handleOnClick={ handleOnClick }
      userIsAuthenticated={ userIsAuthenticated }
    />
  );
}

HomeContainer.propTypes = {
  userIsAuthenticated: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  return {
    userIsAuthenticated: state.authReducer.authenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    logoutAction: logout,
    showModalAction: showModal,
  },
  dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
