import { RECENTLY_VIEWED_ACTIONS } from '../constants/actions';

/**
 * Stores a search
 * @param  {integer} establishmentId
 */
export function saveRecentlyViewed(establishmentId) {
  return {
    id: establishmentId,
    type: RECENTLY_VIEWED_ACTIONS.SAVE,
  };
}
