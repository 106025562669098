import PropTypes from 'prop-types';
import React from 'react';

export default function GuestInput({ guests, handleOnClick, t }) {
  return (
    <div className="search-bar-molecule__desktop__box__guests">
      <span className="search-bar-molecule__desktop__box__guests__title">
        { t('Nº Guests') }
      </span>
      <span className="search-bar-molecule__desktop__box__guests__bottom">
        <span className="search-bar-molecule__desktop__box__guests__bottom__text">
          { guests > 16 ? '+16' : guests }
          <span className="search-bar-molecule__desktop__box__guests__bottom__text__description">
            {' '}
            { guests === 1 ? t('Guest') : t('Guests') }
          </span>
        </span>
        <div className="search-bar-molecule__desktop__box__guests__bottom__buttons">
          <span
            className="search-bar-molecule__desktop__box__guests__bottom__buttons__button hundredicons-minus-filled"
            onClick={ () => guests <= 1 || handleOnClick(Number(guests) - 1) }
          />
          <span
            className="search-bar-molecule__desktop__box__guests__bottom__buttons__button hundredicons-plus-filled"
            onClick={ () => guests > 16 || handleOnClick(Number(guests) + 1) }
          />
        </div>
      </span>
    </div>
  );
}

GuestInput.propTypes = {
  guests: PropTypes.number.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
