import { AUTH_ACTIONS } from 'constants/actions';


const userInitialState = {
  authenticated: false,
  extraInfoFetched: false,
  href: '/',
  user: {},
};

export function authReducer(state = userInitialState, action) {
  switch (action.type) {

    case AUTH_ACTIONS.CHANGE_TO_AUTHENTICATED_MODE:
      return Object.assign({}, state, {
        authenticated: true,
        user: action.user,
      });

    case AUTH_ACTIONS.CHANGE_TO_GUEST_MODE:
      return Object.assign({}, state, {
        authenticated: false,
        user: {},
      });

    case AUTH_ACTIONS.STORE_HREF:
      return Object.assign({}, state, {
        href: global.location.href,
      });

    case AUTH_ACTIONS.EXTRA_INFO_FETCHED:
      return Object.assign({}, state, {
        extraInfoFetched: true,
      });

    default:
      return state;
  }
}
