import AboutUs from 'molecules/footer/links/about-us';
import International from 'molecules/footer/links/international';
import Newsletter from 'molecules/footer/links/newsletter';
import PropTypes from 'prop-types';
import PublishEstablishment from 'molecules/footer/links/publish-establishment';
import React from 'react';
import Typologies from 'molecules/footer/links/typologies';


export default function Links({ handleChange, shouldHaveTypologies, routes, partnersLinks, domainCulture, device, t }) {
  return (
    <div className="footer-molecule__first-footer">
      <ul className="footer-molecule__first-footer__links">
        <AboutUs
          routes={ routes }
          t={ t }
        />
        { shouldHaveTypologies === 'index,follow'
          && (
            <Typologies
              routes={ routes }
              t={ t }
            />
          )
        }
        { partnersLinks
          && (
            <li className="footer-molecule__first-footer__links__publish-establishment" id="publishStablishment">
              <PublishEstablishment
                device={ device }
                partnersLinks={ partnersLinks }
                t={ t }
              />
            </li>
          )
        }
        <International domainCulture={ domainCulture } t={ t } />
      </ul>

      <Newsletter
        handleChange={ handleChange }
        t={ t }
      />
    </div>
  );
}

Links.propTypes = {
  device: PropTypes.oneOf(['mo', 'ta', 'dk']).isRequired,
  domainCulture: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  partnersLinks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  routes: PropTypes.shape().isRequired,
  shouldHaveTypologies: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
