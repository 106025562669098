import { LOGGER_ACTIONS } from 'constants/actions';


const initialState = {
  userId: null,
  visit: {
    id: null,
    timestamp: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {

    case LOGGER_ACTIONS.STORE_VISIT_ID:
      return Object.assign({}, state, {
        visit: {
          id: action.visitId,
          timestamp: action.refreshTime ? Date.now() : state.visit.timestamp,
        },
      });

    case LOGGER_ACTIONS.STORE_USER_ID:
      return Object.assign({}, state, {
        userId: action.userId,
      });

    default:
      return state;
  }
};
