/* eslint-disable no-inner-declarations */
import '../styles/chunks/app.home.styl';
import { gaPublishPartner, gARedirectBanner } from 'services/tracking/g-analytics';
import { isCookieSet, setCookie } from 'helpers/cookies-helper';
import BannerFooterRedirect from 'molecules/footer-smart-banner';
import { COOKIES } from 'constants/constants';
import CookiesBanner from 'atoms/cookie-banner';
import { dispatch } from 'services/store';
import { store as globalStore } from 'app/boot/init';
import LanguageCurrency from 'molecules/language-currency';
import PopunderController from 'components/ads/popunder-controller';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import PublishAd from 'molecules/publish-ad';
import React from 'react';
import { render } from 'react-dom';
import Routes from 'app/routes';
import { setContext } from 'services/context';
import { t } from 'services/Intl';


global.timestart = Date.now();

function App({ store, routes }) {
  return [
    <Provider key="provider" store={ store }>
      <Routes routes={ routes } />
    </Provider>,
    <PopunderController key="popunder-controller" />,
  ];
}

App.propTypes = {
  routes: PropTypes.shape().isRequired,
  store: PropTypes.shape().isRequired,
};

const { intl } = global.hr;
const AppIntl = setContext(App, intl, global.isPhone, global.isTablet);

render(<AppIntl
  routes={ intl.ROUTES }
  store={ globalStore }
/>,
document.getElementById('app-wrapper'));

if (document.querySelector('#publishAdInline') && global.SERVERDATA && global.SERVERDATA.props && global.SERVERDATA.props._publishEstablishmentAds) {
  render(
    <section>
      <PublishAd
        ads={ global.SERVERDATA.props._publishEstablishmentAds }
        body={ t('Millions of users search for accommodations like yours. Publish it in Hundredrooms through:') }
        device={ global.isPhone ? 'mo' : global.isTablet ? 'ta' : 'dk' }
        handleOnClick={ name => gaPublishPartner(name) }
        title={ t('Are you a rental property owner?') }
      />
    </section>,
    document.querySelector('#publishAdInline')
  );
}

if (document.querySelector('#language-currency') && global.hr.intl.DOMAIN_CULTURE) {
  render(
    <LanguageCurrency
      culture={ global.hr.intl.DOMAIN_CULTURE }
      t={ t }
    />,
    document.querySelector('#language-currency')
  );
}

if (global.SERVERDATA && global.SERVERDATA.props && global.SERVERDATA.props.redirectLiterals) {
  if (!isCookieSet(COOKIES.REDIRECT)) {

    function handleSmartBannerClick(accepted) {
      if (accepted) {
        setCookie(COOKIES.REDIRECT, true, 365);
        gARedirectBanner('redirection', global.SERVERDATA.props.redirectLiterals.domain);
        window.location.href = `https://www.hundredrooms.${global.SERVERDATA.props.redirectLiterals.domain}`;
      } else {
        setCookie(COOKIES.REDIRECT, false, 365);
        gARedirectBanner('no-thanks', global.SERVERDATA.props.redirectLiterals.domain);
        document.querySelector('#footerRedirect').style.display = 'none';
      }
    }

    gARedirectBanner('impression', global.SERVERDATA.props.redirectLiterals.domain);

    render(
      <BannerFooterRedirect
        handleOnClick={ handleSmartBannerClick }
        text={ global.SERVERDATA.props.redirectLiterals }
      />, document.querySelector('#footerRedirect')
    );
  }
}

const cookiesBanner = document.querySelector('#cookies-banner');
const literal = t('This site uses first and third party cookies to help you provide you with the best experience that we can. By continuing to browse Hundredrooms, you are agreeing to our use of cookies.');
if (cookiesBanner) {
  render(<CookiesBanner
    cookieName={ COOKIES.COOKIES_BANNER }
    dispatch={ dispatch }
    literal={ literal }
  />, cookiesBanner);
}

document.body.classList.remove('server');
