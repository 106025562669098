import PropTypes from 'prop-types';
import React from 'react';


export default function AboutUs({ routes, t }) {
  return (
    <li className="footer-molecule__first-footer__links__about-us">
      <ul className="footer-molecule__first-footer__links__about-us-container">
        <p className="footer-molecule__first-footer__links__about-us__title">
          { t('About us') }
        </p>
        { routes.blog
          && (
            <li className="footer-molecule__first-footer__links__about-us__link">
              <a
                className="footer-molecule__first-footer__links__about-us__link__a"
                href={ `/${routes.blog}/` }
                target="_blank"
              >
                { t('Blog') }
              </a>
            </li>
          )
        }
        <li className="footer-molecule__first-footer__links__about-us__link">
          <a
            className="footer-molecule__first-footer__links__about-us__link__a"
            href="mailto:contact@hundredrooms.com"
          >
            { t('Contact hundredrooms') }
          </a>
        </li>
      </ul>
    </li>
  );
}

AboutUs.propTypes = {
  routes: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};
