import Dropdown from 'atoms/dropdown';
import { getContext } from 'services/context';
import PropTypes from 'prop-types';
import React from 'react';
import SearchBarContainer from 'molecules/search-bar/container';


function Header({ className, handleOnClick, hasSearchBar, showHeaderMenu, hasSeoLinks, t, seoLinks,
  hideFilters, filterInteractionResult }) {
  const seoLinksText = {
    Apartments: t('Apartments'),
    Cottage: t('Cottage'),
    'Vacational houses': t('Vacational houses'),
  };

  const seoLinksComponent = seoLinks && seoLinks.length ? (
    <Dropdown
      className="header-molecule__nav__item__seo-links"
      content={
        seoLinks.map(({ text, href }) => ({
          href,
          target: "_blank",
          text: seoLinksText[text],
        }))
      }
      follow
      title={ t('Types of accommodation') }
    />
  ) : null;

  return (
    <header
      className={ `${className} header-molecule ${
        hasSearchBar ? "-has-search-bar" : "-without-search-bar"
      } ${!showHeaderMenu ? "-hidden-header" : ""}` }
      id="header-molecule"
      onClick={ e => hideFilters(e) }
    >
      <div
        className="header-molecule-container"
        style={ { pointerEvents: filterInteractionResult ? "none" : "auto" } }
      >
        <div className="header-molecule__logo">
          <a className="header-molecule__logo__image" href="/">
            <img
              alt="Hundredrooms logo"
              className="header-molecule__logo__image__img"
              src="https://static.100r.systems/img/general/logo.svg"
            />
            {!hasSearchBar && (
              <img
                alt="Hundredrooms logo"
                className="header-molecule__logo__image__img-text"
                src="https://static.100r.systems/img/general/HR-Logo-Horizontal.svg"
              />
            )}
          </a>
        </div>
        {hasSearchBar && (
          <SearchBarContainer
            className="header-molecule__search-bar"
            hideCheckboxes={ true }
          />
        )}
        <nav className="header-molecule__nav">
          {hasSeoLinks && seoLinksComponent}
        </nav>
      </div>
    </header>
  );
}

Header.defaultProps = {
  className: '',
  handleOnClick: () => {},
  hasSearchBar: false,
  hasSeoLinks: null,
  seoLinks: [],
};

Header.propTypes = {
  className: PropTypes.string,
  filterInteractionResult: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func,
  hasSearchBar: PropTypes.bool,
  hasSeoLinks: PropTypes.bool,
  hideFilters: PropTypes.func.isRequired,
  seoLinks: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
  })),
  showHeaderMenu: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default getContext(Header);
