/* eslint-disable import/prefer-default-export */
import { LOCATION_ACTIONS } from 'constants/actions';


export function changeActualPage(page) {
  return {
    page,
    type: LOCATION_ACTIONS.SET_ACTUAL_PAGE,
  };
}
