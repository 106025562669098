export const URL = 'https://www.hundredrooms';

export const COUNTRY_CURRENCY = {
  'de-de': {
    culture: 'DE',
    currency: '€',
    domain: '.de',
    flag: 'ger',
    translatedName: 'Deutsch',
  },
  'en-au': {
    culture: 'AU',
    currency: '$',
    domain: '.com.au',
    flag: 'aus',
    translatedName: 'English - Australia',
  },
  'en-gb': {
    culture: 'UK',
    currency: '£',
    domain: '.co.uk',
    flag: 'uki',
    translatedName: 'English - UK',
  },
  'en-us': {
    culture: 'US',
    currency: '$',
    domain: '.net',
    flag: 'usa',
    translatedName: 'English - USA',
  },
  'es-ar': {
    culture: 'AR',
    currency: '$',
    domain: '.com.ar',
    flag: 'arg',
    translatedName: 'Español - Argentina',
  },
  'es-cl': {
    culture: 'CL',
    currency: '$',
    domain: '.cl',
    flag: 'chi',
    translatedName: 'Español - Chile',
  },
  'es-co': {
    culture: 'CO',
    currency: '$',
    domain: '.com.co',
    flag: 'col',
    translatedName: 'Español - Colombia',
  },
  'es-es': {
    culture: 'ES',
    currency: '€',
    domain: '.com',
    flag: 'spa',
    translatedName: 'Español - España',
  },
  'es-mx': {
    culture: 'MX',
    currency: '$',
    domain: '.com.mx',
    flag: 'mex',
    translatedName: 'Español - México',
  },
  'es-pe': {
    culture: 'PE',
    currency: 'S/.',
    domain: '.com.pe',
    flag: 'per',
    translatedName: 'Español - Perú',
  },
  'es-uy': {
    culture: 'UY',
    currency: '$',
    domain: '.com.uy',
    flag: 'uru',
    translatedName: 'Español - Uruguay',
  },
  'fr-fr': {
    culture: 'FR',
    currency: '€',
    domain: '.fr',
    flag: 'fra',
    translatedName: 'Français',
  },
  'it-it': {
    culture: 'IT',
    currency: '€',
    domain: '.it',
    flag: 'ita',
    translatedName: 'Italiano',
  },
  'ja-jp': {
    culture: 'JP',
    currency: '¥',
    domain: '.jp',
    flag: 'jpn',
    translatedName: '日本語',
  },
};

export const COUNTRY_CURRENCY_DISCARDED = ['en-au'];
