import { AD_ACTIONS } from '../constants/actions';


const initialState = {
  checkboxes: [],
  inlines: [],
  pinmap: null,
  suggestionBox: null,
};

export function adsReducer(state = initialState, action) {

  switch (action.type) {
    case AD_ACTIONS.RECEIVE_CHECKBOXES:
      return Object.assign({}, state, {
        // ad default checked values to the data received
        checkboxes: action.checkboxes,
      });

    case AD_ACTIONS.RECEIVE_PINMAP:
      return Object.assign({}, state, { pinmap: action.pinmap });

    case AD_ACTIONS.CHANGE_CHECKBOX_CHECKED: {
      // change the checked value of the given checkbox
      const checkboxesCopy = state.checkboxes.slice();
      const checkboxToChangeIndex = checkboxesCopy.findIndex(checkbox => checkbox.id === action.adId);
      checkboxesCopy[checkboxToChangeIndex].checked = action.checked;
      return { ...state, checkboxes: checkboxesCopy };
    }

    case AD_ACTIONS.RECEIVE_INLINES:
      return Object.assign({}, state, {
        inlines: action.inlines,
      });

    case AD_ACTIONS.CLEAR_CHECKBOXES:
      return Object.assign({}, state, {
        checkboxes: initialState.checkboxes,
      });

    case AD_ACTIONS.RECEIVE_SUGGESTION_BOX:
      return Object.assign({}, state, {
        suggestionBox: action.suggestionBox,
      });

    default:
      return state;
  }
}
