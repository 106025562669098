import { getZonesByPolygonId } from 'services/geo';
import { SEARCH_RESULTS_ACTIONS } from 'constants/actions';


export const updateZones = zones => ({
  type: SEARCH_RESULTS_ACTIONS.UPDATE_ZONES,
  zones,
});

export const cleanZones = () => ({ type: SEARCH_RESULTS_ACTIONS.CLEAN_ZONES });

export const getZones = polygonId => dispatch => getZonesByPolygonId(polygonId).then(res => dispatch(updateZones(res)));
