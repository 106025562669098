import { AD_ACTIONS } from 'constants/actions';
import AdInline from 'services/AdService/AdInline';
import Checkbox from 'services/AdService/Checkbox';
import { getState as getCurrentState } from 'services/store';
import { getSearch } from 'reducers/main';
import Pinmap from 'services/AdService/Pinmap';
import SuggestionBox from 'services/AdService/SuggestionBox';


export function requestCheckboxes(search, withoutDates = false) {
  return function (dispatch) {
    const searchApplied = search || getSearch(getCurrentState());
    if ((!searchApplied.dateArrival || !searchApplied.dateLeaving) && !withoutDates) return;
    const checkbox = new Checkbox(searchApplied);
    dispatch({ checkboxes: checkbox.getCheckboxToShow(), type: AD_ACTIONS.RECEIVE_CHECKBOXES });
  };
}

export const requestPinmaps = search => (dispatch) => {
  const pinmap = new Pinmap(search);
  dispatch({ pinmap: pinmap.getPinmapToShow(), type: AD_ACTIONS.RECEIVE_PINMAP });
};

export function changeCheckboxChecked(adId, checked) {
  return { adId, checked, type: AD_ACTIONS.CHANGE_CHECKBOX_CHECKED };
}

export const requestInlines = search => (dispatch) => {
  const inline = new AdInline(search);
  dispatch({ inlines: inline.getInlinesToShow(), type: AD_ACTIONS.RECEIVE_INLINES });
};

export const requestSuggestionBox = search => (dispatch) => {
  const suggestionBox = new SuggestionBox(search);
  dispatch({ suggestionBox: suggestionBox.getSuggestionBoxToShow(), type: AD_ACTIONS.RECEIVE_SUGGESTION_BOX });
};
