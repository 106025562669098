import PropTypes from 'prop-types';
import React from 'react';


function Modal({ wrapperClass, contentClass, isClosable, showClose, handleClose, isLongModal, children, layerId }) {
  return (
    <div className={ `hundredmodal shape__modal ${wrapperClass} ${isLongModal ? 'long-modal' : ''}` } id={ layerId }>
      { isClosable
        && (
          <div
            className="hundredmodal__veil"
            onClick={ handleClose }
          />
        )
      }
      <div className={ `hundredmodal__content ${contentClass}` }>
        { children }
        { isClosable && showClose
          && (
            <i
              className={ `hundredmodal__content__close
              ${global.isPhone ? 'hundredicons-arrow-left-filled' : 'hundredicons-cross'}` }
              onClick={ handleClose }
            />
          )
        }
      </div>
    </div>
  );
}

Modal.defaultProps = {
  contentClass: '',
  isClosable: true,
  isLongModal: false,
  layerId: '',
  showClose: true,
  wrapperClass: '',
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  contentClass: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isClosable: PropTypes.bool,
  isLongModal: PropTypes.bool,
  layerId: PropTypes.string,
  showClose: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

export default Modal;
