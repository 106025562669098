import { createSearch, isSearchFromMap, reverseDestination, selectDate, selectDestination, selectGuests, setSearchOrigin, submitIncomplete } from 'actions/searchFormActions';
import { getBoundingBoxByName, getReverseGeocoding } from 'services/geo';
import { getURIS, PAGE_SIZE, RATING_PRICES_RANGE } from 'constants/constants';
import { isFetchingEstablishmentsInArea, receiveEstablishments, receiveVisibleEstablishments, startSearch, storeLoggerSearchId } from 'actions/searchResultsActions';
import { SEARCH_FORM_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';
import { browserHistory } from 'react-router';
import calculateRadius from 'helpers/geometry-helper';
import { formatFromString } from 'helpers/dates-helper';
import { generateSearchUrlNew } from 'helpers/urlGenerator';
import { getCurrentRoute } from 'app/route-container';
import { getPredictionPrice } from 'actions/predictionActions';
import { getZones } from 'actions/geoActions';
import Hr from 'service-client-js';
import { ID_ROUTES } from 'constants/routes';
import { isFormOk } from 'reducers/main';
import moment from 'moment';
import { requestRecommendations } from 'actions/recommendation-actions';
import { save as saveRecentSearch } from 'actions/recentSearchesActions';
import { trackSearch } from 'services/tracking';
import { updateFilters } from 'actions/filtersActions';


const maxSearchTime = 60 * 1000;
const maxResultsByRequest = (global.isPhone || global.isTablet) ? 50 : null;

Hr.setConfig({ RATING_PRICES_RANGE, SEARCH_SERVICE: getURIS().SEARCH });

// Create search request in area
export const requestEstablishmentsInArea = (boundingBox, polygon) => (dispatch, getState) => {
  getReverseGeocoding(boundingBox).then((result) => {
    dispatch(isFetchingEstablishmentsInArea(polygon ? polygon.getPath() : null));
    dispatch(setSearchOrigin());
    dispatch(reverseDestination(result));
    window.open(generateSearchUrlNew(getState()), '_self');
  });
};

export const requestNearbyEstablishments = (boundingBox, polygon) => (dispatch, getState) => {
  dispatch({ boundingBox, type: SEARCH_FORM_ACTIONS.UPDATE_BOUNDINGBOX });
  dispatch(isFetchingEstablishmentsInArea(polygon ? polygon.getPath() : null));
  dispatch(setSearchOrigin());
  dispatch(isSearchFromMap(false));
  window.open(generateSearchUrlNew(getState()), '_self');
};

/**
* Requests establishments
* @param {array} establishmentsIds
* */
export const requestEstablishments = establishmentsIds => (dispatch, getState) => {
  const newEstablishmentsIds = establishmentsIds.filter(id => !getState().establishments[id]);
  if (!newEstablishmentsIds.length) return;

  Hr.establishment.get(newEstablishmentsIds).then(establishments => dispatch(receiveEstablishments(establishments)));
};

// Requests for a description
export const requestEstablishmentImages = establishmentId => () => Hr.establishment.get(establishmentId).images();
export const requestEstablishmentInfo = establishmentId => () => Hr.establishment.get(establishmentId).extraInfo();

/**
 * User requested access to the results page from a search URL
 * @return {function}
 */
export function requestFromSearchUrl(destinationName, query) {
  return function (dispatch, getState) {
    dispatch(getUrlData(query));
    const language = global.hr.intl.DOMAIN_CULTURE.match(/^(..)-..$/)[1];
    getBoundingBoxByName(destinationName, language)
      .then((data) => {
        dispatch(selectDestination(destinationName, data));
        window.open(generateSearchUrlNew(getState()), '_self');
        window.open(generateSearchUrlNew(getState(), query.establishment ? { establishment: query.establishment } : {}), '_self');
      });
  };
}

export function getUrlData(query) {
  return function (dispatch) {
    const dateArrival = query.dateArrival
      ? moment(query.dateArrival, 'DD-MM-YYYY')
      : moment().add(5, 'days');
    const dateLeaving = query.dateLeaving
      ? moment(query.dateLeaving, 'DD-MM-YYYY')
      : dateArrival.clone().add(5, 'days');
    const guestsNumber = query.guestsNumber
      ? parseInt(query.guestsNumber, 10)
      : 2;
    dispatch(selectDate(dateArrival.valueOf(), dateLeaving.valueOf()));
    dispatch(selectGuests(guestsNumber));
    if (query.place_name) dispatch(selectDestination(query.place_name, query));
    if (query.searchId) dispatch(storeLoggerSearchId(query.searchId));
    if (query.polygon_id) dispatch(getZones(query.polygon_id));
  };
}

export function requestFromUrl(query) {
  return function (dispatch, getState) {
    dispatch(getUrlData(query));
    // Submit the form to perform the request
    if (isFormOk(getState())) {
      dispatch(createSearch());
    } else {
      dispatch(submitIncomplete());
    }
  };
}

function onStart(result, dispatch, getState, performance) {
  const state = getState();
  dispatch(startSearch(result.search_id));
  const { dateArrival, dateLeaving, guestsNumber } = state.searchFormReducer;

  const {
    country, lat, lng, lat_ne: latNe, lat_sw: latSw, lng_ne: lngNe, lng_sw: lngSw,
  } = state.destination;
  const radius = calculateRadius(latNe, latSw, lngNe, lngSw);
  dispatch(getPredictionPrice(
    formatFromString(dateArrival, 'DD-MM-YYYY'),
    formatFromString(dateLeaving, 'DD-MM-YYYY'),
    guestsNumber,
    country,
    lat,
    lng,
    radius
  ));
  trackSearch(state.searchFormReducer.searchOrigin);

  const cachedUrl = window.location.search.match(/is_cached=(\w+)/);
  if (cachedUrl) {
    performance.cached = cachedUrl[1] === 'true' ? 1 : 0;
  } else {
    performance.cached = result.is_cached ? 1 : 0;
  }

  if (getCurrentRoute() !== ID_ROUTES.ESTABLISHMENT) dispatch(saveRecentSearch(result.search_id, getState()));
}

function onProgress(results, dispatch, getState) {
  const state = getState();
  dispatch(receiveEstablishments(results.results));
  dispatch(requestRecommendations(state.loggerReducer.userId, Object.keys(results.results).map(id => Number(id))));
  if (results.visible) {
    Hr.establishment.search.filter(state.filtersApplied, true);
    const establishments = Hr.establishment.search.getPage(state.searchResultsReducer.currentPage - 1);
    dispatch(receiveVisibleEstablishments(establishments, results.search_progress));
  }
}

function forceUpdate(search, dispatch, getState, performance) {
  search.establishments.sort();
  onProgress({
    results: search.toObject(),
    search_progress: {
      progress: null,
    },
    visible: true,
  }, dispatch, getState, performance);
}

function onComplete(dispatch) {
  dispatch({ type: SEARCH_RESULTS_ACTIONS.FINISH_SEARCH });
}

// Requests the total result number of establishments
export const requestCreateSearch = () => (dispatch, getState) => {
  const state = getState().searchResultsReducer;
  const options = {
    maxResultsByRequest,
    maxSearchTime,
    pageSize: PAGE_SIZE,
    sortType: state.establishmentOrder,
  };

  try {
    /* eslint-disable no-unused-expressions */
    window.opener.name;
    /* eslint-enable no-unused-expressions */
  } catch (e) {
    return dispatch(updateFilters());
  }
  if (window.opener.name === 'checkbox' && window.opener.hr && window.opener.hr.search) {
    options.initState = { collection: [...window.opener.hr.search.toArray()], dictionary: { ...window.opener.hr.search.toObject() } };
    dispatch(receiveEstablishments(window.opener.hr.search.toObject()));
    window.opener.location.href = window.opener.hr.checkboxHref;
    window.opener = null;
  }

  return dispatch(updateFilters());
};

export function requestConnectSearch() {
  return function (dispatch, getState) {
    const { search } = Hr.establishment;
    const performance = {
      cached: 0,
      counterLog: 0,
      firstResult: 0,
      timestart: Date.now(),
    };

    if (search.startData) {
      onStart(search.startData, dispatch, getState, performance);
    } else {
      search.onStart = result => onStart(result, dispatch, getState, performance);
    }

    search.onProgress = results => onProgress(results, dispatch, getState, performance);
    forceUpdate(search, dispatch, getState, performance);

    search.onComplete = () => {
      onComplete(dispatch, performance);
      forceUpdate(search, dispatch, getState, performance);
    };

    search.onError = err => console.log('ERROR', err);

    dispatch(updateFilters());
  };
}
