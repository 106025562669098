import { SEARCH_FORM_ACTIONS } from 'constants/actions';

const destinationInitialState = {
  country: '',
  edomizil_id: null,
  lat: null,
  lat_ne: null,
  lat_sw: null,
  lng: null,
  lng_ne: null,
  lng_sw: null,
  name: '',
  path: null,
  place_id: null,
  polygon_id: null,
  rentalia_id: null,
  romaji_name: null,
  tags: [],
  tui_id: null,
};

function getTags(action) {
  return action.tags ? typeof action.tags === 'string' ? action.tags.split(',') : action.tags : [];
}

export default function destination(state = { ...destinationInitialState }, action) {
  switch (action.type) {

    case SEARCH_FORM_ACTIONS.DESTINATION_TYPING:
      return Object.assign({}, state, {
        lat: null,
        lng: null,
        name: action.value,
      });

    case SEARCH_FORM_ACTIONS.CLEAR_DESTINATION:
      return { ...state, ...destinationInitialState };

    case SEARCH_FORM_ACTIONS.DESTINATION_SELECTED:
      return Object.assign({}, state, {
        country: action.country,
        edomizil_id: action.edomizil_id,
        lat: parseFloat(action.lat),
        lat_map: null,
        lat_ne: parseFloat(action.lat_ne),
        lat_sw: parseFloat(action.lat_sw),
        lng: parseFloat(action.lng),
        lng_map: null,
        lng_ne: parseFloat(action.lng_ne),
        lng_sw: parseFloat(action.lng_sw),
        name: action.name,
        place_id: action.place_id,
        polygon_id: Number(action.polygon_id),
        rentalia_id: action.rentalia_id,
        romaji_name: action.romaji_name,
        tags: getTags(action),
        tui_id: action.tui_id,
      });

    case SEARCH_FORM_ACTIONS.RESET_FORM:
      return destinationInitialState;

    case SEARCH_FORM_ACTIONS.REVERSE_DESTINATION:
      if (action.destination.geoFailed) {
        return Object.assign({}, state, {
          lat: Number(action.destination.lat),
          lat_ne: action.destination.lat_ne,
          lat_sw: action.destination.lat_sw,
          lng: Number(action.destination.lng),
          lng_ne: action.destination.lng_ne,
          lng_sw: action.destination.lng_sw,
        });
      }
      return Object.assign({}, state, {
        country: action.destination.country,
        edomizil_id: action.edomizil_id,
        lat: Number(action.destination.lat),
        lat_ne: action.destination.lat_ne,
        lat_sw: action.destination.lat_sw,
        lng: Number(action.destination.lng),
        lng_ne: action.destination.lng_ne,
        lng_sw: action.destination.lng_sw,
        name: action.destination.name,
        place_id: action.destination.place_id,
        polygon_id: Number(action.destination.polygon_id),
        rentalia_id: action.rentalia_id,
        romaji_name: action.romaji_name,
        tags: action.tags,
        tui_id: action.tui_id,
      });

    case SEARCH_FORM_ACTIONS.UPDATE_BOUNDINGBOX:
      return Object.assign({}, state, {
        lat_ne: action.boundingBox.lat_ne,
        lat_sw: action.boundingBox.lat_sw,
        lng_ne: action.boundingBox.lng_ne,
        lng_sw: action.boundingBox.lng_sw,
      });

    case SEARCH_FORM_ACTIONS.STORE_HOLIDU_DESTINATION:
      return {
        ...state,
        name: action.name,
        path: action.path,
      };

    default:
      return state;
  }
}

export function isDestinationOk(state) {
  return state && state.name && state.name !== '';
}

export function getDestinationName(state) {
  return state.name;
}
