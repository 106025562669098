export const MACROS = {
  CHECKBOX: {
    AIRBNB: 'https://airbnb.pvxt.net/c/368721/264339/4273?u=<PARTNER_PARAM_1>/s/<ENCODED_DESTINATION>&p.checkin=<START_DATE>&p.checkout=<END_DATE>&sharedid=checkbox&subId1=b8c8f09bd9284c0fba43d546d5dbcd91',
  },
  INLINE: {
    AIRBNB_PREMIUM_POSITION: 'https://airbnb.pvxt.net/c/368721/264339/4273?u=<PARTNER_PARAM_1>/s/<ENCODED_DESTINATION>&p.checkin=<START_DATE>&p.checkout=<END_DATE>&sharedid=inline&subId1=b8c8f09bd9284c0fba43d546d5dbcd91',
    AIRBNB_SLP: 'https://airbnb.pvxt.net/c/368721/264339/4273?u=<PARTNER_PARAM_1>/s/<ENCODED_DESTINATION>&p.checkin=<START_DATE>&p.checkout=<END_DATE>&sharedid=inline&subId1=b8c8f09bd9284c0fba43d546d5dbcd91',
  },
  PINMAP: {
    AIRBNB: 'https://airbnb.pvxt.net/c/368721/264339/4273?u=<PARTNER_PARAM_1>/s/<ENCODED_DESTINATION>&p.checkin=<START_DATE>&p.checkout=<END_DATE>&sharedid=pinmap&subId1=b8c8f09bd9284c0fba43d546d5dbcd91',
  },
  SUGGESTIONBOX: {
    AIRBNB: 'https://airbnb.pvxt.net/c/368721/264339/4273?u=<PARTNER_PARAM_1>/s/<ENCODED_DESTINATION>&p.checkin=<START_DATE>&p.checkout=<END_DATE>&sharedid=suggestionbox&subId1=b8c8f09bd9284c0fba43d546d5dbcd91',
    AIRBNB_FALLBACK: 'https://airbnb.pvxt.net/c/368721/264339/4273?u=<PARTNER_PARAM_1>/s/homes?&sharedid=suggestionbox&subId1=b8c8f09bd9284c0fba43d546d5dbcd91',
  },
};

export const COUNTRY_PARAMS = {
  CHECKBOX: {
    AIRBNB: {
      ar: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'ar' } },
      au: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.au', 2: 'au' } },
      cl: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.cl', 2: 'cl' } },
      co: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.co', 2: 'co' } },
      de: { cpc: { dk: 0.05, mo: 0.05, ta: 0.05 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.de', 2: 'de' } },
      es: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.es', 2: 'com' } },
      fr: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.fr', 2: 'fr' } },
      gb: { cpc: { dk: 0.05, mo: 0.05, ta: 0.05 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.co.uk', 2: 'gb' } },
      it: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.it', 2: 'it' } },
      jp: { cpc: { dk: 0.1, mo: 0.1, ta: 0.1 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.jp', 2: 'jp' } },
      mx: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.mx', 2: 'mx' } },
      pe: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.pe', 2: 'pe' } },
      us: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com', 2: 'us' } },
      uy: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'uy' } },
    },
  },
  INLINE: {
    AIRBNB: {
      ar: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'ar' } },
      au: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.au', 2: 'au' } },
      cl: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.cl', 2: 'cl' } },
      co: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.co', 2: 'co' } },
      de: { cpc: { dk: 0.05, mo: 0.05, ta: 0.05 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.de', 2: 'de' } },
      es: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.es', 2: 'com' } },
      fr: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.fr', 2: 'fr' } },
      gb: { cpc: { dk: 0.05, mo: 0.05, ta: 0.05 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.co.uk', 2: 'gb' } },
      it: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.it', 2: 'it' } },
      jp: { cpc: { dk: 0.1, mo: 0.1, ta: 0.1 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.jp', 2: 'jp' } },
      mx: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.mx', 2: 'mx' } },
      pe: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.pe', 2: 'pe' } },
      us: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com', 2: 'us' } },
      uy: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'uy' } },
    },
  },
  PINMAP: {
    AIRBNB: {
      ar: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'ar' } },
      au: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.au', 2: 'au' } },
      cl: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.cl', 2: 'cl' } },
      co: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.co', 2: 'co' } },
      de: { cpc: { dk: 0.05, mo: 0.05, ta: 0.05 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.de', 2: 'de' } },
      es: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.es', 2: 'com' } },
      fr: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.fr', 2: 'fr' } },
      gb: { cpc: { dk: 0.05, mo: 0.05, ta: 0.05 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.co.uk', 2: 'gb' } },
      it: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.it', 2: 'it' } },
      jp: { cpc: { dk: 0.1, mo: 0.1, ta: 0.1 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.jp', 2: 'jp' } },
      mx: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.mx', 2: 'mx' } },
      pe: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.pe', 2: 'mx' } },
      us: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com', 2: 'us' } },
      uy: { cpc: { dk: 0.22, mo: 0.16, ta: 0.22 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'uy' } },
    },
  },
  SUGGESTIONBOX: {
    AIRBNB: {
      ar: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'ar' } },
      au: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.au', 2: 'au' } },
      cl: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.cl', 2: 'cl' } },
      co: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.co', 2: 'co' } },
      de: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.de', 2: 'de' } },
      es: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.es', 2: 'com' } },
      fr: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.fr', 2: 'fr' } },
      gb: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.co.uk', 2: 'gb' } },
      it: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.it', 2: 'it' } },
      jp: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.jp', 2: 'jp' } },
      mx: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.mx', 2: 'mx' } },
      pe: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.pe', 2: 'pe' } },
      us: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com', 2: 'us' } },
      uy: { cpc: { dk: 0.10, mo: 0.10, ta: 0.10 }, dateFormat: 'YYYY-MM-DD', params: { 1: 'www.airbnb.com.ar', 2: 'uy' } },
    },
  },
};
