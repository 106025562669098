import { getDatesAndGuests, getEstablishmentInfo, getPageConcept, getPageCountry, getPageSectionAndType, getPageType, getSearchFilters, getUrl, NONE } from 'services/tracking/gtm/gtm-data';
import Url from 'url';


export default function gtmPageView(params = null) {
  try {
    const parsedUrl = Url.parse(global.location.href);
    const state = global.hr.store ? global.hr.store.getState() : null;
    const tipologyPage = getPageType(parsedUrl, params);
    const [pageSection, pageType] = getPageSectionAndType(tipologyPage);
    const [startDate, endDate, guests] = getDatesAndGuests(tipologyPage, Url.parse(global.location.href), state);
    const status = global.hr.httpCode;
    const userId = state && state.loggerReducer ? state.loggerReducer.userId : null;
    global.dataLayer.push({
      ...params,
      establishmentInfo: getEstablishmentInfo(tipologyPage, Url.parse(global.location.href), state),
      event: 'pageview',
      numberGuests: guests || NONE,
      pageCode: status || '200(ok)',
      pageConcept: getPageConcept(tipologyPage, Url.parse(global.location.href), state),
      pageCountry: status ? NONE : getPageCountry(tipologyPage, state),
      pageSection: status ? NONE : pageSection || NONE,
      pageType: status ? NONE : pageType || NONE,
      searchEndDate: endDate || NONE,
      searchFilters: getSearchFilters(tipologyPage, state),
      searchStartDate: startDate || NONE,
      url: getUrl(parsedUrl, params),
      userId: userId || NONE,
      userLoggedIn: (global.hr.store && global.hr.store.getState().authReducer.user.id) || NONE,
    });
  } catch (e) {}

  global.hr.httpCode = null;
}
