import React, { Component } from 'react';
import axios from 'axios';
import { dispatch } from 'services/store';
import { domainIDMatching } from 'helpers/holidu';
import PropTypes from 'prop-types';
import { recentSearchesInterface } from 'constants/interfaces';
import { selectHoliduDestination } from 'actions/searchFormActions';
import { translatable } from 'services/Intl';


export class SearchFormDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSuggestions: false,
      suggestions: [],
    };
  }

  componentDidMount() {
    const { searchInputFocused } = this.props;
    if (searchInputFocused) this.geoSearch.focus();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { destinationName } = this.props;
    const { showSuggestions, suggestions } = this.state;
    return (
      (destinationName !== nextProps.destinationName
      && nextProps.destinationName.length)
      || showSuggestions !== nextState.showSuggestions
      || suggestions.length !== nextState.suggestions.length
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutBlur);
  }

  handleOnSuggestSelect(suggestion) {
    const { onDestinationSelected } = this.props;
    if (suggestion) {
      dispatch(selectHoliduDestination(suggestion.value, suggestion.pathname));
      onDestinationSelected();
    }

    this.setState({ suggestions: [] });
    setTimeout(() => this.geoSearch.blur(), 50);
  }

  handleOnFocus() {
    const { isOnModal, destinationName, storedSearches } = this.props;
    if (isOnModal) {
      const searchBoxModal = document.getElementsByClassName('search-bar-molecule__desktop__box__destination');
      searchBoxModal[1].classList.add('-grow');
    } else {
      const searchBox = document.querySelector('.search-bar-molecule__desktop__box__destination');
      searchBox.classList.add('-grow');
    }
    if (!destinationName && storedSearches.length > 0 && !global.isPhone && !global.isTablet) {
      this.setState({ showSuggestions: false });
    }
  }

  handleOnClickDestination() {
    const { destinationName, storedSearches } = this.props;

    if (!document.querySelector('.geosuggest__input').value && !destinationName && storedSearches.length > 0 && !global.isPhone && !global.isTablet) {
      this.setState({ showSuggestions: true });
    }
  }

  handleOnBlur() {
    const { isOnModal, destinationName, destinationLat, destinationLng, clearDestination } = this.props;
    this.setState({ showSuggestions: false });
    if (isOnModal) {
      const searchBoxModal = document.getElementsByClassName('search-bar-molecule__desktop__box__destination');
      searchBoxModal[1].classList.remove('-grow');
    } else {
      const searchBox = document.querySelector('.search-bar-molecule__desktop__box__destination');
      searchBox.classList.remove('-grow');
    }
    if (destinationName && (!destinationLat || !destinationLng)) clearDestination();
    if (destinationName !== '') this.setState({ showSuggestions: false });
  }

  async handleOnChange() {
    const { value } = this.geoSearch;
    const { destinationTyping } = this.props;
    axios.get(
      `/rest/v6/search/autocomplete/${value}?domainId=${domainIDMatching[global.hr.intl.COUNTRY_CODE]}`
    ).then(resp => this.setState({ suggestions: resp.data.items }));

    if (value !== "") {
      this.setState({ showSuggestions: false });
    } else {
      this.handleClearGeosuggest();
      this.setState({ suggestions: [] });
    }

    destinationTyping(value);
  }

  handleClearGeosuggest() {
    const { isOnModal, clearDestination } = this.props;
    if (isOnModal) {
      const searchBoxModal = document.getElementsByClassName('search-bar-molecule__desktop__box__destination');
      searchBoxModal[1].classList.remove('-decrease');
      searchBoxModal[1].classList.add('-grow');
    } else {
      const searchBox = document.querySelector('.search-bar-molecule__desktop__box__destination');
      searchBox.classList.add('-grow');
    }
    this.geoSearch.value = '';
    clearDestination();
    this.setState({ suggestions: [] });
    setTimeout(() => this.geoSearch.focus(), 750);
  }

  render() {
    const { className, destinationName, t } = this.props;
    const { suggestions } = this.state;
    return (
      <div
        className={ `${className} search-form-destination` }
        onClick={ this.handleOnClickDestination.bind(this) }
      >
        <input
          ref={ (input) => { this.geoSearch = input; } }
          className={ `geosuggest__input ${className} ${global.isPhone ? '' : '-is-desktop'}` }
          onBlur={ this.handleOnBlur.bind(this) }
          onChange={ this.handleOnChange.bind(this) }
          onFocus={ this.handleOnFocus.bind(this) }
          placeholder={ t("Where do you want to go?") }
          value={ destinationName || "" }
        />
        <div
          className={ `search-form-destination__remove-button hundredicons-cross ${
            !suggestions.length ? "-hidden" : ""
          }` }
          onClick={ this.handleClearGeosuggest.bind(this) }
        />
        {suggestions.length && destinationName ? (
          <div className="geosuggest__suggests-wrapper">
            <ul className="geosuggest__suggests geosuggest__suggests">
              {suggestions.map(suggestion => (
                <li
                  key={ suggestion.value }
                  className="geosuggest__item"
                  onClick={ () => this.handleOnSuggestSelect(suggestion) }
                >
                  <span>
                    <b className="geosuggest__item__matched-text">
                      {suggestion.value}
                    </b>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    );
  }
}

SearchFormDestination.defaultProps = {
  className: '',
  destinationLat: null,
  destinationLng: null,
  destinationName: null,
  isOnModal: null,
  searchInputFocused: false,
};

SearchFormDestination.propTypes = {
  className: PropTypes.string,
  clearDestination: PropTypes.func.isRequired,
  destinationLat: PropTypes.number,
  destinationLng: PropTypes.number,
  destinationName: PropTypes.string,
  destinationTyping: PropTypes.func.isRequired,
  isOnModal: PropTypes.bool,
  onDestinationSelected: PropTypes.func.isRequired,
  searchInputFocused: PropTypes.bool,
  storedSearches: recentSearchesInterface.isRequired,
  t: PropTypes.func.isRequired,
};

export default translatable(SearchFormDestination);
