import { MAX_NUMBER_OF_RECENTLY_VIEWED_TO_STORE } from 'constants/constants';
import { RECENTLY_VIEWED_ACTIONS } from 'constants/actions';


const initialState = {
  recentlyViewedIds: [],
};

export default function recentlyViewedReducer(state = initialState, action) {

  if (action.type === RECENTLY_VIEWED_ACTIONS.SAVE) {

    let recentlyViewed = state.recentlyViewedIds.slice();

    // Do not duplicate
    if (recentlyViewed.includes(action.id)) return state;

    // Limit storage
    const length = recentlyViewed.unshift(action.id);
    if (length > MAX_NUMBER_OF_RECENTLY_VIEWED_TO_STORE) {
      recentlyViewed = recentlyViewed.slice(0, MAX_NUMBER_OF_RECENTLY_VIEWED_TO_STORE);
    }

    return {
      ...state,
      recentlyViewedIds: recentlyViewed,
    };
  }

  return state;
}

export function getRecentlyViewedEstablishmentIds(state) {
  return state.recentlyViewedIds;
}

export function getRecentlyViewedEstablishmentsNumber(state) {
  return getRecentlyViewedEstablishmentIds(state).length;
}
