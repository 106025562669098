import Links from 'molecules/footer/links';
import Permanent from 'molecules/footer/permanent';
import PropTypes from 'prop-types';
import React from 'react';


export default function Footer({ className, shouldHaveLinks, shouldHaveTypologies, handleChange, routes,
  domainCulture, partnersLinks, socialMediaLinks, follow, device, t }) {
  return (
    <footer className={ `${className} footer-molecule` }>
      { shouldHaveLinks
        && (
          <Links
            device={ device }
            domainCulture={ domainCulture }
            handleChange={ handleChange }
            partnersLinks={ partnersLinks }
            routes={ routes }
            shouldHaveTypologies={ shouldHaveTypologies }
            t={ t }
          />
        )
      }
      <Permanent
        follow={ follow }
        routes={ routes }
        shouldHaveLinks={ shouldHaveLinks }
        socialMediaLinks={ socialMediaLinks }
        t={ t }
      />
    </footer>
  );
}

Footer.defaultProps = {
  className: '',
  device: 'dk',
  follow: false,
  handleChange: () => {},
  partnersLinks: null,
  shouldHaveLinks: null,
  shouldHaveTypologies: null,
};

Footer.propTypes = {
  className: PropTypes.string,
  device: PropTypes.oneOf(['mo', 'ta', 'dk']),
  domainCulture: PropTypes.string.isRequired,
  follow: PropTypes.bool,
  handleChange: PropTypes.func,
  partnersLinks: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  routes: PropTypes.shape().isRequired,
  shouldHaveLinks: PropTypes.bool,
  shouldHaveTypologies: PropTypes.string,
  socialMediaLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
};
