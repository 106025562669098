import { AVAILABILITY, ESTABLISHMENT_TYPE } from 'constants/constants';

export function establishmentTypesLiteral(t, id) {
  const literals = {
    [ESTABLISHMENT_TYPE.APARTMENT]: t('Apartment'),
    [ESTABLISHMENT_TYPE.HOTEL]: t('Hotel'),
    [ESTABLISHMENT_TYPE.HOUSE]: t('House'),
    [ESTABLISHMENT_TYPE.ROOM]: t('Room'),
    [ESTABLISHMENT_TYPE.APARTHOTEL]: t('Aparthotel'),
  };
  return literals[id];
}

export function servicesLiteral(t, id) {
  const literals = {
    1: t('Sheets and towels '),
    2: t('Kitchen'),
    3: t('Wifi'),
    4: t('Internet'),
    5: t('Cable TV'),
    6: t('TV'),
    7: t('Parking'),
    8: t('AC'),
    9: t('Laundry'),
    10: t('Cleaning'),
    11: t('Wheelchair accessible'),
    12: t('Pool'),
    13: t('Gym'),
    14: t('Breakfast'),
    15: t('Elevator'),
    16: t('Doorman'),
    17: t('Balcony'),
    18: t('Outdoors'),
    19: t('Cot'),
    20: t('Heating'),
    21: t('Pet friendly'),
  };
  return literals[id];
}

export function roomsLiterals(t, id) {
  const literals = [
    t('All'),
    `1 ${t('Room')}`,
    `2 ${t('Rooms')}`,
    `3 ${t('Rooms')}`,
    `4 ${t('Rooms')}`,
    `5 ${t('Rooms')}`,
    `6 ${t('Rooms')}`,
    `7 ${t('Rooms')}`,
    `8 ${t('Rooms')}`,
    `9 ${t('Rooms')}`,
    `10 ${t('Rooms')}`,
    `+10 ${t('Rooms')}`,
  ];
  return literals[id];
}

export function availabilityLiterals(t, id) {
  const literals = {
    [AVAILABILITY.INSTANTLY]: t('Instant book'),
    [AVAILABILITY.UNDER_DEMAND]: t('Host approval'),
    [AVAILABILITY.UNCONFIRMED]: t('Unconfirmed'),
  };
  return literals[id];
}

export function otherLiterals(t) {
  return [
    t('Exceptional'),
    t('Fantastic'),
    t('Very good'),
    t('Good'),
  ];
}
