import PropTypes from 'prop-types';
import React from 'react';


export default function Newsletter({ handleChange, t }) {
  return (
    <div className="footer-molecule__first-footer__newsletter">
      <p className="footer-molecule__first-footer__newsletter__title">
        { t('Newsletter') }
      </p>
      <p className="footer-molecule__first-footer__newsletter__text">
        { t('Subscribe to our newsletter and you will be the first to get the best discounts') }
      </p>
      <input
        className="footer-molecule__first-footer__newsletter__input"
        name="newsletter"
        onChange={ e => handleChange(e.target.value) }
        placeholder={ t('Email') }
        tabIndex="-1"
        type="email"
      />
      <button className="footer-molecule__first-footer__newsletter__button" type="button">
        { t('Send') }
      </button>
    </div>
  );
}

Newsletter.propTypes = {
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
