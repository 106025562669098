/* eslint-disable react/no-multi-comp */
import { DATA_ROUTES, ID_ROUTES } from 'constants/routes';
import Footer from 'molecules/footer/container';
import HeaderContainer from 'components/header-container';
import PropTypes from 'prop-types';
import React from 'react';

let currentRoute = '';

export function getCurrentRoute() {
  return currentRoute;
}

export default function getRouteContainer(Component, receivedId) {
  function RouteContainer(props) {
    currentRoute = receivedId;
    let isServerError = null;
    if (typeof window !== 'undefined') {
      if (props.location && ['error-page', 'server'].every(c => document.body.classList.contains(c))) {
        document.body.classList.remove('server');
        isServerError = true;
      } else {
        document.body.className = DATA_ROUTES[currentRoute].bodyClass;
      }
    }

    const header = {
      [ID_ROUTES.HOME]: () => (
        <HeaderContainer
          className={ `${DATA_ROUTES[currentRoute].bodyClass}__header` }
          hasSeoLinks
        />
      ),
      default: () => (
        <HeaderContainer
          className={ `${DATA_ROUTES[currentRoute].bodyClass}__header` }
          hasSearchBar
        />
      ),

    };

    const footer = {
      [ID_ROUTES.HOME]: () => null,
      [ID_ROUTES.STATIC]: () => null,
      [ID_ROUTES.ERROR]: () => null,
      [ID_ROUTES.ERROR]: () => null,
      default: () => (isServerError ? null : (
        <Footer
          className="contact__footer"
          handleChange={ () => {} }
          socialMediaLinks={ global.hr.intl.SOCIAL_MEDIA_LINKS }
        />)
      ),
    };

    return (
      <div id="routeContent">
        { header[currentRoute] ? header[currentRoute]() : header.default() }
        <div id="app">
          <Component { ...props } routeId={ currentRoute } />
        </div>
        { footer[currentRoute] ? footer[currentRoute]() : footer.default() }
      </div>
    );
  }

  RouteContainer.propTypes = {
    location: PropTypes.shape().isRequired,
  };

  return RouteContainer;
}
