import {
  ALERT_POPUP_TYPES,
  AVAILABILITY,
  ESTABLISHMENT_TYPE,
  PRICE_FILTER_TYPE,
  PRICE_RANGES,
  SERVICES,
} from 'constants/constants';

export const ACTIONS_ALERT_NAMES = {
  [ALERT_POPUP_TYPES.CARD]: 'card',
  [ALERT_POPUP_TYPES.FLOAT_REGISTER]: 'Float_register',
  [ALERT_POPUP_TYPES.FOCUS_LOST]: 'retention-popup',
  [ALERT_POPUP_TYPES.LEAD]: 'Leads',
};

export const OPEN_MODAL_ACTIONS = {
  OPEN_FROM: 'Open from',
  SAVE: 'Save',
  SHARE: 'Share',
  SLIDE: 'Slide card',
};

export const OPEN_FROM_LABEL = {
  AMENITIES: 'amenities',
  CANCELLATION_POLICY_FREE: 'free-cancellation-logo',
  CANCELLATION_POLICY_PARTIAL: 'partial-cancellation-logo',
  DETAIL_BUTTON: 'Detail button',
  DISCOUNT: 'Tag discount',
  FIRST_IMAGE: 'Image principle',
  HOTDEALS: 'hotdeals',
  OFFERS: 'Link offer match',
  RESUME_INFO: 'Resume info',
  REVIEW: 'Review',
  REVIEWS: 'reviews',
  TITLE: 'Title card',
};

export const OPEN_FROM_LABEL_MAPCARD = {
  AMENITIES: 'cardmap_amenities',
  HOTDEALS: 'cardmap_hotdeals',
  IMAGE: 'Image cardmap',
  REVIEWS: 'cardmap_reviews',
  TITLE: 'Title cardmap',
};

export const SAVE_LABEL = {
  SAVE: 'Save',
  UNSAVE: 'Un-Save',
};

export const SLIDE_LABEL = {
  DEPLOY: 'deploy',
  FOLD: 'fold',
  SLIDE: 'Slide',
};

export const FILTERS_ACTION_NAMES = {
  APPLY_FILTERS: 'apply_filters',
  AVAILABILITY: 'availability',
  CANCELLATION: 'cancellation',
  CLOSE_FILTERS: 'close_filters',
  DELETE_FILTERS: 'delete_filters',
  ESTABLISHMENT_TYPE: 'type',
  HOT_DEALS: 'chollos',
  NEIGHBORHOODS: 'neighborhoods',
  OPEN_FILTERS: 'open_filters',
  PRICE_RANGES: 'price',
  PRICE_TYPE: 'price_type',
  RATING: 'rating',
  RECOMMENDATION: 'recommendation',
  REVIEWS: 'reviews',
  ROOM_OPTIONS: 'room',
  SERVICES: 'services',
  STARS: 'stars',
};

export const FILTERS_LABELS = {
  AVAILABILITY: {
    [AVAILABILITY.INSTANTLY]: 'instant-booking',
    [AVAILABILITY.UNCONFIRMED]: 'filter.unconfirmed',
    [AVAILABILITY.UNDER_DEMAND]: 'request',
  },
  ESTABLISHMENT_TYPE: {
    [ESTABLISHMENT_TYPE.APARTMENT]: 'apartament',
    [ESTABLISHMENT_TYPE.HOTEL]: 'hotel',
    [ESTABLISHMENT_TYPE.HOUSE]: 'house',
    [ESTABLISHMENT_TYPE.ROOM]: 'room',
    [ESTABLISHMENT_TYPE.APARTHOTEL]: 'aparthotel',
  },
  HOT_DEALS: {
    CHECKED: 'Activate',
    UNCHECKED: 'Desactivate',
  },
  PRICE_RANGES: {
    [PRICE_FILTER_TYPE.DAY]: PRICE_RANGES.DAY,
    [PRICE_FILTER_TYPE.TOTAL]: PRICE_RANGES.TOTAL,
  },
  PRICE_TYPE: {
    [PRICE_FILTER_TYPE.DAY]: 'night',
    [PRICE_FILTER_TYPE.TOTAL]: 'total',
  },
  RATING: {
    7: '7+',
    8: '8+',
    8.5: '8.5+',
    9.5: '9.5+',
  },
  RECOMMENDATION: 'recommendation',
  ROOM_OPTIONS: {
    0: 'all',
    1: '1',
    2: '2',
    3: '3',
    4: '4+',
  },
  SERVICES: {
    [SERVICES.SERVICE_AIR_CONDITIONING]: 'air conditioner',
    [SERVICES.SERVICE_BALCONY]: 'balcony',
    [SERVICES.SERVICE_BREAKFAST]: 'breakfast',
    [SERVICES.SERVICE_CABLE_TV]: 'cableTV',
    [SERVICES.SERVICE_CRADLE]: 'cradle',
    [SERVICES.SERVICE_DOORMAN]: 'doorman',
    [SERVICES.SERVICE_FREE_CLEANING]: 'cleaning',
    [SERVICES.SERVICE_GYM]: 'gym',
    [SERVICES.SERVICE_HANDICAPPED]: 'handicapped',
    [SERVICES.SERVICE_HEAT]: 'heating',
    [SERVICES.SERVICE_INTERNET]: 'internet',
    [SERVICES.SERVICE_KITCHEN]: 'kitchen',
    [SERVICES.SERVICE_LAUNDRY]: 'laundry',
    [SERVICES.SERVICE_LIFT]: 'elevator',
    [SERVICES.SERVICE_OUTDOOR_SPACE]: 'outdoor',
    [SERVICES.SERVICE_PARKING]: 'parking',
    [SERVICES.SERVICE_PETS]: 'pet',
    [SERVICES.SERVICE_SWIMMING_POOL]: 'swimmingPool',
    [SERVICES.SERVICE_TOWELS]: 'towels',
    [SERVICES.SERVICE_TV]: 'tv',
    [SERVICES.SERVICE_WIFI]: 'wifi',
  },
};

export const SHARE_ACTION_NAMES = {
  ESTABLISHMENT: 'page_establishment',
};

export const SHARE_UTM_MEDIUM = {
  [SHARE_ACTION_NAMES.ESTABLISHMENT]: 'page_establishment',
};

export const CALENDAR_ACTION = {
  INPUT: 'Input',
  OUTPUT: 'Output',
};

export const CALENDAR_LABEL = {
  [CALENDAR_ACTION.INPUT]: 'fecha entrada',
  [CALENDAR_ACTION.OUTPUT]: 'fecha salida',
};

export const SEARCH_BAR_ACTION = {
  ACTIONABLE: 'actionable',
  CHECKBOX: 'checkbox',
  GUESTS: 'guests',
  OPEN_CALENDAR: 'open-calendar',
  POPUNDER: 'popunder',
  SEARCH_LOCATION: 'search-location',
};

export const SEARCH_BAR_LABEL = {
  [SEARCH_BAR_ACTION.ACTIONABLE]: {
    LOCATION_FIX: 'location-fix',
    NEW_SEARCH: 'new-search',
    SAVED_SEARCH: 'saved-search',
  },
  [SEARCH_BAR_ACTION.CHECKBOX]: {
    CHECKED: 'checked',
    IMPRESSION: 'impression',
  },
  [SEARCH_BAR_ACTION.OPEN_CALENDAR]: {
    CHECK_IN: 'check-in',
    CHECK_OUT: 'check-out',
  },
  [SEARCH_BAR_ACTION.POPUNDER]: {
    ARRIVE: 'arrive',
    COUNTRY_DISABLED: 'country-disabled',
    NOT_ARRIVE_ADBLOCK: 'not-arrive-adblock',
    NOT_ARRIVE_OTHERS: 'not-arrive-others',
  },
};

export const CARD_MAP_LABELS = {
  AMENITIES: 'amenities',
  BUTTON_BOOKING: 'cta',
  CARD_IN_PAGE: 'Dot Principle',
  CARD_NOT_IN_PAGE: 'Dot paginate',
  CARDIMAGE: 'cardimage',
  HOTDEALS: 'hotdeals',
  NEXT: 'next',
  PARTNER: 'partner',
  PREVIOUS: 'previous',
  PRICE: 'price',
  REVIEWS: 'reviews',
  TITLECARD: 'titlecard',
};

export const INLINE_SELF_AD_ACTIONS = {
  CANCELLATION_POLICY: 'cancellation-policy-inline',
  HOTDEAL: 'hotdeal-inline',
  INSTANT_BOOKING: 'instant-booking-inline',
  RECOMMENDED: 'recommendation-inline',
  REVIEWS: 'sentimental-inline',
};

export const INLINE_SELF_AD_LABELS = {
  CLOSED: 'closed',
  IMPRESSION: 'impression',
};

export const USER_PUSHES = {
  ACCEPT: 'accept',
  COOKIE_NAME: 'hr.push.selected',
  DEFAULT: 'default',
  IMPRESSION: 'impression',
  INVALID: 'invalid',
  REJECT: 'reject',
  VALID: 'valid',
};
