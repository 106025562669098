import { getCurrentRoute } from 'app/route-container';
import { ID_ROUTES } from 'constants/routes';
import PropTypes from 'prop-types';
import React from 'react';


export default function MobileSearchBar({ destinationName, searchMobileRequest, t }) {
  const enabledRoutes = [ID_ROUTES.HOME];
  if (enabledRoutes.includes(getCurrentRoute())) return null;

  return (
    <div className="search-bar-molecule__mobile" id="searchFormMobile" onClick={ searchMobileRequest }>
      <p className={ `search-bar-molecule__mobile__destination ${destinationName ? '-has-destination' : '-has-not-destination'}` }>
        { destinationName || t('Where') }
      </p>
      <div className="search-bar-molecule__mobile__container">
        <span className="search-bar-molecule__mobile__container__icon hundredicons-search" />
      </div>
    </div>
  );
}

MobileSearchBar.defaultProps = {
  destinationName: null,
};

MobileSearchBar.propTypes = {
  destinationName: PropTypes.string,
  searchMobileRequest: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};
