/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
import { AVAILABILITY, ESTABLISHMENT_TYPE, HOTEL_CATEGORIES, PRICE_FILTER_TYPE, PRICE_RANGES } from 'constants/constants';
import { FILTERS_ACTION_NAMES, FILTERS_LABELS } from 'constants/tracking';
import { HEADER_FILTER_TYPE, OPTIONS_FILTER, OPTIONS_FILTER_MAP } from 'constants/filters';
import { trackFilter, trackOrder, trackZonesFilter } from 'services/tracking';
import { dispatch } from 'services/store';
import { filterInteraction } from 'actions/filtersActions';
import { loggerFilters } from 'services/tracking/logger';

export function isButtonFilterNotShown(filterClassName) {
  const filterSelected = document.querySelector(`.${filterClassName}`);
  if (filterSelected) return filterSelected.offsetHeight === 0;
}

export function resetAllFilters() {
  const { resetFiltersAction, updateFiltersAction, filterInteractionAction } = this.props;
  resetFiltersAction();
  updateFiltersAction();
  filterInteractionAction("");
}

export function zonesParser(zones) {

  const initState = this.props.zones.reduce((acc, { id }) => Object.assign({}, acc, { [id]: false }), {});
  const _mapProps = zones.reduce((acc, key) => { acc[key] = true; return acc; }, {});
  return Object.assign({}, initState, _mapProps);
}

export function handleRooms(_this) {

  const MAX_ROOMS = 10;
  const MIN_ROOMS = 0;
  const { roomsSelected } = _this.props;

  function getRooms(rooms) {
    if (rooms === 0) return 'flexible';
    if (rooms > 10) return '10+';
    return rooms;
  }

  return {
    incrementRooms() {
      if (roomsSelected > MAX_ROOMS) return;
      const newRooms = roomsSelected + 1;
      _this.props.selectRoomsNumber(newRooms); // eslint-disable-line
      _this.props.updatePriceRangesAction(); // eslint-disable-line
      trackFilter({
        action: FILTERS_ACTION_NAMES.ROOM_OPTIONS,
        label: getRooms(newRooms),
      });
    },
    decrementRooms() {
      if (roomsSelected <= MIN_ROOMS) return;
      const newRooms = roomsSelected - 1;
      _this.props.selectRoomsNumber(newRooms); // eslint-disable-line
      _this.props.updatePriceRangesAction(); // eslint-disable-line
      trackFilter({
        action: FILTERS_ACTION_NAMES.ROOM_OPTIONS,
        label: getRooms(newRooms),
      });
    },
  };
}

export function cleanValoration(isMobile = false) {
  const allCheckboxesValoration = document.querySelectorAll(isMobile ? '.-filters-mobile' : '.checkbox-valoration-filters');

  if (!this.props.filtersApplied.ratingSelected) {
    Array.from(allCheckboxesValoration).forEach((checkbox) => (checkbox.checked = false)); // eslint-disable-line
  } else {
    Array.from(allCheckboxesValoration).forEach((checkbox, index) => {
      if (index === this.props.filtersApplied.position) {
        checkbox.checked = true;
      } else {
        checkbox.checked = false;
      }
    });
  }
}

function cleanPriceRange() {
  const oldRange = this.props.filtersApplied.priceRange.applied.a;
  if (this.props.filtersApplied && Object.keys(this.props.filtersApplied.priceRange.applied).length === 0) {
    this.props.setPriceRange(!oldRange ? {} : {
      a: oldRange,
      type: this.props.filtersApplied.priceRange.type,
    });
  }

  if (this.props.filtersApplied.priceRange.type === 1) {
    document.querySelector('.toggle-switch__container__switch__label-night').click();
  } else {
    document.querySelector('.toggle-switch__container__switch__label-total').click();
  }
}

export function hideFilters(e) {
  if (e.target.id === 'serp-overlay' || e.target.id === 'serp-header-filters' || e.target.id === 'header-molecule' || e.target.id === 'filter-button-close') {
    clearFilterInteraction.bind(this, e);
    dispatch(filterInteraction(""));
  }
}

export function clearFilterInteraction(prevOpened = "") {
  switch (prevOpened) {
    case HEADER_FILTER_TYPE.PRICE_RANGE:
      cleanPriceRange.call(this);
      break;
    case HEADER_FILTER_TYPE.SERVICES:
      this.props.selectServiceAction(this.props.filtersApplied.servicesFilterApplied);
      break;
    case HEADER_FILTER_TYPE.VALORATION:
      this.props.selectRating(this.props.filtersApplied.ratingSelected, this.props.filtersApplied.position);
      cleanValoration.call(this);
      break;
    case HEADER_FILTER_TYPE.ESTABLISHMENT_TYPE:
      this.props.selectEstablishmentType(this.props.typeFilterApplied);
      this.props.selectStars(this.props.starsSelected);
      break;
    case HEADER_FILTER_TYPE.MORE:
      this.props.selectRoomsNumber(this.props.filtersApplied.roomsSelected);
      this.props.selectZone(this.props.filtersApplied.zonesFilterApplied);
      this.props.selectAvailability(this.props.filtersApplied.availabilityFilterApplied);
      this.props.selectDiscount(this.props.filtersApplied.discountSelected);
      this.props.selectRecommendation(this.props.filtersApplied.recommendationSelected);
      this.props.selectCancellation(this.props.filtersApplied.cancellationSelected);
      if (isButtonFilterNotShown('-estab-type')) this.props.selectEstablishmentType(this.props.typeFilterApplied); this.props.selectStars(this.props.starsSelected);
      if (isButtonFilterNotShown('-valoration')) cleanValoration.call(this);
      if (isButtonFilterNotShown('-price-range')) cleanPriceRange.call(this);
      if (isButtonFilterNotShown('-services')) this.props.selectServiceAction(this.props.filtersApplied.servicesFilterApplied);
      break;
    default:
      return null;
  }
}

export function computeHistogram(establishments, filters) {

  this.HISTOGRAM_DIVISIONS = !global.isPhone ? 60 : 40;

  const histogram = new Array(this.HISTOGRAM_DIVISIONS).fill(0);

  let prop = (filters.range.max - filters.range.min) / (histogram.length - 1);

  prop = prop !== 0 ? prop : 1;
  let priceTotalCount = 0;

  establishments.forEach((estb) => {
    const estbPriceType = filters.showTotal ? estb.minTotalPrice : estb.minDayPrice;

    if (typeof estbPriceType !== typeof Number(1)) return;
    priceTotalCount += estbPriceType;
    let index = Math.floor((estbPriceType - filters.range.min) / prop);

    index = index < this.HISTOGRAM_DIVISIONS ? index : this.HISTOGRAM_DIVISIONS - 1;

    histogram[index]++;
  });

  const averagePrice = Math.round(priceTotalCount / establishments.length) || null;


  return {
    averagePrice,
    averagePriceIndex: Math.floor((averagePrice - filters.range.min) / prop),
    establishmentsTotal: establishments.length,
    histogram,
  };
}

export function optionsParser(availability, discount, recommended, reviews, cancellation) {
  const initState = Object.keys(OPTIONS_FILTER).reduce((acc, key) => { acc[key] = false; return acc; }, {});
  return Object.assign({}, initState, {
    [OPTIONS_FILTER_MAP.CANCELLATION]: cancellation,
    [OPTIONS_FILTER_MAP.HOT_DEALS]: discount,
    [OPTIONS_FILTER_MAP.INSTANTLY_BOOKING]: availability.some(availab => availab === AVAILABILITY.INSTANTLY),
  });
}

export function priceParser(priceRange, sliderRange) {
  const sliderRangeMin = 0;
  const sliderRangeMax = sliderRange.max > this.props.sliderRangePriceLimit ? this.props.sliderRangePriceLimit : sliderRange.max;

  const sliderRangeMinValue = !priceRange.applied.a ? sliderRangeMin : priceRange.applied.a[0];
  const sliderRangeMaxValue = !priceRange.applied.a ? sliderRangeMax : priceRange.applied.a[1] || sliderRangeMax;


  return Object.assign({}, { showTotal: this.props.showTotal }, {
    range: {
      isMax: sliderRangeMaxValue === this.props.sliderRangePriceLimit,
      max: sliderRangeMax,
      min: sliderRangeMin,
      value: {
        max: sliderRangeMaxValue,
        min: sliderRangeMinValue,
      },
    },
  });
}

export function handleToogle(toogle) {
  this.setState((prevState) => {
    const newToogles = Object.assign({}, prevState.toogles, { [toogle]: !prevState.toogles[toogle] });
    return { toogles: newToogles };
  });
}

export function handleOnClick(evt, type, ...args) {

  const { target } = evt;
  const allCheckboxesValoration = document.querySelectorAll('.checkbox-valoration-filters');

  switch (type) {
    case 'reset':
      this.props.resetFiltersAction();
      this.props.updatePriceRangesAction();
      trackFilter({
        action: FILTERS_ACTION_NAMES.DELETE_FILTERS,
        label: '',
      });
      break;

    case 'orderChange':
      this.props.selectEstablishmentsOrder(args[0].orderType);
      break;

    case 'PriceShowPriceNight':
    case 'PriceShowPriceTotal': {
      const priceType = type === 'PriceShowPriceNight' ? PRICE_FILTER_TYPE.DAY : PRICE_FILTER_TYPE.TOTAL;
      this.props.priceFilterTypeApply(priceType);
      trackFilter({
        action: FILTERS_ACTION_NAMES.PRICE_TYPE,
        label: FILTERS_LABELS.PRICE_TYPE[priceType],
      });
      break;
    }

    case 'selectRating': {
      const rating = Number(args[0].establishmentRating);
      const position = Number(args[0].position);
      this.props.selectRating(rating, position);
      this.props.updatePriceRangesAction();

      trackFilter({
        action: FILTERS_ACTION_NAMES.RATING,
        label: FILTERS_LABELS.RATING[rating],
      });
      break;
    }

    case 'starSelection': {
      const stars = Number(args[0].starSelection);
      if (!args[0].filters.type[ESTABLISHMENT_TYPE.APARTHOTEL] && !args[0].filters.type[ESTABLISHMENT_TYPE.HOTEL]) {
        this.props.selectEstablishmentType(ESTABLISHMENT_TYPE.APARTHOTEL);
        this.props.selectEstablishmentType(ESTABLISHMENT_TYPE.HOTEL);
        HOTEL_CATEGORIES.forEach((estbType) => {
          trackFilter({
            action: FILTERS_ACTION_NAMES.ESTABLISHMENT_TYPE,
            label: `${FILTERS_LABELS.ESTABLISHMENT_TYPE[estbType]}-${target.checked ? 'activate' : 'deactivate'}`,
          });
        });
      }
      this.props.selectStars(stars);
      this.props.updatePriceRangesAction();
      trackFilter({
        action: FILTERS_ACTION_NAMES.STARS,
        label: `${stars}-${target.checked ? 'activate' : 'deactivate'}`,
      });
      break;
    }

    case 'typeEstablishmentChange': {
      const estbType = Number(args[0].establishmentType);
      const typeEstablishmentSelected = args[1].deleteStars.type;
      const starsSelected = args[1].deleteStars.stars;
      if ((!typeEstablishmentSelected[ESTABLISHMENT_TYPE.HOTEL] && typeEstablishmentSelected[ESTABLISHMENT_TYPE.APARTHOTEL] && estbType === ESTABLISHMENT_TYPE.APARTHOTEL) || (!typeEstablishmentSelected[ESTABLISHMENT_TYPE.APARTHOTEL] && typeEstablishmentSelected[ESTABLISHMENT_TYPE.HOTEL] && estbType === ESTABLISHMENT_TYPE.HOTEL)) {
        this.props.selectStars([]);
        Object.values(starsSelected).forEach((star, index) => {
          if (star) {
            trackFilter({
              action: FILTERS_ACTION_NAMES.STARS,
              label: `${index + 1}-deactivate`,
            });
          }
        });
      }
      this.props.selectEstablishmentType(estbType);
      this.props.updatePriceRangesAction();
      trackFilter({
        action: FILTERS_ACTION_NAMES.ESTABLISHMENT_TYPE,
        label: `${FILTERS_LABELS.ESTABLISHMENT_TYPE[estbType]}-${target.checked ? 'activate' : 'deactivate'}`,
      });
      break;
    }

    case 'serviceChange': {
      const serviceType = Number(args[0].typeService);
      const { checked } = args[0];
      this.props.selectServiceAction(serviceType);
      this.props.updatePriceRangesAction();
      if (checked) {
        trackFilter({
          action: FILTERS_ACTION_NAMES.SERVICES,
          label: FILTERS_LABELS.SERVICES[serviceType],
        });
      }
      break;
    }

    case 'optionsChange': {
      const availab = args[0].optionsType;
      switch (availab) {
        case OPTIONS_FILTER_MAP.HOT_DEALS:
          this.props.selectDiscount(target.checked);
          trackFilter({
            action: FILTERS_ACTION_NAMES.HOT_DEALS,
            label: `${target.checked ? 'activate' : 'deactivate'}`,
          });
          break;
        case OPTIONS_FILTER_MAP.CANCELLATION:
          this.props.selectCancellation(target.checked);
          trackFilter({
            action: FILTERS_ACTION_NAMES.CANCELLATION,
            label: `${target.checked ? 'activate' : 'deactivate'}`,
          });
          break;
        default:
          this.props.selectAvailability(Number(availab));
          trackFilter({
            action: FILTERS_ACTION_NAMES.AVAILABILITY,
            label: `${FILTERS_LABELS.AVAILABILITY[availab]}${target.checked ? 'activate' : 'deactivate'}`,
          });
          break;
      }
      this.props.updatePriceRangesAction();
      break;
    }

    case 'zonesChange': {
      const zonesType = Number(args[0].zonesType);
      this.props.selectZone(zonesType);
      this.props.updatePriceRangesAction();
      trackFilter({
        action: FILTERS_ACTION_NAMES.NEIGHBORHOODS,
        label: target.checked ? 'activate' : 'deactivate',
      });
      break;
    }

    case 'establishmentMinusRooms':
      handleRooms(this).decrementRooms.call(this);
      break;

    case 'establishmentMoreRooms':
      handleRooms(this).incrementRooms.call(this);
      break;

    case 'rangeType': {
      const rangeType = args[0].range;
      this.props.selectPriceRange(rangeType);
      if (target.checked) {
        trackFilter({
          action: FILTERS_ACTION_NAMES.PRICE_RANGES,
          label: FILTERS_LABELS.PRICE_RANGES[this.props.priceRange.type][rangeType].join(', '),
        });
      }
      break;
    }

    case 'handleOnValuesUpdated': {
      const sliderRangeMin = 0;
      const sliderRangeMax = this.props.sliderRange.max;
      const sameSliderRanges = evt.values[0] === sliderRangeMin && (evt.values[1] === sliderRangeMax || evt.values[1] === this.props.sliderRangePriceLimit);
      const range = evt.values[1] < this.props.sliderRangePriceLimit ? [evt.values[0], evt.values[1]] : [evt.values[0], null];

      this.props.setPriceRange(sameSliderRanges ? {} : {
        a: range,
      });
      break;
    }

    case 'cancelFilters':
      switch (args[0]) {
        case (HEADER_FILTER_TYPE.ESTABLISHMENT_TYPE):
          this.props.selectEstablishmentType([]);
          this.props.selectStars([]);
          break;
        case (HEADER_FILTER_TYPE.PRICE_RANGE):
          this.props.resetPriceRangeAction();
          break;
        case (HEADER_FILTER_TYPE.VALORATION):
          this.props.selectRating(false);
          Array.from(allCheckboxesValoration).map(checkbox => (checkbox.checked = false)); // eslint-disable-line
          break;
        case (HEADER_FILTER_TYPE.SERVICES):
          this.props.selectServiceAction([]);
          break;
        case (HEADER_FILTER_TYPE.MORE):
          this.props.selectRoomsNumber(0);
          this.props.selectZone([]);
          this.props.selectAvailability([]);
          this.props.selectDiscount(false);
          this.props.selectRecommendation(false);
          this.props.selectCancellation(false);
          if (isButtonFilterNotShown('-services')) this.props.selectServiceAction([]);
          if (isButtonFilterNotShown('-valoration')) this.props.selectRating(false);
          if (isButtonFilterNotShown('-estab-type')) this.props.selectEstablishmentType([]); this.props.selectStars([]);
          if (isButtonFilterNotShown('-price-range')) this.props.resetPriceRangeAction();
          break;
        case ('cancel-mobile'):
          cleanPriceRange.call(this);
          this.props.selectServiceAction(this.props.filtersApplied.servicesFilterApplied);
          this.props.selectRating(this.props.filtersApplied.ratingSelected, this.props.filtersApplied.position);
          cleanValoration.call(this);
          this.props.selectEstablishmentType(this.props.typeFilterApplied);
          this.props.selectRoomsNumber(this.props.filtersApplied.roomsSelected);
          this.props.selectZone(this.props.filtersApplied.zonesFilterApplied);
          this.props.selectAvailability(this.props.filtersApplied.availabilityFilterApplied);
          this.props.selectDiscount(this.props.filtersApplied.discountSelected);
          this.props.selectRecommendation(this.props.filtersApplied.recommendationSelected);
          this.props.selectCancellation(this.props.filtersApplied.cancellationSelected);
          this.props.selectStars(this.props.starsSelected);
          break;
        default:
          this.props.resetFiltersAction();
          this.props.updateFiltersAction();
          break;
      }
      this.props.updateFiltersAction();
      if (this.props.handleClose) {
        this.props.handleClose.call(this);
      } else {
        this.props.filterInteractionAction("");
      }
      break;

    case 'applyFilters':
      this.props.updateFiltersAction();
      trackFilter({ action: FILTERS_ACTION_NAMES.APPLY_FILTERS, label: '' });
      this.props.filterInteractionAction("");

      if (this.props.establishmentOrder !== this.props.orderingSelected && (global.isTablet || global.isPhone)) {
        this.props.orderEstablishments(this.props.orderingSelected);
        trackOrder(this.props.orderingSelected);
      }

      if (this.props.priceRange.applied && this.props.priceRange.applied.a) {
        trackFilter({
          action: FILTERS_ACTION_NAMES.PRICE_RANGES,
          label: this.props.priceRange.applied.a.map(price => this.props.t('£{price}', { price })).join(', '),
        });
      }

      if (this.props.zonesFilterApplied.length) {
        trackZonesFilter({
          location: this.props.destination,
          zone: this.props.zonesFilterApplied.reduce((string, zoneId, index) => {
            const zoneName = this.props.zones.find(zone => zone.id === zoneId).name;
            return string + (index > 0 ? ', ' : '') + zoneName;
          }, ''),
        });
      }

      loggerFilters();
      this.setState({ updated: true }, this.props.handleClose);
      break;

    case 'toogle':
      this.handleToogle.call(this, args);
      if (args[0] === 'valoration') cleanValoration.call(this, true);
      break;

    default:
      break;
  }
}

export function checkboxParser(_from, _to) {
  const initState = _from.reduce((acc, key) => { acc[key] = false; return acc; }, {});
  const _mapProps = _to.reduce((acc, key) => { acc[key] = true; return acc; }, {});

  return Object.assign({}, initState, _mapProps);
}


export function priceRangeTypeParser(priceRange) {
  return PRICE_RANGES[priceRange.type === PRICE_FILTER_TYPE.TOTAL ? 'TOTAL' : 'DAY'];
}
