import { getURIS } from 'constants/constants';
import resource from './JsonApiResource';


const BASE_URL = getURIS().RECOMMENDATION;
const path = `${BASE_URL}/v1/user_recommendations`;

export async function getUserRecommendations(userId, establishmentIds) {
  const data = {
    establishments_ids: establishmentIds,
    user_id: userId,
  };
  const response = await resource.post(path, data);
  if (response.status === 200) {
    const { recommendations } = await response.json();
    return recommendations;
  }
  return {};
}
