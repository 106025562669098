import { COUNTRY_CURRENCY, COUNTRY_CURRENCY_DISCARDED, URL } from 'molecules/language-currency/constants';
import React, { Component } from 'react';
import PropTypes from 'prop-types';


class LanguageCurrency extends Component {
  constructor(props) {
    super(props);
    this.handleHover = this.handleHover.bind(this);
    this.state = {
      isHovered: false,
    };
  }

  orderAphabetical(country) {
    return [(COUNTRY_CURRENCY[country].translatedName)];
  }

  handleHover() {
    const { isHovered } = this.state;
    this.setState({ isHovered: !isHovered });
  }

  render() {
    const { culture, t } = this.props;
    const { isHovered } = this.state;
    const selectorClass = isHovered ? "-showing" : "-not-showing";

    return (
      <div
        className="language-currency"
        onClick={ (global.isPhone || global.isTablet) ? this.handleHover : () => {} }
        onMouseEnter={ !(global.isPhone || global.isTablet) ? this.handleHover : () => {} }
        onMouseLeave={ !(global.isPhone || global.isTablet) ? this.handleHover : () => {} }
      >
        <span className="language-currency__selected">
          <span className="language-currency__image-container">
            <img alt="" className={ `sprite-flags__${COUNTRY_CURRENCY[culture].flag}` } src="https://static.100r.systems/img/flags/sprite-flags.png" />
          </span>
          <p className="language-currency__selected__text">
            { `${(COUNTRY_CURRENCY[culture].translatedName)} (${COUNTRY_CURRENCY[culture].currency})` }
          </p>
          <span className={ `language-currency__selected__icon hundredicons-arrow-down-filled ${isHovered ? '-arrow' : ''}` } />
        </span>
        <ul className={ `language-currency__list ${selectorClass}` }>
          <li className="language-currency__list__item">
            <i className="language-currency__list__item__icon hundredicons-cross" onClick={ this.handleHover } />
          </li>
          { Object.keys(COUNTRY_CURRENCY).map((country, i) => {
            if (country === culture || COUNTRY_CURRENCY_DISCARDED.includes(country)) return null;
            return (
              <li key={ i } className="language-currency__list__item">
                <a
                  className="language-currency__list__item__link"
                  href={ `${URL}${COUNTRY_CURRENCY[country].domain}/?noredirect=true` }
                >
                  <span className="language-currency__image-container">
                    <img alt="" className={ `sprite-flags__${COUNTRY_CURRENCY[country].flag}` } src="https://static.100r.systems/img/flags/sprite-flags.png" />
                  </span>
                  <p className="language-currency__list__item__link__text">
                    { `${this.orderAphabetical(country, t)[0]} (${COUNTRY_CURRENCY[country].currency})` }
                  </p>
                </a>
              </li>
            );
          }) }
        </ul>
      </div>
    );
  }
}

LanguageCurrency.propTypes = {
  culture: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};


export default LanguageCurrency;
