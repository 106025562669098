import { APP_ACTIONS } from 'constants/actions';

export function setStandAloneMode() {
  return {
    type: APP_ACTIONS.SET_STANDALONE_MODE,
  };
}

export function setNotificationsSupported(isSupported) {
  return {
    isSupported,
    type: APP_ACTIONS.NOTIFICATIONS_SUPPORTED,
  };
}

export function subscriptionChange(isSubscribed) {
  return {
    isSubscribed,
    type: APP_ACTIONS.SUBSCRIPTION_CHANGE,
  };
}

export function pushNotificationsPermissionGranted(permission) {
  return {
    permission,
    type: APP_ACTIONS.SET_PUSH_NOTIFICATIONS_PERMISSION,
  };
}

export const toggleCookieBanner = () => ({ type: APP_ACTIONS.TOGGLE_COOKIE_BANNER });
