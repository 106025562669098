import { FILTERS_ACTIONS } from 'constants/actions';
import { PRICE_FILTER_TYPE } from 'constants/constants';

// Display filter
export function displayFilter(filter) {
  return function (dispatch) {
    dispatch({
      filter,
      type: FILTERS_ACTIONS.DISPLAY_FILTER,
    });
  };
}

// Reset filters
export function resetFilters(filters = null) {
  return {
    filters,
    type: FILTERS_ACTIONS.INITIALIZE,
  };
}

export function selectEstablishmentsOrder(order) {
  return {
    order,
    type: FILTERS_ACTIONS.ORDERING,
  };
}

// Price filter
export const selectPriceRange = priceRangeSelected => (dispatch) => {
  dispatch({
    rangeIndex: priceRangeSelected,
    type: FILTERS_ACTIONS.SELECT_PRICE_RANGE,
  });
};

export function resetPriceRange() {
  return {
    type: FILTERS_ACTIONS.RESET_PRICE_RANGE,
  };
}

// Price filter MODAL
export const setPriceRange = values => (dispatch) => {
  dispatch({
    type: FILTERS_ACTIONS.SET_PRICE_RANGE,
    values,
  });
};

// Price type
export const priceFilterTypeApply = priceType => ({
  priceType,
  type: FILTERS_ACTIONS.SELECT_PRICE_FILTER_TYPE,
});

// Services filter
export const selectService = services => ({
  type: FILTERS_ACTIONS.SELECT_SERVICE,
  services: typeof services === 'string' ? services.split(',').map(a => parseInt(a, 10)).filter(a => !isNaN(a)) : services, // eslint-disable-line
});

// Availability filter
export const selectAvailability = availability => ({
  availability,
  type: FILTERS_ACTIONS.SELECT_AVAILABILITY,
});

// Type filter
export const selectEstablishmentType = establishmentType => ({
  establishmentType,
  type: FILTERS_ACTIONS.SELECT_ESTABLISHMENT_TYPE,
});

export const selectStars = stars => ({
  stars,
  type: FILTERS_ACTIONS.SELECT_STARS,
});

// Rooms filter
export const selectRoomsNumber = roomsNumberSelected => ({
  number: roomsNumberSelected,
  type: FILTERS_ACTIONS.SELECT_ROOMS_NUMBER,
});

// Zones filter
export const selectZone = zoneId => ({
  type: FILTERS_ACTIONS.SELECT_ZONE,
  zone: zoneId,
});

// Rating filter
export const selectRating = (rating, position) => ({
  position,
  rating,
  type: FILTERS_ACTIONS.SELECT_RATING,
});

// Discount filter
export const selectDiscount = discountSelected => ({
  discountSelected,
  type: FILTERS_ACTIONS.SELECT_DISCOUNT,
});

// Recommendation filter
export const selectRecommendation = recommendationSelected => ({
  recommendationSelected,
  type: FILTERS_ACTIONS.SELECT_RECOMMENDATION,
});

export const selectCancellation = cancellationSelected => ({
  cancellationSelected,
  type: FILTERS_ACTIONS.SELECT_CANCELLATION,
});

export const updatePriceRanges = () => (dispatch, getState) => {
  dispatch({
    type: FILTERS_ACTIONS.UPDATE_PRICE_RANGES,
  });

  const filterState = getState().filtersReducer;
  if (filterState.priceRange.applied && filterState.priceRange.applied.a) {
    const showTotal = filterState.priceRange.type === PRICE_FILTER_TYPE.TOTAL;
    const { sliderRange } = filterState;
    const minRangePrice = 0;
    const maxRangePrice = showTotal ? sliderRange.maxTotalPrice : sliderRange.maxDayPrice;
    const minPrice = (
      filterState.priceRange.applied.a[0] < minRangePrice
      || filterState.priceRange.applied.a[0] > maxRangePrice
      || filterState.priceRange.applied.a[0] === 0
    ) ? minRangePrice : filterState.priceRange.applied.a[0];
    const maxPrice = (
      filterState.priceRange.applied.a[1] && (
        filterState.priceRange.applied.a[1] < minRangePrice
        || filterState.priceRange.applied.a[1] > maxRangePrice
        || filterState.priceRange.applied.a[1] === 0
      )
    ) ? maxRangePrice : filterState.priceRange.applied.a[1];
    return dispatch(setPriceRange({ a: [minPrice, maxPrice] }));
  }

  return dispatch(setPriceRange({}));
};

export const updateFilters = () => (dispatch, getState) => {
  dispatch({
    filters: getState().filtersReducer,
    type: FILTERS_ACTIONS.UPDATE_FILTERS,
  });
};

// header filters showing
export function filterInteraction(filtersShowed = "") {
  return {
    filtersShowed,
    type: FILTERS_ACTIONS.FILTER_INTERACTION,
  };
}
