import encB64 from 'crypto-js/enc-base64';
import { getClientSecretKey } from 'constants/constants';
import hmacSHA256 from 'crypto-js/hmac-sha256';


export function signRequest(content, key = null) {
  let str = JSON.stringify(content);
  if (str.charAt(0) === '"' && str.charAt(str.length - 1) === '"') str = str.substr(1, str.length - 2);
  str = str.replace(/^(https:\/\/|http:\/\/|\/\/)/, '');

  const hash = hmacSHA256(str, key || getClientSecretKey());
  return encB64.stringify(hash);
}
