import { applyMiddleware, compose, createStore } from 'redux';
import { getLocalStorageState, persistStore } from 'services/local-storage';
import thunkMiddleware from 'redux-thunk';

export function getState() {
  return global.hr.store.getState();
}

export function dispatch(...args) {
  return global.hr.store.dispatch(...args);
}

export function getStore(reducer) {
  global.hr.store = applyMiddleware(thunkMiddleware)(createStore)(reducer, getLocalStorageState(), compose(
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
  ));
  global.hr.store.subscribe(() => {
    persistStore(global.hr.store.getState());
  });
  return global.hr.store;
}
