import Button from 'atoms/button';
import PropTypes from 'prop-types';
import React from 'react';


export default function BannerFooterRedirect({ className, handleOnClick, text }) {
  return (
    <div className={ `smart-banner-footer-redirect ${className}` }>
      <div className="smart-banner-footer-redirect__inner">
        <span className="smart-banner-footer-redirect__inner__text">
          { text ? text.text : '' }
        </span>
        <Button
          className="smart-banner-footer-redirect__inner__denial-button -is-rounded"
          handleOnClick={ () => handleOnClick(false) }
        >
          { text ? text.decline : '' }
        </Button>
        <Button
          className="smart-banner-footer-redirect__inner__confirmation-button -is-rounded -afterMidnight -filled"
          handleOnClick={ () => handleOnClick(true) }
        >
          { text ? text.accept : '' }
        </Button>
        <span
          className="smart-banner-footer-redirect__inner__cross hundredicons-cross"
          onClick={ () => handleOnClick(false) }
        />
      </div>
    </div>
  );
}

BannerFooterRedirect.defaultProps = {
  className: '',
  text: {},
};

BannerFooterRedirect.propTypes = {
  className: PropTypes.string,
  handleOnClick: PropTypes.func.isRequired,
  text: PropTypes.shape({
    accept: PropTypes.string,
    decline: PropTypes.string,
    text: PropTypes.string,
  }),
};
