import { MAX_NUMBER_OF_RECENT_SEARCHES_TO_SHOW } from 'constants/constants';
import moment from 'moment';
import { RECENT_SEARCHES_ACTIONS } from 'constants/actions';


const initialState = {
  storedSearches: [],
};

export function recentSearchesReducer(state = initialState, action) {

  if (action.type === RECENT_SEARCHES_ACTIONS.SAVE) {
    // Remove repeated destinations
    let storedSearches = state.storedSearches.filter(search => (
      search.name !== action.search.name
    ));
    // Store destination
    action.search.saveDate = moment().toISOString();

    // Check the maximum destinations allowed
    const length = storedSearches.unshift(action.search);
    if (length > MAX_NUMBER_OF_RECENT_SEARCHES_TO_SHOW) {
      storedSearches = storedSearches.slice(0, MAX_NUMBER_OF_RECENT_SEARCHES_TO_SHOW);
    }
    return {
      ...state,
      storedSearches,
    };
  }

  return state;
}
