import Footer from 'molecules/footer';
import { getContext } from 'services/context';
import React from 'react';


function FooterContainer(props) {
  return (<Footer { ...props } />);
}

export default getContext(FooterContainer);
