import PropTypes from 'prop-types';


const { arrayOf, shape, string, bool, number, oneOf, oneOfType } = PropTypes;

export const checkboxInterface = shape({
  active: bool,
  checked: bool,
  clickout: string,
  cpc: number,
  destination: string,
  id: string,
  name: string,
  order: number,
  transactionId: string,
  visibleName: string,
});

export const recentSearchesInterface = arrayOf(
  shape({
    dateArrival: number,
    dateLeaving: number,
    guestsNumber: number,
    id: string,
    name: string,
    query: string,
    saveDate: string,
  })
);

export const destinationInterface = shape({
  country: string,
  is_new: bool,
  lat: number,
  lat_ne: number,
  lat_sw: number,
  lng: number,
  lng_ne: number,
  lng_sw: number,
  name: string,
  place_id: string,
  polygon_id: number,
});

export const homeSeoPlaceInterface = shape({
  places: arrayOf(
    shape({
      href: string,
      img: string,
      name: string,
      nofollow: bool,
      number: string,
    })
  ),
});

export const establishmentImagesInterface = arrayOf(
  shape({
    big: string,
    medium: string,
    partners: arrayOf(string),
    position: shape(),
    small: string,
    url: string,
  }),
);

export const partnerInterface = shape({
  cancellation_policy: shape(),
  clickout_url: string,
  instantly_booking: bool,
  num_comments: number,
  partner_id: number,
  price_per_day: number,
  price_total: number,
  quality_score: number,
});

export const establishmentInterface = shape({
  adjective: string,
  area: number,
  availability: number,
  cancellationPolicy: oneOfType([bool, string]),
  category: oneOf([1, 2, 3, 4, 5]),
  categoryLiteral: string,
  cheapestPartner: number,
  city: string,
  combinedRating: number,
  comments: arrayOf(shape({
    comments: number.isRequired,
    partner: string.isRequired,
    partnerId: number.isRequired,
  })),
  computedRating: number,
  discount: number,
  features: arrayOf(number),
  id: number,
  images: establishmentImagesInterface,
  latitude: number,
  longitude: number,
  maxDayPrice: number,
  maxGuests: number,
  maxTotalPrice: number,
  minDayPrice: number,
  minTotalPrice: number,
  numberOfComments: number,
  numImages: number,
  partners: arrayOf(partnerInterface),
  polygons: arrayOf(number),
  qualityScore: number,
  rating: number,
  recommendation: number,
  rooms: number,
  titlePreposition: string,
});
