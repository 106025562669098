import { ADS, COOKIES, MODALS } from 'constants/constants';
import calculateRadius, { pricePerDayShouldUpdate } from 'helpers/geometry-helper';
import { checkboxInterface, destinationInterface, recentSearchesInterface } from 'constants/interfaces';
import { clearDestination, destinationTyping, selectDate, selectDestination, selectGuests, submitRequest, updateSearchSubsection } from 'actions/searchFormActions';
import { getCookieValue, HALF_HOUR_IN_DAYS, isCookieSet, setCookie } from 'helpers/cookies-helper';
import { hideModalByName, showModal } from 'actions/modals';
import { ID_ROUTES, ROUTES_CHECKBOXES } from 'constants/routes';
import React, { Component } from 'react';
import { SEARCH_BAR_ACTION, SEARCH_BAR_LABEL } from 'constants/tracking';
import { trackCheckbox, trackSearchBar } from 'services/tracking';
import { bindActionCreators } from 'redux';
import { changeCheckboxChecked } from 'actions/adActions';
import { connect } from 'react-redux';
import { formatFromString } from 'helpers/dates-helper';
import { gACheckbox } from 'services/tracking/g-analytics';
import { getContext } from 'services/context';
import { getCurrentRoute } from 'app/route-container';
import { gtmMicroconversions } from 'services/tracking/gtm/gtm-events';
import moment from 'moment';
import PropTypes from 'prop-types';
import { retrieveCheapestDays } from 'actions/predictionActions';
import { SEARCH_SUBSECTIONS } from 'services/tracking/logger';
import SearchBar from 'molecules/search-bar';


export class SearchBarContainer extends Component {
  constructor(props) {
    super(props);
    this.searchBarActionableValue = SEARCH_BAR_LABEL[SEARCH_BAR_ACTION.ACTIONABLE].LOCATION_FIX;
    this.state = {
      closeCalendar: false,
      showCalendar: false,
    };
  }

  componentDidMount() {
    if (global.location.pathname.startsWith(`/${global.hr.intl.ROUTES.blog}`)
      && !global.isPhone
      && !global.isTablet
      && !isCookieSet(COOKIES.CONSECUTIVE_VISIT_IN_SESSION)
    ) { this.setState({ showCalendar: true }); } // eslint-disable-line
    const container = document.querySelector('.search-bar-molecule__desktop__box__guests');
    if (container) container.addEventListener('touchstart', this.preventZoom);
    const destinationBox = document.querySelector('.search-bar-molecule__desktop__box__destination');
    const destinationBoxInput = document.querySelector('.geosuggest__input');
    if (getCurrentRoute() === ID_ROUTES.HOME) {
      destinationBox.classList.add('-grow');
      if (!global.isPhone && !global.isTablet) {
        destinationBoxInput.focus();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const { retrieveCheapestDaysAction, checkboxes } = this.props;
    if (pricePerDayShouldUpdate(this.props, nextProps)) {
      const { destination: { country, lat, lng, lat_ne: latNe, lat_sw: latSw, lng_ne: lngNe, lng_sw: lngSw } } = nextProps;
      const radius = calculateRadius(latNe, latSw, lngNe, lngSw);
      retrieveCheapestDaysAction(country, lat, lng, radius);
    }

    if (JSON.stringify(nextProps.checkboxes) !== JSON.stringify(checkboxes) && ROUTES_CHECKBOXES.includes(getCurrentRoute())) {
      if (!checkboxes.length || (nextProps.checkboxes.length && nextProps.checkboxes[0].id !== checkboxes[0].id)) {
        gtmMicroconversions('checkbox-impression', this.getEventDetail(nextProps));
        gACheckbox(nextProps.checkboxes, 'impression', getCurrentRoute());
      }
    }
  }

  componentWillUnmount() {
    const container = document.getElementsByClassName('search-bar-molecule__desktop__box__guests')[0];
    if (container) container.removeEventListener('touchstart', this.preventZoom);
  }

  onGuestChangeHandler(...args) {
    const { selectGuestsAction } = this.props;
    selectGuestsAction.apply(this, args);
  }

  getEventDetail(props) {
    const partner = props.checkboxes[0].visibleName;
    const destination = props.destination.name;
    const guests = props.guestsNumber;
    const startDate = formatFromString(props.dateArrival, 'DD-MM-YYYY');
    const endDate = formatFromString(props.dateLeaving, 'DD-MM-YYYY');
    return `${partner}/${destination}/${guests}/${startDate}_${endDate}`;
  }

  toggleCalendar(mustClose = false) {
    const { closeCalendar } = this.state;
    if (mustClose !== closeCalendar) this.setState({ closeCalendar: mustClose });
  }

  shouldShowCheckboxes() {
    const { route, isPhone, isTablet } = this.props;
    return ROUTES_CHECKBOXES.includes(route || getCurrentRoute()) || !!isPhone || !!isTablet;
  }

  handleDateRangeSelect(dateRange) {
    this.storeDates(dateRange.startDate, dateRange.endDate);
    if (dateRange.endDate) {
      this.setState({ showCalendar: false });
    }
    if (dateRange.startDate) {
      dateRange.endDate = null;
    }
    return dateRange;
  }

  openSearchMobile() {
    const { showModalAction } = this.props;
    showModalAction(MODALS.SEARCH_MOBILE);
  }

  storeDates(start, end) {
    const { selectDateAction } = this.props;
    const startString = moment.isMoment(start) ? start.valueOf() : null;
    const endString = moment.isMoment(end) ? end.valueOf() : null;
    if (startString) (global.IntentMediaProperties || {}).travel_date_start = formatFromString(start, 'YYYYMMDD');
    if (endString) (global.IntentMediaProperties || {}).travel_date_end = formatFromString(end, 'YYYYMMDD');
    selectDateAction(startString, endString);
  }

  handleSearchSubmit(isOnCalendar, evt) {
    const { checkboxes, destination, guestsNumber, isOnModal, dateArrival, dateLeaving, isPhone, isTablet, updateSearchSubsectionAction, submitRequestAction, hideModalAction } = this.props;
    evt.preventDefault();
    if (isOnCalendar) this.toggleCalendar(true);
    submitRequestAction().then((err) => {
      if (destination.name.length === 0) {
        if (!isOnModal) {
          const placeHolder = document.querySelector('.geosuggest__input');
          placeHolder.className += " -formInvalid";
          setTimeout(() => placeHolder.focus(), 750);
        } else {
          const placeHolderModal = document.getElementsByClassName('geosuggest__input')[1];
          placeHolderModal.className += " -formInvalid";
          placeHolderModal.focus();
        }
        return null;
      }
      if (destination.name.length !== 0 && (!dateArrival || !dateLeaving)) {
        const placeHolder = document.querySelectorAll('.DateInput');
        if (!dateArrival) {
          placeHolder[0].style.color = '#FF7165';
          if (!isPhone && !isTablet) this.setState({ showCalendar: true });
        }
        if (dateArrival && !dateLeaving) {
          placeHolder[1].style.color = '#FF7165';
          if (!isPhone && !isTablet) this.setState({ showCalendar: true });
        }
        return null;
      }

      if (err && destination.name.length !== 0 && (!dateArrival || !dateLeaving)) return this.setState({ showCalendar: true });

      if (isOnModal) hideModalAction(MODALS.SEARCH_MOBILE);

      window.scrollTo(0, 0);
      checkboxes.forEach((partner, i) => {
        const data = Object.assign({}, partner, { destination, position: i, type: ADS.CLICK });
        setCookie(COOKIES.SHOWED_CHECKBOX, `${getCookieValue(COOKIES.SHOWED_CHECKBOX) || ''},${partner.id}`, HALF_HOUR_IN_DAYS);
        if (partner.checked) {
          trackCheckbox(data);
          setCookie(COOKIES.OPENED_CHECKBOX, `${getCookieValue(COOKIES.OPENED_CHECKBOX) || ''},${partner.id}`, HALF_HOUR_IN_DAYS);
          gtmMicroconversions('checkbox-click', this.getEventDetail(this.props));
          gACheckbox([partner], 'click', getCurrentRoute());
        }
      });
      updateSearchSubsectionAction(isOnCalendar ? SEARCH_SUBSECTIONS.CALENDAR : SEARCH_SUBSECTIONS.SEARCHBAR);
      trackSearchBar(SEARCH_BAR_ACTION.OPEN_CALENDAR, SEARCH_BAR_LABEL[SEARCH_BAR_ACTION.OPEN_CALENDAR].CHECK_IN);
      trackSearchBar(SEARCH_BAR_ACTION.OPEN_CALENDAR, SEARCH_BAR_LABEL[SEARCH_BAR_ACTION.OPEN_CALENDAR].CHECK_OUT);
      trackSearchBar(SEARCH_BAR_ACTION.SEARCH_LOCATION, `${destination.name} ${destination.country}`);
      trackSearchBar(SEARCH_BAR_ACTION.GUESTS, String(guestsNumber));
      trackSearchBar(SEARCH_BAR_ACTION.ACTIONABLE, this.searchBarActionableValue);
    });
  }

  handleDestinationSelected() {
    const { dateArrival, dateLeaving } = this.props;
    this.searchBarActionableValue = SEARCH_BAR_LABEL[SEARCH_BAR_ACTION.ACTIONABLE].NEW_SEARCH;
    if (!dateArrival && !dateLeaving) {
      this.setState({ showCalendar: true });
    }
  }

  handleSelectDestination(...args) {
    const { selectDestinationAction } = this.props;
    const [destination, country] = args;
    const [city] = destination.split(',');
    (global.IntentMediaProperties || {}).hotel_country = country;
    (global.IntentMediaProperties || {}).hotel_city = city;
    this.handleDestinationSelected.call(this);
    selectDestinationAction(destination, 'country', 'placeId');
  }

  hideCalendar() { this.setState({ showCalendar: false }); }

  showColorBox() {
    const enabledRoutes = [ID_ROUTES.HOME];
    return (enabledRoutes.includes(getCurrentRoute()));
  }

  preventZoom(e) {
    const t2 = e.timeStamp;
    const t1 = e.currentTarget.dataset.lastTouch || t2;
    const dt = t2 - t1;
    const fingers = e.touches.length;
    e.currentTarget.dataset.lastTouch = t2;
    if (!dt || dt > 500 || fingers > 1) return;

    e.preventDefault();
    e.target.click();
  }

  scrollFocus() {
    const searchbox = document.querySelector(".search-bar-molecule__desktop__box__destination");
    document.body.scrollTop += searchbox.getBoundingClientRect().top - 70;
  }

  render() {
    const {
      className, destination, domainCulture, guestsNumber, checkboxes, storedSearches, searchInputFocused, isLandingSeo, isOnModal, isLoading, t,
      changeCheckboxCheckedAction, destinationTypingAction, selectGuestsAction, clearDestinationAction,
    } = this.props;
    const { showCalendar, closeCalendar } = this.state;

    return (
      <SearchBar
        adCheckboxes={ ROUTES_CHECKBOXES.includes(getCurrentRoute()) ? checkboxes : [] }
        className={ className }
        clearDestination={ clearDestinationAction }
        closeCalendar={ closeCalendar }
        destination={ destination }
        destinationTyping={ destinationTypingAction }
        domainCulture={ domainCulture }
        guests={ guestsNumber }
        hideCalendar={ this.hideCalendar.bind(this) }
        isLandingSeo={ isLandingSeo }
        isLoading={ isLoading }
        isOnModal={ isOnModal }
        needsButton={ false }
        onCheckboxChange={ changeCheckboxCheckedAction }
        onDatesChange={ this.handleDateRangeSelect.bind(this) }
        onDestinationSelected={ this.handleDestinationSelected.bind(this) }
        onGuestChange={ selectGuestsAction }
        onRecenSearchSelect={ () => {} }
        onSubmitSearch={ isOnCalendar => this.handleSearchSubmit.bind(this, isOnCalendar) }
        scrollFocus={ this.scrollFocus }
        searchInputFocused={ searchInputFocused }
        searchMobileRequest={ this.openSearchMobile.bind(this) }
        selectDestination={ this.handleSelectDestination.bind(this) }
        showCalendar={ showCalendar }
        showInsideColorBox={ this.showColorBox() }
        storedSearches={ storedSearches }
        t={ t }
        toggleCalendar={ this.toggleCalendar.bind(this) }
      />
    );
  }
}

SearchBarContainer.defaultProps = {
  className: '',
  dateArrival: null,
  dateLeaving: null,
  isLandingSeo: null,
  isLoading: false,
  isOnModal: false,
  isPhone: null,
  isTablet: null,
  route: null,
  searchInputFocused: null,
};

SearchBarContainer.propTypes = {
  changeCheckboxCheckedAction: PropTypes.func.isRequired,
  checkboxes: PropTypes.arrayOf(checkboxInterface).isRequired,
  className: PropTypes.string,
  clearDestinationAction: PropTypes.func.isRequired,
  dateArrival: PropTypes.number,
  dateLeaving: PropTypes.number,
  destination: destinationInterface.isRequired,
  destinationTypingAction: PropTypes.func.isRequired,
  domainCulture: PropTypes.string.isRequired,
  guestsNumber: PropTypes.number.isRequired,
  hideModalAction: PropTypes.func.isRequired,
  isLandingSeo: PropTypes.bool,
  isLoading: PropTypes.bool,
  isOnModal: PropTypes.bool,
  isPhone: PropTypes.string,
  isTablet: PropTypes.string,
  retrieveCheapestDaysAction: PropTypes.func.isRequired,
  route: PropTypes.string,
  searchInputFocused: PropTypes.bool,
  selectDateAction: PropTypes.func.isRequired,
  selectDestinationAction: PropTypes.func.isRequired,
  selectGuestsAction: PropTypes.func.isRequired,
  showModalAction: PropTypes.func.isRequired,
  storedSearches: recentSearchesInterface.isRequired,
  submitRequestAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  updateSearchSubsectionAction: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    checkboxes: state.adsReducer ? state.adsReducer.checkboxes : [],
    country: state.destination.country,
    dateArrival: state.searchFormReducer.dateArrival,
    dateLeaving: state.searchFormReducer.dateLeaving,
    destination: state.destination,
    guestsNumber: state.searchFormReducer.guestsNumber,
    isLoading: state.searchFormReducer.isLoading,
    lat: state.destination.lat,
    latNe: state.destination.latNe,
    latSw: state.destination.latSw,
    lng: state.destination.lng,
    lngNe: state.destination.lngNe,
    lngSw: state.destination.lngSw,
    storedSearches: state.recentSearchesReducer.storedSearches,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    changeCheckboxCheckedAction: changeCheckboxChecked,
    clearDestinationAction: clearDestination,
    destinationTypingAction: destinationTyping,
    hideModalAction: hideModalByName,
    retrieveCheapestDaysAction: retrieveCheapestDays,
    selectDateAction: selectDate,
    selectDestinationAction: selectDestination,
    selectGuestsAction: selectGuests,
    showModalAction: showModal,
    submitRequestAction: submitRequest,
    updateSearchSubsectionAction: updateSearchSubsection,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(getContext(SearchBarContainer));
