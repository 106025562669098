import { FILTERS_ACTIONS, MAP_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';

const mapInitialState = {
  openEstablishment: null,
  overEstablishment: null,
};

export default function map(state = mapInitialState, action) {
  switch (action.type) {
    case SEARCH_RESULTS_ACTIONS.RESET_RESULTS_PAGE:
    case SEARCH_RESULTS_ACTIONS.SHOW_MAP:
    case FILTERS_ACTIONS.UPDATE_FILTERS:
      return Object.assign({}, state, {
        openEstablishment: null,
        overEstablishment: null,
      });

    case SEARCH_RESULTS_ACTIONS.ESTABLISHMENT_OVER:
      return Object.assign({}, state, {
        overEstablishment: action.establishmentId,
      });

    case MAP_ACTIONS.ESTABLISHMENT_OPEN:
      return Object.assign({}, state, {
        openEstablishment: action.establishmentId,
      });

    default:
      return state;
  }
}
