import { COOKIES, DISABLED_POPUNDER_COUNTRIES } from 'constants/constants';
import { isCookieSet, setCookie } from 'helpers/cookies-helper';
import React, { Component } from 'react';
import { defaultWeekend } from 'helpers/dates-helper';
import { getPageType } from 'services/tracking/gtm/gtm-data';
import { ID_PAGES } from 'constants/routes';


export const POPUNDER_DEFAULT_DATA = {
  ar: { country: 'ar', name: 'Cordoba' },
  au: { country: 'au', name: 'Sidney' },
  cl: { country: 'cl', name: 'Santiago' },
  co: { country: 'co', name: 'Bogotá' },
  de: { country: 'de', name: 'Berlin' },
  es: { country: 'es', name: 'Madrid' },
  fr: { country: 'fr', name: 'Paris' },
  gb: { country: 'gb', name: 'London' },
  it: { country: 'it', name: 'Roma' },
  jp: { country: 'jp', name: 'Tokyo' },
  mx: { country: 'mx', name: 'Cancun' },
  pe: { country: 'pe', name: 'Lima' },
  uy: { country: 'uy', name: 'Montevideo' },
};

const FIRST_CLICK_PAGES = [9];

export const triggerPopunder = () => {
  global.document.dispatchEvent(new CustomEvent('popunder'));
};

export default class PopunderController extends Component {
  constructor(props) {
    super(props);
    const [defaultStart, defaultEnd] = defaultWeekend('YYYYMMDD');
    this.defaultStart = defaultStart;
    this.defaultEnd = defaultEnd;
    this.country = null;
    this.pageId = null;
    this.handlerBinded = this.handler.bind(this);
    this.dispatchOnClickBinded = this.dispatchOnClick.bind(this);
    this.state = {
      firstClickDone: false,
    };
  }

  componentDidMount() {
    this.country = global.hr.intl.COUNTRY_CODE;
    this.pageId = ID_PAGES[getPageType(window.location)];
    document.addEventListener('popunder', this.handlerBinded);
    if (FIRST_CLICK_PAGES.includes(this.pageId)) {
      document.addEventListener('click', this.dispatchOnClickBinded);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('popunder', this.handlerBinded);
    if (FIRST_CLICK_PAGES.includes(this.pageId)) {
      document.removeEventListener('click', this.dispatchOnClickBinded);
    }
  }

  dispatchOnClick() {
    const { firstClickDone } = this.state;
    if (firstClickDone) {
      return null;
    }
    this.setState({ firstClickDone: true });
    this.handler.call(this);
  }

  isMacOS() {
    return global.detectUser.os === 'Mac OS';
  }

  isChrome() {
    return global.detectUser.name === 'chrome';
  }

  handler() {
    if (isCookieSet(COOKIES.CONSECUTIVE_VISIT_IN_SESSION)) {
      return null;
    }

    if (DISABLED_POPUNDER_COUNTRIES.includes(this.country)) {
      return null;
    }

    if (!global.IntentMedia || !global.IntentMediaProperties) {
      return null;
    }

    if (this.isMacOS() && this.isChrome()) {
      return null;
    }

    const defaultData = POPUNDER_DEFAULT_DATA[this.country];

    if (!global.IntentMediaProperties.hotel_city) {
      global.IntentMediaProperties.hotel_city = defaultData.name;
    }

    if (!global.IntentMediaProperties.hotel_country) {
      global.IntentMediaProperties.hotel_country = defaultData.country;
    }

    if (!global.IntentMediaProperties.travel_date_start) {
      global.IntentMediaProperties.travel_date_start = this.defaultStart;
    }

    if (!global.IntentMediaProperties.travel_date_end) {
      global.IntentMediaProperties.travel_date_end = this.defaultEnd;
    }

    if (!global.IntentMediaProperties.travelers) {
      global.IntentMediaProperties.travelers = 2;
    }

    if (!global.IntentMediaProperties.hotel_rooms) {
      global.IntentMediaProperties.hotel_rooms = 1;
    }

    setCookie(COOKIES.CONSECUTIVE_VISIT_IN_SESSION, true);
    global.IntentMedia.trigger('open_exit_unit');
  }

  render() {
    return <div id="popunder-controller" />;
  }

}
