import { isCookieSet, setCookie } from 'helpers/cookies-helper';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toggleCookieBanner } from 'actions/appActions';


class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = { showBanner: false };
  }

  componentDidMount() {
    const { cookieName } = this.props;
    if (!isCookieSet(cookieName)) this.showBanner();
  }

  setCookie() {
    const { cookieName } = this.props;
    setCookie(cookieName, true, 365);
  }

  hideBanner() {
    const { cookieName, dispatch } = this.props;
    this.setState({ showBanner: false });
    setCookie(cookieName, true, 365);
    dispatch(toggleCookieBanner());
  }

  handleOnClick() {
    this.hideBanner();
  }

  showBanner() {
    const { dispatch } = this.props;
    this.setState({ showBanner: true });
    dispatch(toggleCookieBanner());
  }

  render() {
    const { className, literal } = this.props;
    const { showBanner } = this.state;

    if (!showBanner) return null;

    return (
      <div className={ `cookie-banner ${className}` }>
        <p className="cookie-banner__text" dangerouslySetInnerHTML={ { __html: literal } } />
        <span className="cookie-banner__close hundredicons-cross" onClick={ this.handleOnClick.bind(this) } />
      </div>
    );
  }
}

CookieBanner.defaultProps = {
  className: '',
  cookieName: '',
  dispatch: () => {},
  literal: '',
};

CookieBanner.propTypes = {
  className: PropTypes.string,
  cookieName: PropTypes.string,
  dispatch: PropTypes.func,
  literal: PropTypes.string,
};

export default CookieBanner;
