import { FILTERS_ACTIONS, MODALS_ACTIONS, SEARCH_RESULTS_ACTIONS } from 'constants/actions';
import { MAX_PRICE, MENUS, PRICE_FILTER_TYPE, PRICE_RANGE_LIMIT, PRICE_RANGES } from 'constants/constants';
import { getState } from 'services/store';
import Hr from 'service-client-js';
import { totalNights } from 'reducers/searchFormReducer';


const _filtersInitialState = {
  availabilityFilterApplied: [],
  cancellationSelected: false,
  discountSelected: false,
  distanceSelected: 0,
  filterInteraction: "",
  orderingSelected: 0,
  poiFilterApplied: [],
  position: -1,
  priceRange: {
    applied: {},
    type: PRICE_FILTER_TYPE.DAY,
  },
  ratingSelected: false,
  recommendationSelected: false,
  reviewsSelected: false,
  roomsSelected: 0,
  servicesFilterApplied: [],
  sliderRange: {
    maxDayPrice: 2500,
    maxTotalPrice: 7500,
    minDayPrice: 35,
    minTotalPrice: 106,
  },
  starsSelected: [],
  typeFilterApplied: [],
  zonesFilterApplied: [],
};

export const filtersInitialState = () => JSON.parse(JSON.stringify(_filtersInitialState));

function generateFilters(filters, item) {
  if (typeof item === 'object') return item;
  const newFilter = filters.slice();
  if (!filters.includes(item)) {
    newFilter.push(item);
  } else {
    newFilter.splice(newFilter.indexOf(item), 1);
  }
  return newFilter;
}

export function filtersReducer(state = filtersInitialState(), action) {
  let priceRange;

  switch (action.type) {
    case SEARCH_RESULTS_ACTIONS.RECEIVE_ESTABLISHMENTS:
    case MODALS_ACTIONS.SHOW_MODAL:
      return action.name === MENUS.FILTERS || getState().modals.name === MENUS.FILTERS
        ? Object.assign({}, state, {
          sliderRange: doFilteredPriceRange(state),
        })
        : state;

    case SEARCH_RESULTS_ACTIONS.INIT_PRICE_RANGE: {
      return Object.assign({}, state, {
        sliderRange: doFilteredPriceRange(state),
      });
    }

    case FILTERS_ACTIONS.FILTER_INTERACTION: {
      return Object.assign({}, state, {
        filterInteraction: action.filtersShowed,
      });
    }

    case FILTERS_ACTIONS.ORDERING: {
      return Object.assign({}, state, { orderingSelected: action.order });
    }

    // Applies Price Filter
    case FILTERS_ACTIONS.SELECT_PRICE_RANGE: {
      priceRange = Object.assign({}, state.priceRange);

      // Get the price range selected
      let priceRanges;
      if (priceRange.type === PRICE_FILTER_TYPE.TOTAL) {
        priceRanges = PRICE_RANGES.TOTAL;
      } else {
        priceRanges = PRICE_RANGES.DAY;
      }
      const priceRangeSelected = priceRanges[action.rangeIndex].slice();

      // Add/Remove the price range selected
      if (priceRange.applied.hasOwnProperty(action.rangeIndex)) {
        delete priceRange.applied[action.rangeIndex];
      } else {
        // For range "200+/400+"
        if (!priceRangeSelected[1]) {
          priceRangeSelected[1] = MAX_PRICE;
        }
        priceRange.applied[action.rangeIndex] = priceRangeSelected;
      }

      return Object.assign({}, state, {
        priceRange,
      });
    }

    case FILTERS_ACTIONS.RESET_PRICE_RANGE:
      return Object.assign({}, state, {
        priceRange: _filtersInitialState.priceRange,
      });

    // Modal Filter
    case FILTERS_ACTIONS.SET_PRICE_RANGE:
      priceRange = Object.assign({}, state.priceRange);
      priceRange.applied = action.values;

      return Object.assign({}, state, {
        priceRange,
      });

    // Applies Price Type (day/total)
    case FILTERS_ACTIONS.SELECT_PRICE_FILTER_TYPE:
      priceRange = Object.assign({}, state.priceRange);
      priceRange.type = action.priceType;
      priceRange.applied = {};
      return Object.assign({}, state, {
        priceRange,
      });

    // Updates Availability Filter
    case FILTERS_ACTIONS.SELECT_AVAILABILITY:
      return Object.assign({}, state, {
        availabilityFilterApplied: generateFilters(state.availabilityFilterApplied, action.availability),
      });

    // Updates Service Filter
    case FILTERS_ACTIONS.SELECT_SERVICE:
      return Object.assign({}, state, {
        servicesFilterApplied: generateFilters(state.servicesFilterApplied, action.services),
      });

    // Updates Establishment Type Filter
    case FILTERS_ACTIONS.SELECT_ESTABLISHMENT_TYPE:
      return Object.assign({}, state, {
        typeFilterApplied: generateFilters(state.typeFilterApplied, action.establishmentType),
      });

    // Updates Stars Filter
    case FILTERS_ACTIONS.SELECT_STARS:
      return Object.assign({}, state, {
        starsSelected: generateFilters(state.starsSelected, action.stars),
      });

    // Updates Zone Type Filter
    case FILTERS_ACTIONS.SELECT_ZONE:
      return Object.assign({}, state, {
        zonesFilterApplied: generateFilters(state.zonesFilterApplied, action.zone),
      });

    // Updates rooms number
    case FILTERS_ACTIONS.SELECT_ROOMS_NUMBER:
      return Object.assign({}, state, {
        roomsSelected: action.number,
      });

    // Updates rating
    case FILTERS_ACTIONS.SELECT_RATING:
      return Object.assign({}, state, {
        position: action.position,
        ratingSelected: action.rating,
      });

    case FILTERS_ACTIONS.INITIALIZE: {
      const initialFilters = filtersInitialState();
      return Object.assign({}, state,
        action.filters ? action.filters : initialFilters,
        { sliderRange: doFilteredPriceRange(state) });
    }

    case SEARCH_RESULTS_ACTIONS.RESET_RESULTS_PAGE:
      return Object.assign({}, state, {
        priceRange: Object.assign({}, state.priceRange, {
          applied: JSON.stringify(state.priceRange) === JSON.stringify(filtersInitialState().priceRange) ? _filtersInitialState.priceRange.applied : state.priceRange.applied,
        }),
        zonesFilterApplied: filtersInitialState().zonesFilterApplied,
      });

    case FILTERS_ACTIONS.UPDATE_PRICE_RANGES:
      return Object.assign({}, state, {
        sliderRange: doFilteredPriceRange(state),
      });

    case FILTERS_ACTIONS.SELECT_DISCOUNT:
      return Object.assign({}, state, {
        discountSelected: action.discountSelected,
      });

    case FILTERS_ACTIONS.SELECT_RECOMMENDATION:
      return Object.assign({}, state, {
        recommendationSelected: action.recommendationSelected,
      });

    case FILTERS_ACTIONS.SELECT_CANCELLATION:
      return Object.assign({}, state, {
        cancellationSelected: action.cancellationSelected,
      });

    default:
      return state;
  }
}

export function getPriceRangesApplied(state) {
  return state.priceRange.applied;
}

export function getPriceType(state) {
  return state.priceRange.type;
}

export function getDistanceInKms(state) {
  let distance = state.filtersReducer.distanceSelected;
  if (distance > 10) {
    distance /= 1000;
  }

  return distance;
}

export function showTotalPrice(stateFilters) {
  return stateFilters.priceRange.type === PRICE_FILTER_TYPE.TOTAL;
}

export function checkFiltersApplied(compareFilters, stateFilters) {
  const notCheckStateFilter = { sliderRange: false };
  const stateFiltersStream = Object.assign({}, JSON.parse(JSON.stringify(stateFilters)), notCheckStateFilter);
  const compareFiltersStream = Object.assign({}, JSON.parse(JSON.stringify(compareFilters)) || filtersInitialState(), notCheckStateFilter);
  return JSON.stringify(compareFiltersStream) !== JSON.stringify(stateFiltersStream);
}

export function doFilteredEstablishments(stateFilters) {
  const estabs = Hr.establishment.search.toObject();
  return Hr.establishment.search.filter(stateFilters).map(id => estabs[id]);
}

export function doFilteredPriceRange(stateFilters) {
  const estabs = Hr.establishment.search.toObject();

  return Hr.establishment.search.filter(Object.assign({}, stateFilters, { priceRange: { applied: {} } }))
    .map(id => estabs[id]).reduce((acc, estb) => {
      acc.minDayPrice = estb.minDayPrice < acc.minDayPrice || !acc.minDayPrice ? estb.minDayPrice : acc.minDayPrice;
      acc.maxDayPrice = estb.minDayPrice > acc.maxDayPrice || !acc.maxDayPrice ? estb.minDayPrice : acc.maxDayPrice;
      acc.minTotalPrice = estb.minTotalPrice < acc.minTotalPrice || !acc.minTotalPrice ? estb.minTotalPrice : acc.minTotalPrice;
      acc.maxTotalPrice = estb.minTotalPrice > acc.maxTotalPrice || !acc.maxTotalPrice ? estb.minTotalPrice : acc.maxTotalPrice;
      return acc;
    }, {
      maxDayPrice: 0,
      maxTotalPrice: 0,
      minDayPrice: 0,
      minTotalPrice: 0,
    });
}

export function getSliderRangesPriceLimit(state) {
  const maxTotalLimit = PRICE_RANGE_LIMIT.DAY * totalNights(state.searchFormReducer);
  const priceMaxTotal = maxTotalLimit < PRICE_RANGE_LIMIT.TOTAL ? maxTotalLimit : PRICE_RANGE_LIMIT.TOTAL;
  return !showTotalPrice(state.filtersReducer) ? PRICE_RANGE_LIMIT.DAY : priceMaxTotal;
}

export function getSliderRanges(state) {
  const sliderRangeMin = 0;
  const sliderRangeMax = showTotalPrice(state.filtersReducer) ? state.filtersReducer.sliderRange.maxTotalPrice : state.filtersReducer.sliderRange.maxDayPrice;

  return {
    max: sliderRangeMax,
    min: sliderRangeMin,
  };
}

export function getFiltersApplied(state) {
  if (!checkFiltersApplied(null, state.filtersApplied)) return 0;

  const {
    availabilityFilterApplied,
    cancellationSelected,
    discountSelected,
    distanceSelected,
    poiFilterApplied,
    priceRange,
    ratingSelected,
    recommendationSelected,
    reviewsSelected,
    roomsSelected,
    servicesFilterApplied,
    typeFilterApplied,
    zonesFilterApplied,
    starsSelected,
  } = state.filtersApplied;

  const filtersAppliedCount = [
    (availabilityFilterApplied.length ? 1 : 0),
    (cancellationSelected ? 1 : 0),
    (discountSelected ? 1 : 0),
    (distanceSelected ? 1 : 0),
    (priceRange.applied && priceRange.applied.a ? 1 : 0),
    (ratingSelected ? 1 : 0),
    (recommendationSelected ? 1 : 0),
    (reviewsSelected ? 1 : 0),
    (roomsSelected ? 1 : 0),
    poiFilterApplied.length,
    servicesFilterApplied.length,
    typeFilterApplied.length,
    zonesFilterApplied.length,
    starsSelected.length,
  ];

  return filtersAppliedCount.reduce((acc, result) => acc + result, 0);
}
